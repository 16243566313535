import { useHistory } from 'react-router';

import { Button, Logo, Screen, Text } from '../../components';
import * as Theme from '../../constants/Theme';
import './styles.scss';

const ConfirmEmailScreen = () => {
  const history = useHistory();

  return (
    <Screen className='confirm-email'>
      <main>
        <div className='confirm-email__logo' onClick={() => history.push('/')}>
          <Logo big />
        </div>
        <div className='confirm-email__title'>
          <Text variant='h5' responsive color={Theme.primary}>
            Confirmar conta
          </Text>
        </div>
        <div className='confirm-email__text'>
          <Text variant='h6'>
            Um email de verificação foi enviado. Confirme sua conta e faça parte da Rokkos.
          </Text>
        </div>
        <div className='confirm-email__actions'>
          <Button variant='contained' fullWidth onClick={() => history.push('/')}>
            Voltar para o login
          </Button>
        </div>
      </main>
    </Screen>
  );
};

export default ConfirmEmailScreen;
