import { makeObservable, observable } from 'mobx';

export default class BankData {
  id = null;
  gateway_account_bank_account_type = null;
  gateway_account_bank = null;
  gateway_account_bank_ag = null;
  gateway_account_bank_cc = null;
  gateway_account_id = null;
  gateway_account_validated = null;
  gateway_automatic_transfer = null;
  percent_comission = null;
  validated = null;
  created_at = null;
  updated_at = null;

  constructor(newBankData = {}) {
    makeObservable(this, {
      id: observable,
      gateway_account_bank_account_type: observable,
      gateway_account_bank: observable,
      gateway_account_bank_ag: observable,
      gateway_account_bank_cc: observable,
      gateway_account_id: observable,
      gateway_account_validated: observable,
      gateway_automatic_transfer: observable,
      percent_comission: observable,
      validated: observable,
      created_at: observable,
      updated_at: observable,
    });

    const {
      id,
      gateway_account_bank_account_type,
      gateway_account_bank,
      gateway_account_bank_ag,
      gateway_account_bank_cc,
      gateway_account_id,
      gateway_account_validated,
      gateway_automatic_transfer,
      percent_comission,
      validated,
      updated_at,
      created_at,
    } = newBankData;

    this.id = id || null;
    this.gateway_account_bank_account_type = gateway_account_bank_account_type || '';
    this.gateway_account_bank = gateway_account_bank || '';
    this.gateway_account_bank_ag = gateway_account_bank_ag || '';
    this.gateway_account_bank_cc = gateway_account_bank_cc || '';
    this.gateway_account_id = gateway_account_id || null;
    this.gateway_account_validated = gateway_account_validated || false;
    this.gateway_automatic_transfer = gateway_automatic_transfer || false;
    this.percent_comission = percent_comission || 0;
    this.validated = validated || false;
    this.updated_at = updated_at || null;
    this.created_at = created_at || null;
  }
}
