import PropTypes from 'prop-types';

import * as Theme from '../../../constants/Theme';

const FacebookIcon = (props) => {
  const { color } = props;

  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M20 0.0800781C9 0.0800781 0 9.06008 0 20.1201C0 30.1201 7.32 38.4201 16.88 39.9201V25.9201H11.8V20.1201H16.88V15.7001C16.88 10.6801 19.86 7.92008 24.44 7.92008C26.62 7.92008 28.9 8.30008 28.9 8.30008V13.2401H26.38C23.9 13.2401 23.12 14.7801 23.12 16.3601V20.1201H28.68L27.78 25.9201H23.12V39.9201C27.8329 39.1758 32.1244 36.7711 35.2199 33.1402C38.3153 29.5093 40.0107 24.8914 40 20.1201C40 9.06008 31 0.0800781 20 0.0800781Z'
        fill={color}
      />
    </svg>
  );
};

FacebookIcon.propTypes = {
  color: PropTypes.string,
};

FacebookIcon.defaultProps = {
  color: Theme.light87,
};

export default FacebookIcon;
