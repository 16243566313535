/* eslint-disable no-unused-vars */
import { useFormik } from 'formik';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import {
  Autocomplete,
  BulletList,
  Button,
  Checkbox,
  DatePicker,
  FileUploaderModal,
  Input,
  Modal,
  RadioButtonsGroup,
  Select,
  Text,
} from '..';
import * as Theme from '../../constants/Theme';
import { useStore } from '../../hooks';
import { compressImage } from '../../utils';
import { MoneyIcon } from '../svg';
import './styles.scss';

const EventForm = (props) => {
  const {
    initialValues,
    validationSchema,
    editing,
    eventIsEnabled,
    changingEventStatus,
    onSubmit,
    onEnableEvent,
    onDisableEvent,
    onDeleteEvent,
  } = props;

  const store = useStore();

  const [coverModalIsOpen, setCoverModalIsOpen] = useState(false);
  const [deleteEventModalIsOpen, setDeleteEventModalIsOpen] = useState(false);
  const [disableEventModalIsOpen, setDisableEventModalIsOpen] = useState(false);
  const [coverPreview, setCoverPreview] = useState(null);

  const eventCategories = store.ConstantStore.eventCategories.map((category) => ({
    value: String(category.id),
    label: category.name,
  }));

  const cities = store.ConstantStore.cities.map((city) => ({
    value: String(city.id),
    label: `${city.name} - ${city.state}`,
  }));

  const autoCompleteDefaultValue = initialValues?.city?.id
    ? {
        value: String(initialValues.city.id),
        label: `${initialValues.city.name} - ${initialValues.city.state}`,
      }
    : null;

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit,
  });

  const handleCoverChange = async (files) => {
    if (files?.length) {
      const compressedImage = await compressImage(files[0]);

      if (compressedImage) {
        formik.setFieldValue(
          'cover',
          new File([compressedImage], compressedImage.name, {
            type: compressedImage.type,
          }),
        );
      }
    }
  };

  const enableEvent = () => {
    setDisableEventModalIsOpen(false);
    onEnableEvent();
  };

  const disableEvent = () => {
    setDisableEventModalIsOpen(false);
    onDisableEvent();
  };

  const deleteEvent = () => {
    setDeleteEventModalIsOpen(false);
    onDeleteEvent();
  };

  const getPackagePrice = (value, quantity) => {
    const price = Number(value) * Number(quantity);
    if (price) return `O valor minímo do pacote sairá R$ ${price.toFixed(2)}.`;

    return null;
  };

  const checkPlanAllowed = (sectionName) => {
    // console.log('checkPlanAllowed', sectionName);

    let active_plan = store.UserStore.profile?.active_plan;

    // console.log('active_plan', active_plan);

    if (active_plan == null || active_plan == 'starter') {
      switch (sectionName) {
        case 'section_package1_event':
          return false;
        case 'section_package2_event':
          return false;
        case 'section_private_event':
          return false;
        case 'section_private_photos_event':
          return false;
        case 'section_colaborative_event':
          return false;
      }
    }

    if (active_plan == 'professional') {
      switch (sectionName) {
        case 'section_package1_event':
          return false;
        case 'section_package2_event':
          return false;
        case 'section_private_event':
          return false;
        case 'section_private_photos_event':
          return true;
        case 'section_colaborative_event':
          return true;
      }
    }

    if (active_plan == 'business') {
      switch (sectionName) {
        case 'section_package1_event':
          return true;
        case 'section_package2_event':
          return true;
        case 'section_private_event':
          return true;
        case 'section_private_photos_event':
          return true;
        case 'section_colaborative_event':
          return true;
      }
    }
  };

  useEffect(() => {
    if (formik.values.cover) {
      if (formik.values.cover instanceof File) {
        const newCoverPreview = URL.createObjectURL(formik.values.cover);
        setCoverPreview(newCoverPreview);
        return () => {
          URL.revokeObjectURL(newCoverPreview);
        };
      } else if (typeof formik.values.cover === 'string') {
        setCoverPreview(formik.values.cover);
      }
    }
  }, [formik.values.cover]);

  return (
    <form
      className='event-form surface-7'
      noValidate
      onSubmit={formik.handleSubmit}
      style={{
        pointerEvents: formik.isSubmitting || changingEventStatus ? 'none' : 'initial',
      }}
    >
      <section>
        <Text variant='h5' className='event-form__title main-title'>
          Dados gerais
        </Text>

        <div className='input-group'>
          <Text variant='subtitle-1' className='input-group__label'>
            Qual o nome do evento?
          </Text>
          <Input
            variant='filled'
            placeholder='Nome do evento'
            fullWidth
            id='name'
            value={formik.values.name}
            error={formik.touched.name && !!formik.errors.name}
            helperText={formik.touched.name && formik.errors.name}
            onChange={formik.handleChange}
          />
        </div>
        <div className='input-group'>
          <Text variant='subtitle-1' className='input-group__label'>
            Aonde aconteceu/acontecerá o evento?
          </Text>
          <Autocomplete
            id='city_id'
            options={cities}
            defaultValue={autoCompleteDefaultValue}
            onChange={(option) =>
              option?.value
                ? formik.setFieldValue('city_id', option.value)
                : formik.setFieldValue('city_id', '')
            }
            error={formik.touched.city_id && !!formik.errors.city_id}
            helperText={formik.touched.city_id && formik.errors.city_id}
            fullWidth
            disableClearable={editing}
          />
        </div>
        <div className='input-group'>
          <Text variant='subtitle-1' className='input-group__label'>
            Quando aconteceu/acontecerá o evento?
          </Text>
          <DatePicker
            id='date'
            fullWidth
            value={formik.values.date ? new Date(formik.values.date) : null}
            error={formik.touched.date && !!formik.errors.date}
            helperText={formik.touched.date && formik.errors.date}
            onChange={(value) => {
              formik.setFieldValue('date', value);
            }}
          />
        </div>
      </section>

      <section>
        <Text variant='h5' className='event-form__title'>
          Categoria do evento
        </Text>
        <Text variant='h6' className='event-form__description'>
          Escolha a categoria que seu evento se encaixa.
        </Text>

        <Select
          variant='filled'
          placeholder='Categoria'
          fullWidth
          options={eventCategories}
          id='category'
          value={String(formik.values.category)}
          error={formik.touched.category && !!formik.errors.category}
          helperText={formik.touched.category && formik.errors.category}
          onChange={formik.handleChange}
        />
      </section>

      <section>
        <Text variant='h5' className='event-form__title'>
          Valores
        </Text>
        <Text variant='h6' className='event-form__description'>
          O uso da ferramenta dos valores individuais, pacote 1 e pacote 2 não é obrigatório.
        </Text>

        <div className='input-group checkbox-group mb-24'>
          <Checkbox
            id='has_individual_price'
            label='Habilitar FOTO INDIVIDUAL'
            defaultChecked={initialValues.has_individual_price}
            onChange={(value) => formik.setFieldValue('has_individual_price', value)}
          />
          <Text variant='subtitle-1' className='input-group__label' color={Theme.light87}>
            Escolha o valor que cada foto será vendida.
          </Text>
          <Text variant='subtitle-1' className='input-group__label' color={Theme.light87}>
            Novidade: Você pode oferecer um evento com fotos gratuitas.<br></br>Fale conosco!
          </Text>
          <Input
            id='photo_price'
            variant='filled'
            placeholder='R$ 15,00'
            startAdornment={<MoneyIcon />}
            fullWidth
            value={String(formik.values.photo_price)}
            error={formik.touched.photo_price && !!formik.errors.photo_price}
            helperText={formik.touched.photo_price && formik.errors.photo_price}
            onChange={({ target }) => {
              const { value } = target;

              if (value < 1) formik.setFieldValue('photo_price', '1');
              else formik.setFieldValue('photo_price', value);
            }}
            mask='currency'
            lineThrough={!formik.values.has_individual_price}
            disabled={!formik.values.has_individual_price}
          />
        </div>

        <div className={checkPlanAllowed('section_package1_event') ? '' : 'disabled_section'}>
          <div className='input-group checkbox-group mb-24'>
            <Checkbox
              id='enable_package_1'
              label='Habilitar PACOTE 1'
              defaultChecked={initialValues.enable_package_1}
              onChange={(value) => formik.setFieldValue('enable_package_1', value)}
            />
            <Text variant='subtitle-1' className='input-group__label' color={Theme.light87}>
              Escolha a quantidade e valor para o pacote 1.
            </Text>
            <Text variant='subtitle-1' className='input-group__label'>
              A partir de:
            </Text>
            <Input
              id='package_1_quantity'
              variant='filled'
              placeholder='Quantidade de fotos'
              fullWidth
              value={String(formik.values.package_1_quantity)}
              error={formik.touched.package_1_quantity && !!formik.errors.package_1_quantity}
              helperText={formik.touched.package_1_quantity && formik.errors.package_1_quantity}
              onChange={formik.handleChange}
              mask='integer'
            />
          </div>

          <div className='input-group mb-24'>
            <Text variant='subtitle-1' className='input-group__label'>
              Valor unitário da foto:
            </Text>
            <Input
              id='package_1_price'
              variant='filled'
              placeholder='R$ 0,00'
              startAdornment={<MoneyIcon />}
              fullWidth
              value={String(formik.values.package_1_price)}
              error={formik.touched.package_1_price && !!formik.errors.package_1_price}
              helperText={formik.touched.package_1_price && formik.errors.package_1_price}
              onChange={formik.handleChange}
              mask='currency'
            />
            <Text variant='subtitle-1' className='input-group__label helper'>
              {getPackagePrice(formik.values.package_1_price, formik.values.package_1_quantity)}
            </Text>
          </div>
        </div>

        <div className={checkPlanAllowed('section_package1_event') ? '' : 'disabled_section'}>
          <div className='input-group checkbox-group mb-24'>
            <Checkbox
              id='enable_package_2'
              label='Habilitar PACOTE 2'
              defaultChecked={initialValues.enable_package_2}
              onChange={(value) => formik.setFieldValue('enable_package_2', value)}
            />
            <Text variant='subtitle-1' className='input-group__label' color={Theme.light87}>
              Escolha a quantidade e valor para o pacote 2.
            </Text>
            <Text variant='subtitle-1' className='input-group__label'>
              A partir de:
            </Text>
            <Input
              id='package_2_quantity'
              variant='filled'
              placeholder='Quantidade de fotos'
              fullWidth
              value={String(formik.values.package_2_quantity)}
              error={formik.touched.package_2_quantity && !!formik.errors.package_2_quantity}
              helperText={formik.touched.package_2_quantity && formik.errors.package_2_quantity}
              onChange={formik.handleChange}
              mask='integer'
            />
          </div>

          <div className='input-group mb-24'>
            <Text variant='subtitle-1' className='input-group__label'>
              Valor unitário da foto:
            </Text>
            <Input
              id='package_2_price'
              variant='filled'
              placeholder='R$ 0,00'
              startAdornment={<MoneyIcon />}
              fullWidth
              value={String(formik.values.package_2_price)}
              error={formik.touched.package_2_price && !!formik.errors.package_2_price}
              helperText={formik.touched.package_2_price && formik.errors.package_2_price}
              onChange={formik.handleChange}
              mask='currency'
            />
            <Text variant='subtitle-1' className='input-group__label helper'>
              {getPackagePrice(formik.values.package_2_price, formik.values.package_2_quantity)}
            </Text>
          </div>
        </div>
      </section>

      <section className={checkPlanAllowed('section_private_event') ? '' : 'disabled_section'}>
        <Text variant='h5' className='event-form__title'>
          Privacidade do Evento
        </Text>
        <Text variant='h6' className='event-form__description'>
          Defina a privacidade do seu evento.
        </Text>

        <RadioButtonsGroup
          initialValue={formik.values.public_event}
          options={[
            {
              label: 'Público',
              value: '1',
              description: 'O evento é público para todos na Rokkos.',
            },
            {
              label: 'Privado',
              value: '0',
              description: 'O evento só é acessado pelo cliente com um código.',
            },
          ]}
          onChange={(value) => formik.setFieldValue('public_event', value)}
        />
      </section>

      <section
        className={checkPlanAllowed('section_private_photos_event') ? '' : 'disabled_section'}
      >
        <Text variant='h5' className='event-form__title'>
          Defina a privacidade das fotos do seu evento
        </Text>

        <RadioButtonsGroup
          initialValue={formik.values.restricted_photos}
          options={[
            {
              label: 'Listadas',
              value: '0',
              description:
                'Todas as fotos são liberadas para visualização. Ao entrar no evento, o cliente pode ver todas as fotos.',
            },
            {
              label: 'Não listadas',
              value: '1',
              description:
                'Apenas fotos do cliente reconhecido serão liberadas para visualização. Ao entrar no evento, o cliente pode ver apenas aquelas fotos em que foi reconhecido.',
            },
          ]}
          onChange={(value) => formik.setFieldValue('restricted_photos', value)}
        />
      </section>

      <section
        className={checkPlanAllowed('section_colaborative_event') ? '' : 'disabled_section'}
      >
        <Text variant='h5' className='event-form__title'>
          Colaborativo ou restrito
        </Text>
        <Text variant='h6' className='event-form__description'>
          Defina se seu evento será colaborativo ou não.
        </Text>

        <RadioButtonsGroup
          initialValue={formik.values.collaborative}
          options={[
            {
              label: 'Restrito para mim',
              value: '0',
              description: 'Apenas eu posso publicar minhas fotos no evento.',
            },
            {
              label: 'Evento colaborativo',
              value: '1',
              description: 'Outros fotógrafos também podem publicar fotos no meu evento.',
            },
          ]}
          onChange={(value) => formik.setFieldValue('collaborative', value)}
        />
      </section>

      <section className='bullet-list-section'>
        <Text variant='h5' className='event-form__title'>
          Individualizadores
        </Text>

        <BulletList
          options={[
            {
              label: 'Reconhecimento facial',
              description:
                'Suas fotos serão submetidas ao reconhecimento facial automaticamente. Não se preocupe com isso. Você faz o upload, nós reconhecemos e mostramos essas fotos primeiro para o seu cliente. Assim ele não perde tempo e pode agilizar muito mais o processo da compra.',
              checked: true,
            },
            {
              label: 'Numeração',
              description: 'Em breve.',
              checked: false,
            },
            {
              label: 'Cores',
              description: 'Em breve.',
              checked: false,
            },
            {
              label: 'Geolocalização ',
              description: 'Em breve.',
              checked: false,
            },
          ]}
        />
      </section>

      <section>
        <Text variant='h5' className='event-form__title'>
          Definir capa do evento
        </Text>
        <Text variant='h6' className='event-form__description'>
          Defina uma capa para seu evento.
        </Text>
        {!!coverPreview?.length && (
          <div className='event-form__cover-preview'>
            <img src={coverPreview} />
          </div>
        )}
        <Button
          variant='outlined'
          className='bt-search-photo'
          onClick={() => setCoverModalIsOpen(true)}
        >
          Buscar foto
        </Button>

        <FileUploaderModal
          modalProps={{
            open: coverModalIsOpen,
            btCancelText: 'Cancelar',
            btConfirmText: 'Definir capa',
            onCancel: () => {
              // setCoverPreview(null);
              // formik.setFieldValue("cover", null);
              setCoverModalIsOpen(false);
            },
            onConfirm: () => {
              setCoverModalIsOpen(false);
            },
            btCancelVariant: 'outlined',
            btConfirmVariant: 'contained',
          }}
          title='Defina a capa do seu evento'
          description='Escolha uma foto para ser capa do seu evento. Ela aparecerá em
          miniatura na Rokkos.'
          preview={coverPreview}
          onChange={handleCoverChange}
        />
      </section>

      <Button
        variant='contained'
        className='bt-submit'
        type='submit'
        loading={formik.isSubmitting || changingEventStatus}
      >
        {editing ? 'Salvar alterações' : 'Criar evento'}
      </Button>

      {editing ? (
        <div className='event-form__danger-area'>
          <section>
            <Text variant='h5' className='event-form__title'>
              Apagar evento
            </Text>
            <Text variant='h6' className='event-form__description'>
              Quero apagar esse evento da Rokkos.
            </Text>

            <Button
              variant='outlined'
              className='bt-delete-event'
              onClick={() => setDeleteEventModalIsOpen(true)}
              loading={formik.isSubmitting || changingEventStatus}
            >
              Apagar evento
            </Button>
            <Modal
              open={deleteEventModalIsOpen}
              btCancelText='Voltar'
              btConfirmText='Concluir'
              btCancelColor='light'
              onCancel={() => setDeleteEventModalIsOpen(false)}
              onConfirm={deleteEvent}
            >
              <Text variant='h6' className='modal-title'>
                Você tem certeza?
              </Text>
              <Text variant='body-1' color={Theme.light60}>
                Ao apagar o evento não é possível recuperá-lo. No entanto, as vendas realizadas
                ainda podem ser acessadas em Minhas Vendas.
              </Text>
            </Modal>
          </section>

          <section>
            <Text variant='h5' className='event-form__title'>
              {eventIsEnabled ? 'Desativar evento' : 'Reativar evento'}
            </Text>
            <Text variant='h6' className='event-form__description'>
              {eventIsEnabled
                ? 'Quero desativar esse evento do meu perfil e da Rokkos.'
                : 'Quero reativar esse evento do meu perfil e da Rokkos.'}
            </Text>

            <Button
              variant='outlined'
              className='bt-disable-event'
              onClick={() => setDisableEventModalIsOpen(true)}
              loading={formik.isSubmitting || changingEventStatus}
            >
              {eventIsEnabled ? 'Desativar evento' : 'Reativar evento'}
            </Button>
            <Modal
              open={disableEventModalIsOpen}
              btCancelText='Voltar'
              btConfirmText='Concluir'
              btCancelColor='light'
              onCancel={() => setDisableEventModalIsOpen(false)}
              onConfirm={() => (eventIsEnabled ? disableEvent() : enableEvent())}
            >
              <Text variant='h6' className='modal-title'>
                Você tem certeza?
              </Text>
              <Text variant='body-1' color={Theme.light60}>
                {eventIsEnabled
                  ? 'Ao desativar o evento, ele não aparece mais aos seus clientes e as vendas são interrompidas.'
                  : 'Ao reativar o evento, ele volta a aparecer aos seus clientes e as vendas também são reativadas.'}
              </Text>
            </Modal>
          </section>
        </div>
      ) : null}
    </form>
  );
};

EventForm.propTypes = {
  initialValues: PropTypes.instanceOf(Object).isRequired,
  validationSchema: PropTypes.instanceOf(Object).isRequired,
  editing: PropTypes.bool,
  requesting: PropTypes.bool,
  eventIsEnabled: PropTypes.number,
  changingEventStatus: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onEnableEvent: PropTypes.func,
  onDisableEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
};

EventForm.defaultProps = {
  editing: false,
  requesting: false,
  eventIsEnabled: 1,
  changingEventStatus: false,
  onEnableEvent: () => {},
  onDisableEvent: () => {},
  onDeleteEvent: () => {},
};

export default observer(EventForm);
