import { Container } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useHistory } from 'react-router';

import { Button, Input, Modal, SearchBar, Text } from '..';
import heroBackground from '../../assets/images/hero-background.png';
import * as Theme from '../../constants/Theme';
import { KeyIcon } from '../svg';
import './styles.scss';

const Hero = (props) => {
  const {
    variant,
    placeholder,
    buttonLabel,
    requesting,
    onChangeText,
    onChangeLocation,
    onChangeDate,
    onSubmitSearch,
  } = props;

  const history = useHistory();
  const [accessCodeModalIsOpen, setAccessCodeModalIsOpen] = useState(false);
  const [accessCode, setAccessCode] = useState('');

  const heroClassNames = () => {
    let classNames = 'hero';
    if (variant) classNames += ` hero--${variant}`;
    return classNames;
  };

  return (
    <div className={heroClassNames()}>
      {variant !== 'shopper' ? <img src={heroBackground} className='hero__background' /> : null}

      <Container maxWidth={Theme.containerMaxWidth}>
        {variant !== 'shopper' ? (
          <div className='hero__text'>
            <Text variant='h5' responsive className='hero__title'>
              Busque um evento
            </Text>
            <Text variant='h6' responsive className='hero__description'>
              Ache um evento perto de você e venda suas fotos na Rokkos.
            </Text>
          </div>
        ) : null}

        <div className='hero__search-bar'>
          <SearchBar
            buttonLabel={buttonLabel}
            placeholder={placeholder}
            requesting={requesting}
            onChangeText={onChangeText}
            onChangeLocation={onChangeLocation}
            onChangeDate={onChangeDate}
            onSubmitSearch={onSubmitSearch}
          />

          <div className='bt-code-wrapper'>
            <Button
              variant='contained'
              startIcon={<KeyIcon color='#000' />}
              onClick={() => setAccessCodeModalIsOpen(true)}
              fullWidth
            >
              Inserir Código
            </Button>
          </div>
          <Modal
            open={accessCodeModalIsOpen}
            btConfirmText='Buscar fotos'
            btCancelText='voltar'
            btCancelColor='light'
            onCancel={() => setAccessCodeModalIsOpen(false)}
            onConfirm={() => history.push(`/private-events/${accessCode}`)}
          >
            <Text variant='h6' className='modal-title'>
              Possui um código?
            </Text>
            <Text variant='body-1' color={Theme.light60}>
              {variant === 'shopper'
                ? 'Insira o código passado pelo seu fotógrafo para acessar este evento.'
                : 'Insira o código para acessar este evento.'}
            </Text>
            <div className='code-modal-input'>
              <Input
                variant='filled'
                placeholder='Código de acesso'
                fullWidth
                id='code'
                value={accessCode}
                onChangeText={setAccessCode}
                startAdornment={<KeyIcon />}
              />
            </div>
          </Modal>
        </div>
      </Container>
    </div>
  );
};

Hero.propTypes = {
  variant: PropTypes.oneOf(['photographer', 'photographer-small', 'shopper']),
  buttonLabel: PropTypes.string,
  placeholder: PropTypes.string,
  requesting: PropTypes.bool,
  onChangeText: PropTypes.func,
  onChangeLocation: PropTypes.func,
  onChangeDate: PropTypes.func,
  onSubmitSearch: PropTypes.func,
};

Hero.defaultProps = {
  variant: 'photographer',
  buttonLabel: 'Buscar',
  placeholder: 'Pesquisar',
  requesting: false,
  onChangeText: () => '',
  onChangeLocation: () => '',
  onChangeDate: () => '',
  onSubmitSearch: () => '',
};

export default Hero;
