import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Button, CartListItem, Screen, Stepper, Text } from '../../components';
import * as Theme from '../../constants/Theme';
import { useStore } from '../../hooks';
import './styles.scss';

const OrderScreen = () => {
  const history = useHistory();
  const store = useStore();

  useEffect(() => {
    if (store.CheckoutStore.cart?.cart_events?.length < 1) history.push('/checkout/cart');
  }, []);

  return (
    <Screen className='order'>
      <main>
        <div className='checkout__header'>
          <Text variant='h5' responsive color={Theme.primary}>
            Comprar
          </Text>
        </div>
        <div className='checkout__stepper'>
          <Stepper
            activeStep={0}
            steps={[
              {
                label: 'Resumo',
              },
              {
                label: 'Compra',
              },
              {
                label: 'Confirmação',
              },
            ]}
          />
        </div>
        <div className='checkout__section-title'>
          <Text variant='h6' responsive>
            Resumo do pedido
          </Text>
        </div>
        <div className='checkout__surface surface-7'>
          <div className='order__list-header'>
            <div className='order__list-header__photo'>
              <Text variant='body-1' color={Theme.primary}>
                Item
              </Text>
            </div>
            <div className='order__list-header__id'>
              <Text variant='body-1' color={Theme.primary}>
                ID
              </Text>
            </div>
            <div className='order__list-header__owner'>
              <Text variant='body-1' color={Theme.primary}>
                Fotógrafo
              </Text>
            </div>
            <div className='order__list-header__resolution'>
              <Text variant='body-1' color={Theme.primary}>
                Resolução
              </Text>
            </div>
            <div className='order__list-header__price'>
              <Text variant='body-1' color={Theme.primary}>
                Preço
              </Text>
            </div>
          </div>

          <div className='order__list'>
            {store.CheckoutStore.cart.map((photo) => (
              <CartListItem key={photo.id} photo={photo} variant='order' showBtRemove={false} />
            ))}
          </div>
          <div className='order__total'>
            <Text variant='h4' color={Theme.primary}>
              Total
            </Text>
            <Text variant='h4'>{`R$ ${String(store.CheckoutStore.total.toFixed(2)).replace(
              '.',
              ',',
            )}`}</Text>
          </div>
        </div>

        <div className='order__actions'>
          <Button variant='outlined' onClick={() => history.push('/checkout')}>
            Inserir dados de pagamento
          </Button>
        </div>
      </main>
    </Screen>
  );
};

export default observer(OrderScreen);
