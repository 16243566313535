import { Container } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { Modal } from '..';
import { COPY_URL_SUCCESS } from '../../constants/Messages';
import * as Theme from '../../constants/Theme';

const InstagramBrowserModal = () => {
  const [InstagramBrowserModalIsOpen, setInstagramBrowserModalIsOpen] = useState(false);

  const redirectAway = () => {
    let currentLink = window.location.href;
    let chromeLink = '';

    currentLink = currentLink.replace('https://', '');
    chromeLink = 'googlechrome://' + currentLink;

    window.location.href = chromeLink;
  };

  const isInstagramBrowser = () => {
    let ua = navigator.userAgent || navigator.vendor || window.opera;
    return ua.indexOf('Instagram') > -1 ? true : false;
  };

  const init = async () => {
    if (document.documentElement.classList) {
      if (isInstagramBrowser()) {
        setInstagramBrowserModalIsOpen(true);
      }
    }
  };

  const copyClipboard = () => {
    navigator.clipboard.writeText(window.location.href);
    console.log(COPY_URL_SUCCESS);
    toast.success(COPY_URL_SUCCESS);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <div className='instagram-browser-modal'>
      <Modal
        open={InstagramBrowserModalIsOpen}
        btConfirmText='Ir para outro navegador'
        btCancelColor='light'
        onCancel={() => setInstagramBrowserModalIsOpen(false)}
        onConfirm={() => redirectAway()}
      >
        <Container maxWidth={Theme.containerMaxWidth}>
          <h2>Aviso!</h2>
          <p>
            Notamos que você está acessando a Rokkos através do navegador in-app do Instagram.
          </p>

          <p>
            Infelizmente, navegar por dentro do instagram resulta em erros no sistema da Rokkos,
            devido a utilizarmos recursos avançado do sistema.
          </p>

          <p>
            Pedimos encarecidamente para clicar no link abaixo e acessar a Rokkos pelo navegador
            oficial do seu dispositivo, fora do Instagram ou acesse diretamente pelo seu
            navegador preferido. Clique no link abaixo para copiar:
          </p>

          <div
            className='link'
            onClick={copyClipboard}
            style={{ cursor: 'pointer', fontSize: '1.2rem' }}
          >
            {window.location.href}
          </div>
        </Container>
      </Modal>
    </div>
  );
};

export default InstagramBrowserModal;
