/* eslint-disable no-undef */
import { useFormik } from 'formik';
import { flowResult } from 'mobx';
import { useEffect, useState } from 'react';
import GoogleLogin from 'react-google-login';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import banner from '../../assets/images/banner-login.png';
import { Button, Input, Logo, Screen, Text } from '../../components';
import { GoogleIcon } from '../../components/svg';
import { loginFormDefinitions } from '../../constants/FormDefinitions';
import {
  EMAIL_NOT_REGISTERED,
  INVALID_EMAIL_OR_PASSWORD,
  SYSTEM_INSTABILITY,
} from '../../constants/Messages';
import * as Theme from '../../constants/Theme';
import { useQuery, useStore } from '../../hooks';
import './styles.scss';

const LoginScreen = () => {
  const history = useHistory();
  const store = useStore();
  const query = useQuery();

  const redirectUri = query.get('redirect');

  const [mounted, setMounted] = useState(false);

  const { initialValues, validationSchema } = loginFormDefinitions;

  const getScreenClassNames = () => {
    let newClassNames = 'login';
    if (mounted) newClassNames += ' login--active';
    return newClassNames;
  };

  const onSubmit = async (formValues) => {
    try {
      const response = await flowResult(store.AuthStore.authenticate(formValues));

      if (response?.error) {
        switch (response?.error?.status) {
          case 401:
          case 404:
          case 422: {
            toast.error(INVALID_EMAIL_OR_PASSWORD);
            break;
          }

          case 403: {
            history.push('/register/confirmation');
            break;
          }

          default: {
            toast.error(SYSTEM_INSTABILITY);
            break;
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const oAuth = async (oAuthData) => {
    try {
      formik.setSubmitting(true);

      const response = await flowResult(store.AuthStore.oAuth(oAuthData));

      if (response?.error) {
        switch (response?.error?.status) {
          case 401:
          case 422: {
            toast.error(INVALID_EMAIL_OR_PASSWORD);
            break;
          }

          case 404: {
            toast.warning(EMAIL_NOT_REGISTERED);
            history.push(`/register?email=${oAuthData.profileObj.email}`);
            break;
          }

          case 403: {
            history.push('/register/confirmation');
            break;
          }

          default: {
            toast.error(SYSTEM_INSTABILITY);
            break;
          }
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      formik.setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit,
  });

  useEffect(() => {
    const animationTimeout = setTimeout(() => {
      setMounted(true);
      if (query.get('email-verify')) {
        toast.success('E-mail verificado com sucesso!');
      }
    }, 100);

    return () => {
      clearTimeout(animationTimeout);
    };
  }, []);

  return (
    <Screen className={getScreenClassNames()} container={false}>
      <div className='login__banner'>
        <img src={banner} alt='Photographer' />
      </div>
      <div
        className='login__content'
        style={{
          pointerEvents: formik.isSubmitting ? 'none' : 'initial',
        }}
      >
        <div className='login__content__logo' onClick={() => history.push('/')}>
          <Logo big />
        </div>

        <div className='login__content__title'>
          <Text variant='h5' responsive color={Theme.primary}>
            Entrar
          </Text>
        </div>
        <form
          noValidate
          onSubmit={formik.handleSubmit}
          style={{
            pointerEvents: formik.isSubmitting ? 'none' : 'initial',
          }}
        >
          <div className='login__content__inputs'>
            <div className='login__content__inputs__input-wrapper'>
              <Input
                variant='filled'
                placeholder='E-mail'
                fullWidth
                id='email'
                value={formik.values.email}
                error={formik.touched.email && !!formik.errors.email}
                helperText={formik.touched.email && formik.errors.email}
                onChange={formik.handleChange}
              />
            </div>
            <div className='login__content__inputs__input-wrapper'>
              <Input
                variant='filled'
                placeholder='Senha'
                fullWidth
                id='password'
                value={formik.values.password}
                error={formik.touched.password && !!formik.errors.password}
                helperText={formik.touched.password && formik.errors.password}
                onChange={formik.handleChange}
                secureText
              />
            </div>
            <Button
              className='bt-password-recovery'
              onClick={() => history.push('/password-recovery')}
            >
              <Text
                variant='subtitle-1'
                color={Theme.light60}
                className='bt-password-recovery__text'
              >
                Esqueci minha senha
              </Text>
            </Button>
          </div>

          <div className='login__content__actions'>
            <div className='login__content__actions__input-wrapper'>
              <Button variant='contained' fullWidth type='submit' loading={formik.isSubmitting}>
                Entrar
              </Button>
            </div>
            <div className='login__content__actions__input-wrapper'>
              <GoogleLogin
                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                buttonText='Login'
                onSuccess={oAuth}
                theme='dark'
                onFailure={() => {
                  formik.setSubmitting(false);
                }}
                cookiePolicy={'single_host_origin'}
                render={(renderProps) => (
                  <Button
                    onClick={() => {
                      renderProps.onClick();
                      formik.setSubmitting(true);
                    }}
                    variant='outlined'
                    fullWidth
                    startIcon={<GoogleIcon />}
                    className='bt-google'
                    disabled={renderProps.disabled}
                    loading={formik.isSubmitting}
                    color='light'
                  >
                    Entrar com Google
                  </Button>
                )}
              />
            </div>
            <div className='login__content__actions__input-wrapper'>
              <Button
                className='bt-register'
                onClick={() =>
                  history.push(
                    `/register${redirectUri?.length ? `?redirect=${redirectUri}` : ''}`,
                  )
                }
              >
                <Text variant='subtitle-1' color={Theme.light60} className='bt-register__text'>
                  Ainda não tem uma conta?
                </Text>
                <Text variant='subtitle-1' color={Theme.primary} className='bt-register__text'>
                  Criar Conta
                </Text>
              </Button>
            </div>
          </div>
        </form>
      </div>
    </Screen>
  );
};

export default LoginScreen;
