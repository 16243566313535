import { Container } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  Button,
  EmptyState,
  EventHeader,
  ListLoader,
  Loader,
  PhotoGrid,
  Screen,
} from '../../components';
import { EmptyStatePhotoSearchIcon } from '../../components/svg';
import { SYSTEM_INSTABILITY } from '../../constants/Messages';
import * as Theme from '../../constants/Theme';
import { useStore } from '../../hooks';
import './styles.scss';

const PublicEventDetailScreen = () => {
  const history = useHistory();
  const store = useStore();
  const params = useParams();

  const event = store.EventStore.detail;
  const photos = store.PhotoStore.list;

  const [mounted, setMounted] = useState(false);
  const [requestingDetails, setRequestingDetails] = useState(true);
  const [requestingList, setRequestingList] = useState(true);
  const [page, setPage] = useState(1);

  const getEventDetail = async () => {
    try {
      setRequestingDetails(true);

      const response = await flowResult(store.EventStore.getDetail({ id: params.id }));

      if (response?.error) {
        switch (response?.error?.status) {
          default: {
            history.push('/404');
            return;
          }
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setRequestingDetails(false);
    }
  };

  const getEventDetailByAccessCode = async () => {
    try {
      setRequestingDetails(true);

      const response = await flowResult(
        store.EventStore.getPublicDetailByAccessCode({
          access_code: params.accessCode,
        }),
      );

      if (response?.error) {
        switch (response?.error?.status) {
          default: {
            history.push('/event-not-found');
            return;
          }
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setRequestingDetails(false);
    }
  };

  const getPhotos = async () => {
    try {
      setRequestingList(true);

      const response = await flowResult(
        store.PhotoStore.getList({
          event_id: event.id,
          page,
        }),
      );

      if (response.error) toast.error(SYSTEM_INSTABILITY);
    } catch (error) {
      console.log(error);
    } finally {
      setRequestingList(false);
    }
  };

  const renderPhotoGrid = () => {
    if (requestingList) {
      return <ListLoader variant='grid' length={16} interval={300} />;
    }

    if (mounted && !requestingList && !photos?.length) {
      return (
        <EmptyState
          icon={<EmptyStatePhotoSearchIcon />}
          title='Este evento ainda não tem fotos.'
          description='Conheça outros eventos da Rokkos!'
          button={
            <Button variant='contained' onClick={() => history.push('/')}>
              Conferir eventos
            </Button>
          }
        />
      );
    }

    return (
      <PhotoGrid
        variant='shopper'
        photos={photos}
        showActionButton
        onDeletePhoto={onDeletePhoto}
      />
    );
  };

  const renderPagination = () => {
    if (
      !mounted ||
      !event?.photo_count ||
      (!event?.collaborative && store.PhotoStore.meta?.last_page <= 1)
    ) {
      return null;
    }

    return (
      <Pagination
        page={page}
        count={store.PhotoStore.meta.last_page}
        color='primary'
        onChange={(_, page) => setPage(page)}
        disabled={requestingList}
      />
    );
  };

  const onDeletePhoto = () => {
    setPage(1);
    getPhotos();
  };

  const init = async () => {
    setPage(1);

    params?.accessCode ? await getEventDetailByAccessCode() : await getEventDetail();

    setMounted(true);
  };

  useEffect(() => {
    if (!event?.photo_count) return;
    getPhotos();
  }, [event]);

  useEffect(() => {
    if (!mounted) return;

    if (event) getPhotos();

    if (window.innerWidth > 1024) {
      if (event.public_event && event.access_code?.length) {
        window.scrollTo(0, 338);
        return;
      }

      window.scrollTo(0, 248);
      return;
    }

    window.scrollTo(0, 368);
  }, [page]);

  useEffect(() => {
    init();
  }, []);

  return (
    <Screen className='public-event-detail' container={false}>
      {requestingDetails || !event ? (
        <Loader color={Theme.primary} paddingVertical={32} />
      ) : (
        <>
          <EventHeader
            variant='shopper'
            requesting={requestingDetails || requestingList}
            onClickEditEvent={() => history.push(`/event/${event.id}/edit`)}
          />
          <Container maxWidth={Theme.containerMaxWidth}>
            {renderPhotoGrid()}
            {renderPagination()}
          </Container>
        </>
      )}
    </Screen>
  );
};

export default observer(PublicEventDetailScreen);
