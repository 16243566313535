import { Container } from '@material-ui/core';
import PropTypes from 'prop-types';

import { Avatar, Text } from '..';
import * as Theme from '../../constants/Theme';
import './styles.scss';

const AlbumHeader = (props) => {
  const { album } = props;
  const { event, owner } = album;

  return (
    <div className='album-header surface-7'>
      <Container maxWidth={Theme.containerMaxWidth}>
        <div className='album-header__owner'>
          {owner ? <Avatar image={owner.avatar_url} size='large' /> : null}
          <div className='album-header__owner__text'>
            <Text variant='h5' className='album-header__owner-name'>
              {owner ? owner.name : ''}
            </Text>
            <Text variant='h6' color={Theme.light60} className='album-header__event-name'>
              {event ? event.name : ''}
            </Text>
          </div>
        </div>
      </Container>
    </div>
  );
};

AlbumHeader.propTypes = {
  album: PropTypes.shape({
    id: PropTypes.number.isRequired,
    owner: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      avatar_url: PropTypes.string,
    }),
    event: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default AlbumHeader;
