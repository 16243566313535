import { makeObservable, observable } from 'mobx';

export default class Event {
  id = null;
  name = null;
  date = null;
  city = null;
  photo_price = null;
  packages = [];
  cover_url = null;
  access_code = null;
  collaborative = null;
  public_event = null;
  restricted_photos = null;
  owner = null;
  user = null;
  category = null;
  photo_count = null;
  enabled = null;
  created_at = null;
  has_individual_price = false;

  constructor(newEvent = {}) {
    makeObservable(this, {
      id: observable,
      name: observable,
      date: observable,
      city: observable,
      photo_price: observable,
      packages: observable,
      cover_url: observable,
      access_code: observable,
      collaborative: observable,
      public_event: observable,
      restricted_photos: observable,
      owner: observable,
      user: observable,
      category: observable,
      photo_count: observable,
      enabled: observable,
      created_at: observable,
      has_individual_price: observable,
    });

    if (newEvent.id == null) {
      throw new Error('Invalid Event constructor');
    }

    const {
      id,
      name,
      date,
      city,
      photo_price,
      packages,
      cover_url,
      access_code,
      collaborative,
      public: public_event,
      restricted_photos,
      owner,
      user,
      category,
      photo_count,
      enabled,
      created_at,
      has_individual_price,
    } = newEvent;

    this.id = id;
    this.name = name || '';
    this.date = date || '';
    this.city = city || '';
    this.photo_price = photo_price || null;
    this.cover_url = cover_url || '';
    this.access_code = access_code || '';
    this.category = category || null;
    this.photo_count = photo_count || null;
    this.packages = packages || [];
    this.owner = owner || null;
    this.user = user || null;
    this.collaborative = collaborative;
    this.public_event = public_event;
    this.restricted_photos = restricted_photos;
    this.enabled = enabled;
    this.created_at = created_at || '';
    this.has_individual_price = has_individual_price || false;
  }
}
