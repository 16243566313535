/* eslint-disable no-undef */
import * as Yup from 'yup';

import { validateCNPJ, validateCPF, validateURL } from '../utils';
import {
  INVALID_BANK_ACCOUNT,
  INVALID_BANK_AGENCY,
  INVALID_CARD_NUMBER,
  INVALID_CEP,
  INVALID_CNPJ,
  INVALID_CPF,
  INVALID_CVV,
  INVALID_DATE,
  INVALID_EMAIL,
  INVALID_EXPIRE_DATE,
  INVALID_PASSWORD_CONFIRMATION,
  INVALID_NUMBER,
  INVALID_NUMBER_SIZE,
  INVALID_PHONE,
  INVALID_URL,
  PASSWORD_MIN_LENGTH,
  REQUIRED_FIELD,
} from './Messages';

const eventFormDefinitions = {
  schema: {
    name: Yup.string().required(REQUIRED_FIELD),
    city_id: Yup.string().required(REQUIRED_FIELD),
    date: Yup.date().required(REQUIRED_FIELD).typeError(INVALID_DATE),
    category: Yup.string().required(REQUIRED_FIELD),
    has_individual_price: Yup.bool(),
    photo_price: Yup.string().nullable(),
    enable_package_1: Yup.bool(),
    package_1_quantity: Yup.string().when(['enable_package_1'], (enable_package_1) =>
      Yup.string().test(
        'package_1_quantity',
        'Campo obrigatório quando o pacote 1 está habilitado',
        (value) => !enable_package_1 || Number(value),
      ),
    ),
    package_1_price: Yup.string().when(
      ['has_individual_price', 'photo_price', 'enable_package_1'],
      (has_individual_price, photo_price, enable_package_1) =>
        Yup.string().test(
          'package_1_price',
          'A foto do pacote 1 deve custar menos que a foto individual',
          (value) =>
            !enable_package_1 ||
            (Number(value) && (!has_individual_price || Number(value) < Number(photo_price))),
        ),
    ),
    enable_package_2: Yup.bool(),
    package_2_quantity: Yup.string().when(['enable_package_2'], (enable_package_2) =>
      Yup.string().test(
        'package_2_quantity',
        'Campo obrigatório quando o pacote 2 está habilitado',
        (value) => !enable_package_2 || Number(value),
      ),
    ),
    package_2_price: Yup.string().when(
      [
        'has_individual_price',
        'photo_price',
        'package_1_price',
        'enable_package_1',
        'enable_package_2',
      ],
      (
        has_individual_price,
        photo_price,
        package_1_price,
        enable_package_1,
        enable_package_2,
      ) =>
        Yup.string().test(
          'package_2_price',
          'A foto do pacote 2 deve custar menos que a foto do pacote 1 e a foto individual',
          (value) =>
            !enable_package_2 ||
            (Number(value) && !has_individual_price && !enable_package_1) ||
            (enable_package_1 &&
              !has_individual_price &&
              Number(value) < Number(package_1_price)) ||
            (!enable_package_1 &&
              has_individual_price &&
              Number(value) < Number(photo_price)) ||
            (enable_package_1 &&
              has_individual_price &&
              Number(value) < Number(package_1_price)),
        ),
    ),
    public_event: Yup.string().required(REQUIRED_FIELD),
    restricted_photos: Yup.string().required(REQUIRED_FIELD),
    collaborative: Yup.string().required(REQUIRED_FIELD),
    cover: Yup.lazy((value) =>
      value instanceof File
        ? Yup.object()
            .shape({
              name: Yup.string(),
            })
            .label('File')
            .nullable()
        : Yup.string().nullable(),
    ),
  },
  initialValues: {
    name: '',
    date: null,
    city_id: '',
    photo_price: '1',
    has_individual_price: false,
    enable_package_1: false,
    package_1_quantity: '',
    package_1_price: '',
    enable_package_2: false,
    package_2_quantity: '',
    package_2_price: '',
    collaborative: '0',
    public_event: '1',
    restricted_photos: '0',
    category: '',
    cover: null,
  },
};

eventFormDefinitions.validationSchema = Yup.object().shape(eventFormDefinitions.schema);

const loginFormDefinitions = {
  schema: {
    email: Yup.string().email(INVALID_EMAIL).required(REQUIRED_FIELD),
    password: Yup.string().required(REQUIRED_FIELD).min(6, PASSWORD_MIN_LENGTH),
  },
  initialValues: {
    email: '',
    password: '',
  },
};

loginFormDefinitions.validationSchema = Yup.object().shape(loginFormDefinitions.schema);

const registerFormDefinitions = {
  schema: {
    type: Yup.string().oneOf(['0', '1'], REQUIRED_FIELD).required(REQUIRED_FIELD),
    name: Yup.string().required(REQUIRED_FIELD),
    legal_consent: Yup.bool().oneOf([true], REQUIRED_FIELD),
    ...loginFormDefinitions.schema,
  },
  initialValues: {
    type: false,
    name: '',
    legal_consent: false,
    ...loginFormDefinitions.initialValues,
  },
};

registerFormDefinitions.validationSchema = Yup.object().shape(registerFormDefinitions.schema);

const passwordRecoveryFormDefinitions = {
  schema: {
    email: Yup.string().email(INVALID_EMAIL).required(REQUIRED_FIELD),
  },
  initialValues: {
    email: '',
  },
};

passwordRecoveryFormDefinitions.validationSchema = Yup.object().shape(
  passwordRecoveryFormDefinitions.schema,
);

const passwordChangeFormDefinitions = {
  schema: {
    password: Yup.string().required(REQUIRED_FIELD).min(6, PASSWORD_MIN_LENGTH),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], INVALID_PASSWORD_CONFIRMATION)
      .required(REQUIRED_FIELD)
      .min(6, PASSWORD_MIN_LENGTH),
  },
  initialValues: {
    password: '',
    password_confirmation: '',
  },
};

passwordChangeFormDefinitions.validationSchema = Yup.object().shape(
  passwordChangeFormDefinitions.schema,
);

const photographerProfileFormDefinitions = {
  schema: {
    avatar: Yup.lazy((value) =>
      value instanceof File
        ? Yup.object()
            .shape({
              name: Yup.string(),
            })
            .label('File')
            .nullable()
        : Yup.string().nullable(),
    ),
    name: Yup.string().required(REQUIRED_FIELD),
    phone: Yup.string().min(11, INVALID_PHONE).required(REQUIRED_FIELD),
    person_type: Yup.string(),
    cpf: Yup.string().when('person_type', (person_type) =>
      person_type === 'fisica'
        ? Yup.string().test('cpf', INVALID_CPF, (value) => validateCPF(value))
        : Yup.string().nullable(),
    ),
    cnpj: Yup.string().when('person_type', (person_type) =>
      person_type === 'juridica'
        ? Yup.string().test('cnpj', INVALID_CNPJ, (value) => validateCNPJ(value))
        : Yup.string().nullable(),
    ),
  },
  initialValues: {
    avatar: '',
    name: '',
    phone: '',
    person_type: 'fisica',
    cpf: '',
    cnpj: '',
  },
};

photographerProfileFormDefinitions.validationSchema = Yup.object().shape(
  photographerProfileFormDefinitions.schema,
);

const shopperProfileFormDefinitions = {
  schema: {
    avatar: Yup.lazy((value) =>
      value instanceof File
        ? Yup.object()
            .shape({
              name: Yup.string(),
            })
            .label('File')
            .nullable()
        : Yup.string().nullable(),
    ),
    name: Yup.string().required(REQUIRED_FIELD),
    phone: Yup.string().min(11, INVALID_PHONE).nullable(),
    person_type: Yup.string(),
    cpf: Yup.string().when('person_type', (person_type) =>
      person_type === 'fisica'
        ? Yup.string().test('cpf', INVALID_CPF, (value) => validateCPF(value) || !value?.length)
        : Yup.string().nullable(),
    ),
    cnpj: Yup.string().when('person_type', (person_type) =>
      person_type === 'juridica'
        ? Yup.string().test(
            'cnpj',
            INVALID_CNPJ,
            (value) => validateCNPJ(value) || !value?.length,
          )
        : Yup.string().nullable(),
    ),
  },
  initialValues: {
    avatar: '',
    name: '',
    phone: '',
    person_type: 'fisica',
    cpf: '',
    cnpj: '',
  },
};

shopperProfileFormDefinitions.validationSchema = Yup.object().shape(
  shopperProfileFormDefinitions.schema,
);

const addressFormDefinitions = {
  schema: {
    zip: Yup.string().min(8, INVALID_CEP).nullable(),
    street: Yup.string().nullable(),
    number: Yup.number()
      .typeError(INVALID_NUMBER)
      .max(99999999, INVALID_NUMBER_SIZE)
      .required(REQUIRED_FIELD),
    complement: Yup.string().nullable(),
    district: Yup.string().nullable(),
    city: Yup.string().nullable(),
    state: Yup.string().nullable(),
  },
  initialValues: {
    zip: '',
    street: '',
    number: '',
    complement: '',
    district: '',
    city: '',
    state: '',
  },
};

addressFormDefinitions.validationSchema = Yup.object().shape(addressFormDefinitions.schema);

const requiredAddressFormDefinitions = {
  schema: {
    zip: Yup.string().required(REQUIRED_FIELD).min(8, INVALID_CEP),
    street: Yup.string().required(REQUIRED_FIELD),
    number: Yup.number()
      .typeError(INVALID_NUMBER)
      .max(99999999, INVALID_NUMBER_SIZE)
      .required(REQUIRED_FIELD),
    complement: Yup.string(),
    district: Yup.string().required(REQUIRED_FIELD),
    city: Yup.string().required(REQUIRED_FIELD),
    state: Yup.string().required(REQUIRED_FIELD),
  },
  initialValues: {
    zip: '',
    street: '',
    number: '',
    complement: '',
    district: '',
    city: '',
    state: '',
  },
};

requiredAddressFormDefinitions.validationSchema = Yup.object().shape(
  requiredAddressFormDefinitions.schema,
);

const bankDataFormDefinitions = {
  schema: {
    gateway_account_bank: Yup.string().required(REQUIRED_FIELD),
    gateway_account_bank_ag: Yup.string()
      .test(
        'gateway_account_bank_ag',
        INVALID_BANK_AGENCY,
        (value) => value?.indexOf('-') !== 0 && value?.indexOf('-') !== value?.length - 1,
      )
      .required(REQUIRED_FIELD),
    gateway_account_bank_account_type: Yup.string().required(REQUIRED_FIELD),
    gateway_account_bank_cc: Yup.string()
      .test(
        'gateway_account_bank_cc',
        INVALID_BANK_ACCOUNT,
        (value) => value?.indexOf('-') !== 0 && value?.indexOf('-') !== value?.length - 1,
      )
      .required(REQUIRED_FIELD),
    gateway_account_bank_operation: Yup.string().when(
      'gateway_account_bank',
      (gateway_account_bank) =>
        gateway_account_bank === 'Caixa Econômica'
          ? Yup.string().required(REQUIRED_FIELD)
          : Yup.string().nullable(),
    ),
  },
  initialValues: {
    gateway_account_bank: '',
    gateway_account_bank_ag: '',
    gateway_account_bank_account_type: '',
    gateway_account_bank_cc: '',
    gateway_account_bank_operation: '',
  },
};

bankDataFormDefinitions.validationSchema = Yup.object().shape(bankDataFormDefinitions.schema);

const publicProfileFormDefinitions = {
  schema: {
    avatar: Yup.lazy((value) =>
      value instanceof File
        ? Yup.object()
            .shape({
              name: Yup.string(),
            })
            .label('File')
            .nullable()
        : Yup.string().nullable(),
    ),
    cover: Yup.lazy((value) =>
      value instanceof File
        ? Yup.object()
            .shape({
              name: Yup.string(),
            })
            .label('File')
            .nullable()
        : Yup.string().nullable(),
    ),
    name: Yup.string().required(REQUIRED_FIELD),
    email: Yup.string().email(INVALID_EMAIL).nullable(),
    bio: Yup.string().nullable(),
    phone: Yup.string().min(11, INVALID_PHONE).nullable(),
    whatsapp: Yup.string().min(11, INVALID_PHONE).nullable(),
    youtube_url: Yup.string()
      .test('youtube_url', INVALID_URL, (value) => validateURL(value) || !value?.length)
      .nullable(),
    facebook_url: Yup.string()
      .test('facebook_url', INVALID_URL, (value) => validateURL(value) || !value?.length)
      .nullable(),
    instagram_url: Yup.string()
      .test('instagram_url', INVALID_URL, (value) => validateURL(value) || !value?.length)
      .nullable(),
    tiktok_url: Yup.string()
      .test('tiktok_url', INVALID_URL, (value) => validateURL(value) || !value?.length)
      .nullable(),
    behance_url: Yup.string()
      .test('behance_url', INVALID_URL, (value) => validateURL(value) || !value?.length)
      .nullable(),
    username: Yup.string().required(REQUIRED_FIELD),
  },
  initialValues: {
    avatar: '',
    name: '',
    username: '',
    email: '',
    bio: '',
    cover: '',
    phone: '',
    whatsapp: '',
    youtube_url: '',
    facebook_url: '',
    instagram_url: '',
    tiktok_url: '',
    behance_url: '',
  },
};

publicProfileFormDefinitions.validationSchema = Yup.object().shape(
  publicProfileFormDefinitions.schema,
);

const checkoutFormDefinitions = {
  schema: {
    card_number: Yup.string().when('payment_method', (payment_method) =>
      payment_method === 'credit_card'
        ? Yup.string()
            .required(REQUIRED_FIELD)
            .test('card_number', INVALID_CARD_NUMBER, (value) => {
              if (!value?.length) return false;
              //TODO: Criar validação de cartão
              return true;
            })
        : Yup.string().nullable(),
    ),
    cpf: Yup.string().test('cpf', INVALID_CPF, (value) => validateCPF(value)),
    phone: Yup.string().min(11, INVALID_PHONE).required(REQUIRED_FIELD),
    verification_value: Yup.string().when('payment_method', (payment_method) =>
      payment_method === 'credit_card'
        ? Yup.string()
            .required(REQUIRED_FIELD)
            .test('verification_value', INVALID_CVV, function (value) {
              if (!value?.length || !this.parent.card_number?.length) return false;
              //TODO: VALIDAÇÃO DE CVV
              return true;
            })
        : Yup.string().nullable(),
    ),
    holder_name: Yup.string().when('payment_method', (payment_method) =>
      payment_method === 'credit_card'
        ? Yup.string().required(REQUIRED_FIELD)
        : Yup.string().nullable(),
    ),
    expire_date: Yup.string().when('payment_method', (payment_method) =>
      payment_method === 'credit_card'
        ? Yup.string()
            .required(REQUIRED_FIELD)
            .test('expire_date', INVALID_EXPIRE_DATE, (value) => {
              if (!value?.length) return false;
              //TODO: VALIDAR EXP_DATE VALID
              return true;
            })
        : Yup.string().nullable(),
    ),
    ...requiredAddressFormDefinitions.schema,
  },
  initialValues: {
    card_number: '',
    verification_value: '',
    holder_name: '',
    expire_date: '',
    cpf: '',

    ...requiredAddressFormDefinitions.initialValues,
  },
};

checkoutFormDefinitions.validationSchema = Yup.object().shape(checkoutFormDefinitions.schema);

const searchBarFormDefinitions = {
  schema: {
    text: Yup.string().nullable(),
    date: Yup.date().typeError(INVALID_DATE).nullable(),
    location: Yup.string().nullable(),
  },
  initialValues: {
    text: '',
    date: null,
    location: '',
  },
};

searchBarFormDefinitions.validationSchema = Yup.object().shape(searchBarFormDefinitions.schema);

export {
  eventFormDefinitions,
  loginFormDefinitions,
  registerFormDefinitions,
  passwordRecoveryFormDefinitions,
  passwordChangeFormDefinitions,
  photographerProfileFormDefinitions,
  shopperProfileFormDefinitions,
  addressFormDefinitions,
  requiredAddressFormDefinitions,
  bankDataFormDefinitions,
  publicProfileFormDefinitions,
  checkoutFormDefinitions,
  searchBarFormDefinitions,
};
