import { makeAutoObservable } from 'mobx';

import PaginationInitialMeta from '../../constants/PaginationInitialMeta';
import baseAPI from '../../services/baseAPI';
import { maskCEP } from '../../utils';
import Order from '../models/Order';
import User from '../models/User';

export default class UserStore {
  rootStore;
  profile = null;
  publicProfile = null;
  publicPhotographerProfile = null;
  collectionMeta = { ...PaginationInitialMeta };
  ordersMeta = { ...PaginationInitialMeta };
  billingMeta = { ...PaginationInitialMeta };
  ordersList = [];
  ordersStatistics = null;
  collectionList = [];
  billingList = [];
  ordersTotal = 0;
  billingTotal = 0;
  billingBalance = 0;

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this, { rootStore: false });
  }

  getUser = async () => {
    try {
      const response = await baseAPI.get('/me');

      const { status, data } = response;

      if (status !== 200 || !data) {
        return {
          error: {
            status,
          },
        };
      }

      this.profile = new User(data);

      return true;
    } catch (error) {
      console.warn(error);

      return {
        error: {
          status: error?.response?.status || 400,
        },
      };
    }
  };

  getPublicUser = async () => {
    try {
      const response = await baseAPI.get('/users/photographer/public-profile');

      const { status, data } = response;

      if (status !== 200 || !data) {
        return {
          error: {
            status,
          },
        };
      }

      const publicProfile = { ...data.profile };

      if (data.address) publicProfile.address = data.address;

      this.publicProfile = publicProfile;

      return true;
    } catch (error) {
      console.warn(error);

      return {
        error: {
          status: error?.response?.status || 400,
        },
      };
    }
  };

  register = async (payload = {}) => {
    try {
      const { type, name, email, password, legal_consent } = payload;

      const response = await baseAPI.post('/users', {
        type: type + 1,
        name,
        email,
        password,
        legal_consent,
      });

      const { status } = response;

      if (status !== 200) {
        return {
          error: {
            status,
          },
        };
      }

      return true;
    } catch (error) {
      console.warn(error);

      return {
        error: {
          status: error?.response?.status || 400,
        },
      };
    }
  };

  editProfile = async (payload = {}) => {
    try {
      const {
        avatar,
        name,
        phone,
        cpf_cnpj,
        zip,
        street,
        number,
        complement,
        district,
        city,
        state,
        gateway_account_bank,
        gateway_account_bank_ag,
        gateway_account_bank_account_type,
        gateway_account_bank_cc,
        gateway_account_bank_operation,
      } = payload;

      const userRole =
        this.rootStore.UserStore.profile?.type === 1 ? 'photographer' : 'shopper';

      const formData = new FormData();

      if (name?.length) formData.append('name', name);
      if (avatar instanceof File) formData.append('avatar', avatar);
      formData.append('phone_prefix', phone.substring(0, 2));
      formData.append('phone', phone.substring(2, 11));
      formData.append('cpf_cnpj', cpf_cnpj);
      formData.append('address[zip]', maskCEP(zip));
      formData.append('address[street]', street);
      formData.append('address[number]', number);
      formData.append('address[complement]', complement);
      formData.append('address[district]', district);
      formData.append('address[city]', city);
      formData.append('address[state]', state);
      if (userRole === 'photographer') {
        if (gateway_account_bank?.length) {
          formData.append('bank_data[gateway_account_bank]', gateway_account_bank);
        }
        if (gateway_account_bank_ag?.length) {
          formData.append('bank_data[gateway_account_bank_ag]', gateway_account_bank_ag);
        }
        if (gateway_account_bank_account_type?.length) {
          formData.append(
            'bank_data[gateway_account_bank_account_type]',
            gateway_account_bank_account_type,
          );
        }
        if (gateway_account_bank_cc?.length) {
          if (
            gateway_account_bank === 'Caixa Econômica' &&
            gateway_account_bank_operation?.length
          ) {
            formData.append(
              'bank_data[gateway_account_bank_cc]',
              gateway_account_bank_operation + gateway_account_bank_cc,
            );
          } else {
            formData.append('bank_data[gateway_account_bank_cc]', gateway_account_bank_cc);
          }
        }
      }

      formData.append('_method', 'put');

      const response = await baseAPI.post(`/users/${userRole}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const { status, data } = response;

      if (status === 400) {
        return {
          error: {
            status,
            message: data.message,
          },
        };
      }

      if (status !== 200 || !data) {
        return {
          error: {
            status,
          },
        };
      }

      this.profile = new User(data);

      return true;
    } catch (error) {
      console.warn(error);

      return {
        error: {
          status: error?.response?.status || 400,
        },
      };
    }
  };

  editPublicProfile = async (payload = {}) => {
    try {
      const {
        avatar,
        cover,
        name,
        phone,
        whatsapp,
        email,
        zip,
        street,
        number,
        complement,
        district,
        city,
        state,
        bio,
        behance_url,
        facebook_url,
        instagram_url,
        tiktok_url,
        youtube_url,
        username,
      } = payload;

      const formData = new FormData();

      formData.append('avatar', avatar);
      formData.append('cover', cover);

      formData.append('name', name);
      formData.append('username', username);

      if (phone?.length) {
        formData.append('phone_prefix', phone.substring(0, 2));
        formData.append('phone', phone?.substring(2, 11));
      }
      if (whatsapp?.length) {
        formData.append('whatsapp_prefix', whatsapp.substring(0, 2));
        formData.append('whatsapp', whatsapp?.substring(2, 11));
      }
      if (email?.length) formData.append('email', email);
      if (zip?.length) formData.append('address[zip]', maskCEP(zip));
      if (street?.length) formData.append('address[street]', street);
      if (number) formData.append('address[number]', number);
      if (complement?.length) formData.append('address[complement]', complement);
      if (district?.length) formData.append('address[district]', district);
      if (city?.length) formData.append('address[city]', city);
      if (state?.length) formData.append('address[state]', state);
      if (bio?.length) formData.append('bio', bio);
      if (behance_url?.length) formData.append('behance_url', behance_url);
      if (facebook_url?.length) formData.append('facebook_url', facebook_url);
      if (instagram_url?.length) formData.append('instagram_url', instagram_url);
      if (tiktok_url?.length) formData.append('tiktok_url', tiktok_url);
      if (youtube_url?.length) formData.append('youtube_url', youtube_url);

      // formData.append('_method', 'put');

      const response = await baseAPI.post(`/users/photographer/public-profile`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const { status, data } = response;

      if (status !== 200 || !data?.profile) {
        return {
          error: {
            status,
          },
        };
      }

      const publicProfile = { ...data.profile };

      if (data.address) publicProfile.address = data.address;

      this.publicProfile = publicProfile;

      return true;
    } catch (error) {
      console.warn(error);

      return {
        error: {
          status: error?.response?.status || 400,
        },
      };
    }
  };

  editSelfie = async (payload = {}) => {
    try {
      const { selfie } = payload;

      const formData = new FormData();

      if (selfie instanceof File) formData.append('avatar', selfie);

      formData.append('_method', 'put');

      const response = await baseAPI.post(`/users/shopper/selfie`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const { status, data } = response;

      if (status !== 200 || !data) {
        return {
          error: {
            status,
          },
        };
      }

      this.profile = new User(data);

      return true;
    } catch (error) {
      console.warn(error);

      return {
        error: {
          status: error?.response?.status || 400,
        },
      };
    }
  };

  validateUsername = async (payload = {}) => {
    try {
      const { username } = payload;

      const response = await baseAPI.get(
        '/users/photographer/public-profile/validate-username',
        {
          params: {
            username,
          },
        },
      );

      const { status } = response;

      if (status !== 204) {
        return {
          error: {
            status,
          },
        };
      }

      return true;
    } catch (error) {
      console.warn(error);

      return {
        error: {
          status: error?.response?.status || 400,
        },
      };
    }
  };

  recoverPassword = async (payload = {}) => {
    try {
      const { email } = payload;

      const response = await baseAPI.post('/forgot-password', {
        email,
      });

      const { status } = response;

      if (status !== 200) {
        return {
          error: {
            status,
          },
        };
      }

      return true;
    } catch (error) {
      console.warn(error);

      return {
        error: {
          status: error?.response?.status || 400,
        },
      };
    }
  };

  changePassword = async (payload = {}) => {
    try {
      const { password, password_confirmation, token, email } = payload;

      const response = await baseAPI.post('/reset-password', {
        password,
        password_confirmation,
        token,
        email,
      });

      const { status } = response;

      if (status !== 200) {
        return {
          error: {
            status,
          },
        };
      }

      return true;
    } catch (error) {
      console.warn(error);

      return {
        error: {
          status: error?.response?.status || 400,
        },
      };
    }
  };

  redefinePassword = async (payload = {}) => {
    try {
      const { password } = payload;

      const response = await baseAPI.put('/password', {
        password,
      });

      const { status } = response;

      if (status !== 200) {
        return {
          error: {
            status,
          },
        };
      }

      return true;
    } catch (error) {
      console.warn(error);

      return {
        error: {
          status: error?.response?.status || 400,
        },
      };
    }
  };

  deleteAccount = async () => {
    try {
      const response = await baseAPI.delete('/users');

      const { status } = response;

      if (status !== 200) {
        return {
          error: {
            status,
          },
        };
      }

      this.rootStore.AuthStore.unauthenticate();

      return true;
    } catch (error) {
      console.warn(error);

      return {
        error: {
          status: error?.response?.status || 400,
        },
      };
    }
  };

  getOrders = async (payload = {}) => {
    try {
      const { page = 1 } = payload;

      const params = {};

      if (page > 1) params.page = page;

      const response = await baseAPI.get('/orders', {
        params,
      });

      const { status: responseStatus, data } = response;

      if (responseStatus === 200 && !data?.data?.length) {
        return true;
      }

      if (responseStatus !== 200 || !data?.data || !data?.meta) {
        return {
          error: {
            status: responseStatus,
          },
        };
      }

      this.ordersMeta = data.meta;
      this.ordersList = data.data.map((order) => new Order(order));
      this.ordersStatistics = data.order_statistics;

      return true;
    } catch (error) {
      console.warn(error);

      return {
        error: {
          status: error?.response?.status || 400,
        },
      };
    }
  };

  getCollection = async (payload = {}) => {
    try {
      const { page = 1 } = payload;

      const params = {};

      if (page > 1) params.page = page;

      const response = await baseAPI.get('/orders', {
        params,
      });

      const { status: responseStatus, data } = response;

      if (responseStatus === 200 && !data?.data?.length) {
        return true;
      }

      if (responseStatus !== 200 || !data?.data || !data?.meta) {
        return {
          error: {
            status: responseStatus,
          },
        };
      }

      this.collectionMeta = data.meta;

      const collectionList = [];

      for (const order of data.data) {
        for (const photographerOrder of order.photographerOrders) {
          collectionList.push(new Order(photographerOrder));
        }
      }

      this.collectionList = collectionList;

      return true;
    } catch (error) {
      console.warn(error);

      return {
        error: {
          status: error?.response?.status || 400,
        },
      };
    }
  };

  getBilling = async (payload = {}) => {
    try {
      const { page = 1 } = payload;

      const params = {};

      if (page > 1) params.page = page;

      const response = await baseAPI.get('/billing', {
        params,
      });

      const { status: responseStatus, data } = response;

      if (responseStatus !== 200 || !data?.data || !data?.meta) {
        return {
          error: {
            status: responseStatus,
          },
        };
      }

      this.billingMeta = data.meta;
      this.billingList = data.data.billing;
      this.billingTotal = data.data.total;
      this.billingBalance = data.data.balance;

      return true;
    } catch (error) {
      console.warn(error);

      return {
        error: {
          status: error?.response?.status || 400,
        },
      };
    }
  };

  getPublicProfile = async (payload = {}) => {
    try {
      const { username } = payload;

      const response = await baseAPI.get(`/photographer/${username}`);

      const { status, data } = response;

      if (status !== 200 || !data) {
        return {
          error: {
            status,
          },
        };
      }

      this.publicPhotographerProfile = data;

      return true;
    } catch (error) {
      console.warn(error);

      return {
        error: {
          status: error?.response?.status || 400,
        },
      };
    }
  };
}
