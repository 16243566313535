const timestampDateTime = () => {
  let today = new Date();
  let d = String(today.getDate()).padStart(2, '0');
  let m = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  let y = today.getFullYear();
  let hh = String(today.getHours()).padStart(2, '0');
  let mm = String(today.getMinutes()).padStart(2, '0');
  let ss = String(today.getSeconds()).padStart(2, '0');
  let mmm = String(today.getMilliseconds()).padStart(4, '0');

  return y + '-' + m + '-' + d + ' ' + hh + ':' + mm + ':' + ss + '.' + mmm;
};

export default timestampDateTime;
