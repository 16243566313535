import Container from '@material-ui/core/Container';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { Button, Loader, Modal, Tabs, Text } from '..';
import {
  UPLOAD_ERROR,
  /* UPLOAD_SUCCESS */
} from '../../constants/Messages';
import * as Theme from '../../constants/Theme';
import { useStore } from '../../hooks';
import {
  ArrowDownIcon,
  CheckIcon,
  CloseIcon,
  ErrorIcon,
  HelpIcon,
  MaximizeIcon,
  MinimizeIcon,
  PhotoIcon,
  WarningIcon,
} from '../svg';
import './styles.scss';

const UploadQueue = () => {
  const store = useStore();
  const [closeQueueModalIsOpen, setCloseQueueModalIsOpen] = useState(false);
  const [compressionModalIsOpen, setCompressionModalIsOpen] = useState(false);

  const tabs = [
    {
      label: 'Todas',
      key: 'todas',
    },
    {
      label: 'Aguardando',
      key: 'idle',
    },
    {
      label: 'Em progresso',
      key: 'requesting',
    },
    {
      label: 'Sucesso',
      key: 'success',
    },
    {
      label: 'Duplicadas',
      key: 'duplicated',
    },
    {
      label: 'Erro',
      key: 'error',
    },
    {
      label: 'Cancelados',
      key: 'cancelled',
    },
  ];

  let activeTab = 0;

  const handleFilesDrop = async (event) => {
    event.preventDefault();
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleTabChange = (index) => {
    activeTab = index;
    store.UploadStore.active_status_filter = tabs[index].key;
  };

  const uploadQueueClassNames = () => {
    let newClassNames = 'upload-queue surface-9';

    if (store.UploadStore.queueIsOpen) {
      newClassNames += ' upload-queue--open';
    }

    if (store.UploadStore.queueIsMaximized) {
      newClassNames += ' upload-queue--maximized';
    }

    if (store.UploadStore.queueIsVisible) {
      newClassNames += ' upload-queue--visible';
    }

    return newClassNames;
  };

  const compressionModalClassNames = () => {
    let classNames = 'compression-modal';

    if (store.UploadStore.queueIsVisible) {
      classNames += ' compression-modal--queue-is-visible';
    }

    if (store.UploadStore.queueIsOpen) {
      classNames += ' compression-modal--queue-is-open';
    }

    return classNames;
  };

  const restartQueue = () => {
    store.UploadStore.restartQueue();
  };

  const cancelUpload = async () => {
    const response = store.UploadStore.cancelQueue();

    if (response?.error) {
      toast.error(UPLOAD_ERROR);
      return;
    }
  };

  const closeQueue = () => {
    setCloseQueueModalIsOpen(false);
    store.UploadStore.closeQueue();
  };

  const renderItemStatus = (id, status) => {
    switch (status) {
      case 'requesting': {
        return (
          <div title='Realizando upload' className='file__upload-state__title'>
            <Loader color={Theme.primary} />
          </div>
        );
      }

      case 'error': {
        return (
          <div title='Erro no upload' className='file__upload-state__title'>
            <ErrorIcon color={Theme.error} />
          </div>
        );
      }

      case 'duplicated': {
        return (
          <div title='Duplicated' className='file__upload-state__title'>
            <WarningIcon color={Theme.warning} />
          </div>
        );
      }

      case 'cancelled': {
        return (
          <div title='Upload cancelado' className='file__upload-state__title'>
            <WarningIcon color={Theme.warning} />
          </div>
        );
      }

      case 'success': {
        return (
          <div title='Upload concluído' className='file__upload-state__title'>
            <CheckIcon color={Theme.success} />
          </div>
        );
      }

      default: {
        return (
          <div title='Remover foto da lista' className='file__upload-state__title'>
            <Button size='micro' onClick={() => store.UploadStore.removeItemFromQueue(id)}>
              <CloseIcon />
            </Button>
          </div>
        );
      }
    }
  };

  const renderTopContent = () => {
    if (!store.UploadStore.queuePendingFilesCount && !store.UploadStore.uploading) {
      return (
        <>
          <CheckIcon color={Theme.success} />
          <Text>
            {
              store.UploadStore.queue.filter(({ status }) => ['success'].includes(status))
                .length
            }{' '}
            fotos enviadas com sucesso
          </Text>
        </>
      );
    }

    return (
      <>
        {store.UploadStore.uploading ? <Loader /> : null}{' '}
        <Text>
          {`Faltam ${store.UploadStore.queuePendingFilesCount} fotos de um total de ${store.UploadStore.queue.length}`}
        </Text>
      </>
    );
  };

  const preventNavigation = (event) => {
    event.preventDefault();
    event.returnValue = '';
  };

  useEffect(() => {
    if (store.UploadStore.uploading) {
      window.addEventListener('beforeunload', preventNavigation, true);
    } else {
      window.removeEventListener('beforeunload', preventNavigation, true);
    }

    return () => {
      window.removeEventListener('beforeunload', preventNavigation, true);
    };
  }, [store.UploadStore.uploading]);

  useEffect(() => {
    return () => {
      window.removeEventListener('beforeunload', preventNavigation, true);
    };
  }, []);

  if (!store.UploadStore.queue?.length) return null;

  return (
    <Container maxWidth={Theme.containerMaxWidth} className='upload-queue__container'>
      <div
        className={uploadQueueClassNames()}
        onDrop={handleFilesDrop}
        onDragOver={handleDragOver}
      >
        <div className='upload-queue__top  surface-11 --with-heading'>
          <div className='uploaderHeading'>Uploader v2</div>
          <div className='upload-queue__top__row'>
            <div className='upload-queue__top__content'>{renderTopContent()}</div>

            <div className='upload-queue__top__actions'>
              <Button
                className='bt-toggle-queue'
                onClick={store.UploadStore.toggleQueueMinimized}
              >
                <ArrowDownIcon title='Minizar' />
              </Button>
              <Button
                className='bt-toggle-maximized'
                onClick={store.UploadStore.toggleQueueMaximized}
              >
                <MaximizeIcon title='Maximizar' size={16} />
                <MinimizeIcon title='Minimizar' size={16} />
              </Button>
              <Button
                className='bt-close-queue'
                onClick={() => {
                  store.UploadStore.uploading ? setCloseQueueModalIsOpen(true) : closeQueue();
                }}
              >
                <CloseIcon />
              </Button>
              <Modal
                open={closeQueueModalIsOpen}
                btCancelText='Voltar'
                btConfirmText='Concluir'
                btCancelColor='light'
                onCancel={() => setCloseQueueModalIsOpen(false)}
                onConfirm={closeQueue}
              >
                <Text variant='h6' className='modal-title'>
                  Você tem certeza?
                </Text>
                <Text variant='body-1' color={Theme.light60}>
                  Ao fechar a fila de uploads, você interrompe o carregamento das fotos
                  restantes.
                </Text>
              </Modal>
            </div>
          </div>
          <div className='upload-queue__top__row'>
            <FormControlLabel
              control={
                <Switch
                  checked={store.UploadStore.compressImages}
                  onChange={store.UploadStore.toggleImageCompression}
                  name='image-compression'
                  color='primary'
                />
              }
              label='Otimizar fotos antes de enviar'
              // labelPlacement='start'
            />
            <Button
              className='bt-toggle-compression-modal'
              onClick={() => setCompressionModalIsOpen(true)}
            >
              <HelpIcon />
            </Button>
            <Modal
              rounded={false}
              className={compressionModalClassNames()}
              open={compressionModalIsOpen}
              onCancel={() => {
                setCompressionModalIsOpen(false);
              }}
              showBtCancel={false}
              showBtConfirm={false}
              showBtClose={true}
            >
              <div className='compression-modal__title'>
                <Text variant='h5' color={Theme.primary}>
                  Compressão das fotos
                </Text>
              </div>

              <div className='compression-modal__description'>
                <Text variant='body-1' color={Theme.light87}>
                  Entenda as duas formas de compressão que as suas fotos podem ter.
                </Text>
              </div>

              <div className='compression-modal__content'>
                <article>
                  <div className='compression-modal__content__title'>
                    <Text variant='h6' color={Theme.primary}>
                      Compressão local
                    </Text>
                  </div>

                  <div className='compression-modal__content__description'>
                    <Text variant='body-1' color={Theme.light87}>
                      Não se preocupe com a internet! As fotos serão comprimidas no seu
                      navegador, antes do upload (Ideal para conexões móveis e/ou máquinas com
                      melhor desempenho).
                    </Text>
                  </div>
                </article>

                <article>
                  <div className='compression-modal__content__title'>
                    <Text variant='h6' color={Theme.primary}>
                      Compressão remota (nuvem)
                    </Text>
                  </div>

                  <div className='compression-modal__content__description'>
                    <Text variant='body-1' color={Theme.light87}>
                      Vamos mais rápido! Faremos o upload das fotos originais e a compressão
                      será realizada na nuvem (Ideal para conexões rápidas, via cabo ou Wi-fi,
                      e/ou máquinas com menor desempenho).
                    </Text>
                    <Text variant='body-1' color={Theme.light87}>
                      Não sabe o que escolher? Fique tranquilo! Essa opção pode ser alterada na
                      fila de upload.
                    </Text>
                  </div>
                </article>
              </div>
            </Modal>
          </div>
        </div>

        <div className='upload-queue__tabs'>
          <Tabs onChange={handleTabChange} value={activeTab} tabs={tabs} />
        </div>

        <div className='upload-queue__progress surface-0'>
          <Text>{`Progresso total ${store.UploadStore.queueProgress}%`}</Text>

          <div className='upload-queue__progress__actions'>
            {store.UploadStore.queuePendingFilesCount ? (
              <Button className='bt-cancel' size='micro' onClick={cancelUpload}>
                Cancelar
              </Button>
            ) : !store.UploadStore.uploading && store.UploadStore.queueRetryFilesCount ? (
              <Button className='bt-cancel' size='micro' onClick={restartQueue}>
                Reiniciar upload
              </Button>
            ) : null}
          </div>
        </div>

        <div className='upload-queue__scroll'>
          {store.UploadStore.filterQueueByStatus.map(({ id, file, status }) => (
            <div className='file' key={id}>
              <PhotoIcon color={Theme.primary} />
              <div className='file__name'>
                {status == 'duplicated' ? '[Duplicata - Não enviada] ' : ''}
                {file.name}
              </div>
              <div className='file__upload-state'>{renderItemStatus(id, status)}</div>
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
};

export default observer(UploadQueue);
