import PropTypes from 'prop-types';

import * as Theme from '../../../constants/Theme';

const RestoreIcon = (props) => {
  const { color, size } = props;

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13 3C8.03 3 4 7.03 4 12H1L4.89 15.89L4.96 16.03L9 12H6C6 8.13 9.13 5 13 5C16.87 5 20 8.13 20 12C20 15.87 16.87 19 13 19C11.07 19 9.32 18.21 8.06 16.94L6.64 18.36C8.27 19.99 10.51 21 13 21C17.97 21 22 16.97 22 12C22 7.03 17.97 3 13 3ZM12 8V13L16.28 15.54L17 14.33L13.5 12.25V8H12Z'
        fill={color}
      />
    </svg>
  );
};

RestoreIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

RestoreIcon.defaultProps = {
  color: Theme.light60,
  size: 24,
};

export default RestoreIcon;
