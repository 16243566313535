import { AppBar, Container, Menu as MUIMenu, MenuItem } from '@material-ui/core';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Avatar, Button, Drawer, InstagramBrowserModal, Logo, Menu } from '..';
import * as Theme from '../../constants/Theme';
import { useStore } from '../../hooks';
import { ArrowDownIcon, ShoppingCartIcon } from '../svg';
import './styles.scss';

const Header = (props) => {
  const { user } = props;
  const history = useHistory();
  const store = useStore();
  const location = useLocation();
  const [userDropdownAnchor, setUserDropdownAnchor] = useState(null);

  const handleUserClick = (event) => {
    setUserDropdownAnchor(event.currentTarget);
  };

  const handleCloseUserDropdown = () => {
    setUserDropdownAnchor(null);
  };

  const isRouteActive = (route) => location?.pathname?.includes(route);

  const getHeaderUserClassName = () => {
    let newClassName = 'header__user__name';
    if (isRouteActive('profile')) newClassName += ' active';
    return newClassName;
  };

  return (
    <AppBar position='fixed' className='header'>
      <InstagramBrowserModal />
      <Container size={Theme.containerMaxWidth}>
        <div className='header__content'>
          <div className='header__drawer'>
            <Drawer user={user} />
          </div>

          <div onClick={() => history.push('/')} className='header__logo'>
            <Logo />
          </div>

          <div className='header__menu'>
            <Menu user={user} />
          </div>
          <div className='header__user'>
            <Avatar image={user?.avatar_url} />
            <Button className={getHeaderUserClassName()} onClick={handleUserClick}>
              {user?.name}
              <ArrowDownIcon color={isRouteActive('profile') ? Theme.primary : Theme.light87} />
            </Button>
            <MUIMenu
              anchorEl={userDropdownAnchor}
              open={Boolean(userDropdownAnchor)}
              onClose={handleCloseUserDropdown}
              getContentAnchorEl={null}
              className='header__user__dropdown'
            >
              <MenuItem
                onClick={() => {
                  handleCloseUserDropdown();
                  history.push('/profile');
                }}
              >
                Meu perfil
              </MenuItem>
              <MenuItem
                onClick={() => {
                  window.open('https://rokkos.app/politica-de-privacidade/', '_blank');
                }}
              >
                Política de Privacidade
              </MenuItem>
              <MenuItem
                onClick={() => {
                  window.open('https://rokkos.app/termos-de-uso-e-servico/', '_blank');
                }}
              >
                Termos de Uso
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleCloseUserDropdown();
                  store.AuthStore.unauthenticate();
                }}
              >
                Sair
              </MenuItem>
            </MUIMenu>
          </div>
          {user?.type === 2 ? (
            <div className='header__shopping-cart'>
              <Button onClick={() => history.push('/checkout/cart')}>
                <ShoppingCartIcon
                  color={isRouteActive('checkout') ? Theme.primary : Theme.light87}
                />
              </Button>
              {store.CheckoutStore.cart?.cart_events?.length ? (
                <div
                  className='header__shopping-cart__count'
                  onClick={() => history.push('/checkout/cart')}
                >
                  {store.CheckoutStore.cartLength}
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      </Container>
    </AppBar>
  );
};

Header.propTypes = {
  user: PropTypes.shape({
    type: PropTypes.number,
    name: PropTypes.string,
    avatar_url: PropTypes.string,
  }).isRequired,
};

export default observer(Header);
