import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { Avatar, Button, Modal, Text } from '..';
import * as Theme from '../../constants/Theme';
import { TrashIcon } from '../svg';
import './styles.scss';

const PhotoModal = (props) => {
  const { variant, modalProps, photo, showBtRemove, onClickAction } = props;

  const getPhotoModalClassName = () => {
    let newClassName = 'photo-modal';

    if (variant) newClassName += ` photo-modal--${variant}`;

    return newClassName;
  };

  return (
    <Modal rounded={false} className={getPhotoModalClassName()} {...modalProps}>
      <img src={photo.preview_url} alt={photo.original_filename} />
      <span className='original_filename'>{photo.original_filename}</span>
      {variant === 'photographer' ? (
        onClickAction && showBtRemove ? (
          <Button className='photo-modal__button surface-11' onClick={onClickAction}>
            <TrashIcon color={Theme.light87} />
          </Button>
        ) : null
      ) : (
        <div className='photo-modal__details'>
          <div className='photo-modal__id'>
            <Text variant='h4' responsive>
              {photo?.id ? photo.id : ''}
            </Text>
          </div>
          <div className='photo-modal__name'>
            <Text variant='h5' color={Theme.light60}>
              {photo?.event ? photo.event.name : ''}
            </Text>
          </div>
          {photo?.owner ? (
            <div className='photo-modal__owner'>
              <Avatar image={photo.owner.avatar_url} size='small' />
              <Text variant='h6' color={Theme.light60}>
                {photo.owner.name}
              </Text>
            </div>
          ) : null}
          {photo.price ? (
            <div className='photo-modal__price'>
              <Text variant='h4' responsive>
                R$ {String(photo.price.toFixed(2)).replace('.', ',')}
              </Text>
            </div>
          ) : null}

          <Button
            startIcon={<TrashIcon color='#000' />}
            variant='contained'
            fullWidth
            onClick={onClickAction}
          >
            Adicionar ao carrinho
          </Button>
        </div>
      )}
    </Modal>
  );
};

PhotoModal.propTypes = {
  modalProps: PropTypes.instanceOf(Object),
  photo: PropTypes.shape({
    id: PropTypes.number,
    preview_url: PropTypes.string,
    original_filename: PropTypes.string,
    price: PropTypes.number,
    owner: PropTypes.shape({
      name: PropTypes.string,
      avatar_url: PropTypes.string,
    }),
    event: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  }).isRequired,
  showBtRemove: PropTypes.bool,
  variant: PropTypes.oneOf(['photographer', 'shopper']),
  onClickAction: PropTypes.func,
};

PhotoModal.defaultProps = {
  modalProps: {},
  showBtRemove: true,
  variant: 'photographer',
  onClickAction: null,
};

export default observer(PhotoModal);
