import { Checkbox, FormControlLabel } from '@material-ui/core';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { Button, Loader, Modal, PhotoModal, Stepper, Text } from '..';
import {
  DELETE_PHOTOS_SUCCESS,
  DELETE_PHOTO_SUCCESS,
  SYSTEM_INSTABILITY,
} from '../../constants/Messages';
import * as Theme from '../../constants/Theme';
import { useStore } from '../../hooks';
import { TrashIcon } from '../svg';
import './styles.scss';

const PhotoTableCondensedItem = ({
  photo,
  variant,
  showActionButton,
  checked,
  requesting,
  deleted,
  onCheck,
  onDeletePhoto,
}) => {
  const { id, thumbnail_url, original_filename, photo_frontend_optimized, photo_status } =
    photo;
  const [photoModalIsOpen, setPhotoModalIsOpen] = useState(false);
  const [deletePhotoModalIsOpen, setDeletePhotoModalIsOpen] = useState(false);

  const renderActionIcon = () => {
    if (requesting)
      return (
        <div title='Apagando item'>
          <Loader />
        </div>
      );

    return (
      <div title='Apagar item'>
        <TrashIcon color={Theme.light60} />
      </div>
    );
  };

  const deletePhoto = async () => {
    try {
      setDeletePhotoModalIsOpen(false);
      setPhotoModalIsOpen(false);

      await onDeletePhoto();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      key={id}
      className='photo-grid-condensed__item'
      style={{ opacity: deleted ? 0.25 : 1 }}
    >
      <div className='col1 image-wrapper'>
        <FormControlLabel
          className='checkbox'
          control={
            <Checkbox
              checked={checked}
              onChange={(event) => onCheck(event.target.checked)}
              color='primary'
            />
          }
        />
      </div>
      <div className='col2 image-wrapper'>
        <img
          src={thumbnail_url}
          className='photo-grid__item__image'
          onClick={() => setPhotoModalIsOpen(true)}
        />
      </div>
      <div className='col3 image-id'>{id}</div>
      <div className='col4 image-filename'>{original_filename}</div>
      <div className='col5 image-process-status'>
        <Stepper
          activeStep={photo_status}
          steps={[
            {
              label: 'Otimização local',
              error: !photo_frontend_optimized,
              completed: photo_frontend_optimized,
            },
            {
              label: 'Upload otimizado',
              completed: photo_status >= 1,
            },
            {
              label: 'Otimização avançada',
              completed: photo_status >= 2,
            },
            {
              label: 'Reconhecimento facial',
              completed: photo_status >= 3,
            },
            {
              label: 'Disponível para venda',
              completed: photo_status >= 4,
            },
            {
              label: 'Venda realizada!',
              completed: photo_status >= 5,
            },
          ]}
          alternativeLabel={true}
        />
      </div>
      <div className='col6 image-actions'>
        {showActionButton ? (
          <Button
            className={`photo-list__item__button ${requesting ? ' visible' : ''}`}
            onClick={() => setDeletePhotoModalIsOpen(true)}
          >
            {renderActionIcon(requesting)}
          </Button>
        ) : null}
      </div>
      <PhotoModal
        modalProps={{
          open: photoModalIsOpen,
          onCancel: () => {
            setPhotoModalIsOpen(false);
          },
          showBtCancel: false,
          showBtConfirm: false,
          showBtClose: true,
        }}
        photo={photo}
        variant={variant}
        onClickAction={() => setDeletePhotoModalIsOpen(true)}
      />
      <Modal
        open={deletePhotoModalIsOpen}
        btConfirmText='Excluir'
        btCancelColor='light'
        onCancel={() => setDeletePhotoModalIsOpen(false)}
        onConfirm={deletePhoto}
      >
        <Text variant='subtitle-1' color={Theme.light87}>
          Deseja excluir a foto {id}?
        </Text>
      </Modal>
    </div>
  );
};

const PhotoTableCondensed = (props) => {
  const { event, photos, variant, showActionButton, onDeletePhoto } = props;

  const store = useStore();

  const [deletingSelectedPhotos, setDeletingSelectedPhotos] = useState(false);
  const [listState, setListState] = useState([]);
  const [deletePhotosModalIsOpen, setDeletePhotosModalIsOpen] = useState(false);

  const deletePhotos = async (_photos, _deletingSelectedPhotos) => {
    try {
      setListState((list) =>
        list.map((item) =>
          _photos.map(({ id }) => id).includes(item.id) ? { ...item, requesting: true } : item,
        ),
      );

      const response = await flowResult(
        store.PhotoStore.remove({ photos_id: _photos.map(({ id }) => id) }),
      );

      if (response.error) {
        toast.error(SYSTEM_INSTABILITY);
        return;
      }

      onDeletePhoto();

      if (!_deletingSelectedPhotos) toast.success(DELETE_PHOTO_SUCCESS);
    } catch (error) {
      console.log(error);
    } finally {
      setListState((list) =>
        list.map((item) =>
          _photos.map(({ id }) => id).includes(item.id) ? { ...item, requesting: false } : item,
        ),
      );
    }
  };

  const deleteSelectedPhotos = async () => {
    try {
      setDeletingSelectedPhotos(true);
      setDeletePhotosModalIsOpen(false);

      const photosToDelete = photos.filter(
        (_, index) => listState[index].checked && !listState[index].requesting,
      );

      await deletePhotos(photosToDelete, true);

      onDeletePhoto();

      toast.success(DELETE_PHOTOS_SUCCESS);
    } catch (error) {
      console.log(error);
    } finally {
      setDeletingSelectedPhotos(false);
    }
  };

  const handleCheck = (id, checked) => {
    setListState((list) => list.map((item) => (item.id === id ? { ...item, checked } : item)));
  };

  const handleCheckAll = (checked) => {
    setListState((list) => list.map((item) => ({ ...item, checked })));
  };

  useEffect(() => {
    setListState(
      photos.map(({ id }) => ({
        id,
        checked: false,
        requesting: false,
        deleted: false,
      })),
    );
  }, []);

  return (
    <div
      className='photo-grid-condensed'
      style={{
        pointerEvents: deletingSelectedPhotos ? 'none' : 'initial',
      }}
    >
      <div className='photo-grid-condensed-actions'>
        <div
          title={
            listState.filter((item) => item.checked).length
              ? 'Apagar todas as fotos selecionadas'
              : 'Selecione fotos para apagar'
          }
        >
          <Button
            onClick={() => setDeletePhotosModalIsOpen(true)}
            disabled={!listState.filter((item) => item.checked).length}
            size='micro'
            variant='outlined'
          >
            Apagar selecionadas
          </Button>
        </div>
      </div>
      <div className='photo-grid-condensed-header'>
        <div className='header col1'>
          <FormControlLabel
            className='checkbox'
            control={
              <Checkbox
                checked={!listState.find((item) => item.checked === false)}
                onChange={(event) => handleCheckAll(event.target.checked)}
                color='primary'
              />
            }
          />
        </div>
        <div className='header col2'>Miniatura</div>
        <div className='header col3'>ID</div>
        <div className='header col4'>Nome</div>
        <div className='header col5'>Status no Processo</div>
        <div className='header col6'>Ações</div>
        <Modal
          open={deletePhotosModalIsOpen}
          btConfirmText='Excluir'
          btCancelColor='light'
          onCancel={() => setDeletePhotosModalIsOpen(false)}
          onConfirm={deleteSelectedPhotos}
        >
          <Text variant='subtitle-1' color={Theme.light87}>
            Excluir as {listState.filter((item) => item.checked).length} fotos selecionadas?
          </Text>
        </Modal>
      </div>
      <div className='photo-grid-condensed-body'>
        {photos.map((photo, index) => (
          <PhotoTableCondensedItem
            key={photo.id}
            checked={listState[index]?.checked}
            requesting={listState[index]?.requesting}
            deleted={listState[index]?.deleted}
            onCheck={(checked) => handleCheck(photo.id, checked)}
            event={event}
            photo={photo}
            variant={variant}
            deletingSelectedPhotos={deletingSelectedPhotos}
            showActionButton={showActionButton}
            onDeletePhoto={() => deletePhotos([photo])}
          />
        ))}
      </div>
    </div>
  );
};

PhotoTableCondensedItem.propTypes = {
  photo: PropTypes.instanceOf(Object).isRequired,
  event: PropTypes.instanceOf(Object),
  variant: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  deleted: PropTypes.bool,
  requesting: PropTypes.bool,
  showActionButton: PropTypes.bool.isRequired,
  onCheck: PropTypes.func.isRequired,
  onDeletePhoto: PropTypes.func.isRequired,
};

PhotoTableCondensedItem.defaultProps = {
  checked: false,
  requesting: false,
  deleted: false,
};

PhotoTableCondensed.propTypes = {
  photos: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      src: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
      original_filename: PropTypes.string,
      photo_frontend_optimized: PropTypes.bool,
      photo_status: PropTypes.number,
    }).isRequired,
  ),
  event: PropTypes.instanceOf(Object),
  variant: PropTypes.oneOf(['photographer', 'shopper']),
  showActionButton: PropTypes.bool,
  onDeletePhoto: PropTypes.func,
};

PhotoTableCondensed.defaultProps = {
  photos: [],
  event: {},
  variant: 'photographer',
  showActionButton: true,
  onDeletePhoto: () => {},
};

export default observer(PhotoTableCondensed);
