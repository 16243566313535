import { Container, IconButton, Menu, MenuItem } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  Button,
  EmptyState,
  FileUploaderModal,
  Hero,
  ListLoader,
  PhotoGrid,
  Screen,
  Tabs,
  Text,
} from '../../components';
import {
  CameraAddIcon,
  CloseIcon,
  DropdownIcon,
  EmptyStatePhotoSearchIcon,
  EmptyStateSearchIcon,
} from '../../components/svg';
import { SYSTEM_INSTABILITY } from '../../constants/Messages';
import * as Theme from '../../constants/Theme';
import { useQuery, useStore } from '../../hooks';
import { compressImage } from '../../utils';
import './styles.scss';

const orderByMenuOptions = ['mais recentes', 'mais antigas'];

const tabs = ['Minhas fotos', 'Todas as fotos'];

const ShopperHomeScreen = () => {
  const store = useStore();
  const history = useHistory();
  const query = useQuery();

  const [mounted, setMounted] = useState(false);
  const [requesting, setRequesting] = useState(true);
  const [searching, setSearching] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [name, setName] = useState('');
  const [location, setLocation] = useState('');
  const [date, setDate] = useState(null);
  const [page, setPage] = useState(1);
  const [orderByMenu, setOrderByMenu] = useState(null);
  const [orderBy, setOrderBy] = useState(0);
  const [selfieModalIsOpen, setSelfieModalIsOpen] = useState(false);
  const [selfie, setSelfie] = useState(null);
  const [selfiePreview, setSelfiePreview] = useState(null);
  const [showSelfieWarning, setShowSelfieWarning] = useState();

  const handleSelfieChange = async (files) => {
    if (files?.length) {
      const compressedImage = await compressImage(files[0]);

      if (compressedImage)
        setSelfie(
          new File([compressedImage], compressedImage.name, {
            type: compressedImage.type,
          }),
        );
    }
  };
  const user = store.UserStore.profile;
  const photos = store.PhotoStore.list;
  const meta = store.PhotoStore.meta;

  const handleOrderByMenuClick = (event) => {
    setOrderByMenu(event.currentTarget);
  };

  const handleCloseOrderByMenu = () => {
    setOrderByMenu(null);
  };

  const getPhotos = async () => {
    try {
      setRequesting(true);

      const response = await flowResult(
        store.PhotoStore.getUserPhotoList({
          user: activeTab,
          name,
          city_id: location,
          date,
          order_by: orderBy,
          page,
        }),
      );

      if (response.error) toast.error(SYSTEM_INSTABILITY);
    } catch (error) {
      console.log(error);
    } finally {
      setRequesting(false);
    }
  };

  const search = async () => {
    await getPhotos();
    setSearching(true);
  };

  const editSelfie = async () => {
    try {
      setSelfieModalIsOpen(false);

      setRequesting(true);

      const response = await flowResult(store.UserStore.editSelfie({ selfie }));

      if (response.error) {
        toast.error(SYSTEM_INSTABILITY);
        return;
      }

      await flowResult(store.UserStore.getUser());
      await getPhotos();
    } catch (error) {
      console.log(error);
    } finally {
      setRequesting(false);
    }
  };

  const renderPhotosLength = () => {
    if (requesting) return '';

    let text = 'Nenhuma disponível na Rokkos';

    if (meta?.total === 1) {
      text = '1 disponível na Rokkos';
    } else if (meta?.total > 1) {
      text = `${meta?.total} disponíveis na Rokkos`;
    }

    return text;
  };

  const renderPhotosGrid = () => {
    if (requesting) {
      return <ListLoader variant='grid' length={16} interval={300} />;
    }

    if (mounted && !requesting && !photos?.length) {
      if (searching) {
        return (
          <EmptyState
            icon={<EmptyStateSearchIcon />}
            title='Desculpe, não achamos o que você estava procurando.'
            description='Que tal fazer uma nova busca?'
          />
        );
      }

      switch (activeTab) {
        case 1: {
          return (
            <EmptyState
              icon={<EmptyStatePhotoSearchIcon />}
              title='A Rokkos não possui nenhuma foto'
              description='Mas em breve terá. Fique de olho e confira.'
            />
          );
        }

        default: {
          return (
            <>
              <EmptyState
                icon={<EmptyStatePhotoSearchIcon />}
                title={
                  user?.avatar_url?.length
                    ? 'Ainda não encontramos fotos em que você foi reconhecido.'
                    : 'Ainda não identificamos sua foto.'
                }
                description={
                  user?.avatar_url?.length
                    ? 'Confira todas as fotos da Rokkos!'
                    : 'Cadastre sua selfie na Rokkos e desbloqueie fotos em que você foi reconhecido.'
                }
                button={
                  <Button
                    variant='contained'
                    startIcon={user?.avatar_url?.length ? null : <CameraAddIcon color='#000' />}
                    onClick={() => {
                      user?.avatar_url?.length
                        ? handleTabChange(1)
                        : setSelfieModalIsOpen(true);
                    }}
                  >
                    {user?.avatar_url?.length
                      ? 'Conferir todas as fotos'
                      : 'Cadastrar minha foto'}
                  </Button>
                }
              />
              <FileUploaderModal
                modalProps={{
                  open: selfieModalIsOpen,
                  btCancelText: 'Pular etapa',
                  btCancelVariant: 'outlined',
                  showBtCancel: false,
                  showBtClose: true,
                  btConfirmText: 'Enviar foto',
                  onCancel: () => {
                    // setSelfiePreview(null);
                    // setSelfie(null);
                    setSelfieModalIsOpen(false);
                  },
                  onConfirm: editSelfie,
                  btConfirmVariant: 'contained',
                }}
                title='Tire uma selfie ou busque uma foto'
                description='Através de reconhecimento facial acharemos suas fotos na Rokkos.'
                preview={selfiePreview}
                onChange={handleSelfieChange}
              />
            </>
          );
        }
      }
    }

    return <PhotoGrid photos={photos} variant='shopper' />;
  };

  const handleTabChange = (tab) => {
    setPage(1);
    setActiveTab(tab);
  };

  const init = async () => {
    if (query.get('redirect')) {
      history.push(query.get('redirect'));
      return;
    }

    await getPhotos();
    setMounted(true);
  };

  useEffect(() => {
    if (selfie) {
      if (selfie instanceof File) {
        const newSelfiePreview = URL.createObjectURL(selfie);
        setSelfiePreview(newSelfiePreview);
        return () => {
          URL.revokeObjectURL(newSelfiePreview);
        };
      } else if (typeof selfie === 'string') {
        setSelfiePreview(selfie);
      }
    }
  }, [selfie]);

  useEffect(() => {
    if (user?.avatar_url?.length) return;

    setShowSelfieWarning(true);

    setTimeout(() => {
      setShowSelfieWarning(false);
    }, 10000);
  }, [user.avatar_url]);

  useEffect(() => {
    if (mounted) getPhotos();
  }, [activeTab, orderBy, page]);

  useEffect(() => {
    if (!mounted) return;

    if (window.innerWidth > 1024) {
      window.scrollTo(0, 104);
      return;
    }

    window.scrollTo(0, 352);
  }, [page]);

  useEffect(() => {
    if (!mounted) init();
  }, []);

  return (
    <Screen className='shopper-home' container={false}>
      <div className='shopper-home__hero-wrapper'>
        <div className={`selfie-warning${showSelfieWarning ? ' selfie-warning--visible' : ''}`}>
          <Container maxWidth={Theme.containerMaxWidth}>
            <div className='selfie-warning__text' onClick={() => setSelfieModalIsOpen(true)}>
              Cadastre sua selfie para que possamos localizar suas fotos!
            </div>
            <IconButton
              className='selfie-warning__bt-close'
              onClick={() => setShowSelfieWarning(false)}
            >
              <CloseIcon />
            </IconButton>
          </Container>
        </div>

        <Hero
          requesting={requesting}
          onChangeText={setName}
          onChangeLocation={setLocation}
          onChangeDate={setDate}
          onSubmitSearch={search}
          variant='shopper'
        />
      </div>

      <Container maxWidth={Theme.containerMaxWidth}>
        <div className='shopper-home__tabs'>
          <Tabs
            onChange={handleTabChange}
            tabs={tabs.map((label, index) => ({
              label,
              disabled: activeTab !== index && requesting,
            }))}
            value={activeTab}
          />
        </div>

        <div className='shopper-home__actions'>
          <div className='shopper-home__filter'>
            <div className='shopper-home__filter__length'>
              <Text variant='h6' color={Theme.light38}>
                {renderPhotosLength()}
              </Text>
            </div>
            <div className='shopper-home__filter__item'>
              <div className='shopper-home__filter__item__label'>
                <Text variant='h6' color={Theme.primary}>
                  Ordenar por:
                </Text>
              </div>
              <div
                className='shopper-home__filter__item__value'
                onClick={handleOrderByMenuClick}
              >
                <Text variant='body-2' color={Theme.light87}>
                  {orderByMenuOptions[orderBy]}
                </Text>
                <DropdownIcon />
              </div>
              <Menu
                anchorEl={orderByMenu}
                open={Boolean(orderByMenu)}
                onClose={handleCloseOrderByMenu}
                getContentAnchorEl={null}
              >
                {orderByMenuOptions.map((option, index) => (
                  <MenuItem
                    key={option}
                    disabled={requesting}
                    onClick={() => {
                      handleCloseOrderByMenu();
                      setOrderBy(index);
                    }}
                  >
                    {option}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </div>
        </div>

        <div className='shopper-home__event-list'>
          {renderPhotosGrid()}
          {mounted && photos?.length && meta?.last_page > 1 ? (
            <Pagination
              page={page}
              count={meta.last_page}
              color='primary'
              onChange={(_, page) => setPage(page)}
              disabled={requesting}
            />
          ) : null}
        </div>
      </Container>
    </Screen>
  );
};

export default observer(ShopperHomeScreen);
