import { Container } from '@material-ui/core';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { toast } from 'react-toastify';

import { Avatar, Button, Text } from '..';
import * as Theme from '../../constants/Theme';
import { useStore } from '../../hooks';
import {
  CalendarIcon,
  GroupAddIcon,
  LinkIcon,
  LockIcon,
  MoneyIcon,
  PencilIcon,
  PersonIcon,
  PhotoPackageIcon,
  PinIcon,
  UnlockIcon,
} from '../svg';
import './styles.scss';

const EventHeader = (props) => {
  const { variant, requesting, onClickEditEvent } = props;
  const { EventStore } = useStore();

  const {
    name,
    date,
    city,
    collaborative,
    public_event,
    owner,
    access_code,
    user,
    photo_price,
    packages,
  } = EventStore.detail;

  const eventHeaderClassNames = () => {
    let classNames = 'event-header surface-7';
    if (variant) classNames += ` event-header--${variant}`;
    return classNames;
  };

  return (
    <div className={eventHeaderClassNames()}>
      <Container maxWidth={Theme.containerMaxWidth}>
        {onClickEditEvent && variant === 'photographer' ? (
          <div className='event-header__actions'>
            {user?.manager ? (
              <Button
                startIcon={<PencilIcon color={requesting ? Theme.light38 : Theme.primary} />}
                onClick={onClickEditEvent}
                disabled={requesting}
              >
                Editar Evento
              </Button>
            ) : null}

            {!public_event && access_code?.length ? (
              <div
                className='event-header__access-code'
                title='Copie e envie esse código para seus clientes acessarem o evento.'
              >
                <Text variant='subtitle-1'>Código do evento</Text>
                <div className='event-header__access-code__code'>
                  <Text variant='h5' color={Theme.primary}>
                    {access_code}
                  </Text>
                  <Button
                    onClick={() => {
                      navigator.clipboard.writeText(access_code);
                      toast.success('Código do evento copiado!');
                    }}
                  >
                    <LinkIcon color={Theme.primary} />
                  </Button>
                </div>
              </div>
            ) : null}
          </div>
        ) : null}

        <div className='event-header__name'>
          <Text variant='h5' responsive className='event-header__title'>
            {name ? name : ''}
          </Text>
        </div>

        <div className='event-header__content'>
          <div className='event-header__content__item'>
            <CalendarIcon />
            <Text variant='h6' color={Theme.light60} className='event-header__title'>
              {date}
            </Text>
          </div>
          <div className='event-header__content__item'>
            <PinIcon />
            <Text variant='h6' color={Theme.light60} className='event-header__title'>
              {city ? `${city.name} - ${city.state}` : ''}
            </Text>
          </div>
          <div className='event-header__content__item'>
            {public_event ? <UnlockIcon /> : <LockIcon />}
            <Text variant='h6' color={Theme.light60}>
              {`${public_event ? 'Público' : 'Privado'}`}
            </Text>
          </div>
          <div className='event-header__content__item'>
            {collaborative ? <GroupAddIcon /> : <PersonIcon />}
            <Text variant='h6' color={Theme.light60}>
              {`${collaborative ? 'Colaborativo' : 'Não colaborativo'}`}
            </Text>
          </div>
          <div className='event-header__content__item'>
            <Avatar size='small' image={owner?.avatar_url ? owner.avatar_url : ''} />
            <Text variant='h6' color={Theme.light60} className='event-header__title'>
              {owner?.name ? `Criado por ‘${owner.name}’` : ''}
            </Text>
          </div>
        </div>
        <div className='event-header__content packages'>
          {!!photo_price && (
            <div className='event-header__content__item'>
              <MoneyIcon />
              <Text variant='h6' color={Theme.light60} className='event-header__title'>
                Valor unitário:{' '}
                <NumberFormat
                  displayType='text'
                  prefix='R$ '
                  thousandSeparator=' '
                  decimalSeparator=','
                  isNumericString
                  decimalScale={2}
                  fixedDecimalScale
                  value={photo_price}
                />
              </Text>
            </div>
          )}

          {!!packages?.length && (
            <>
              {!!packages[0]?.price?.length && !!packages[0]?.quantity && packages[0]?.enabled && (
                <div className='event-header__content__item'>
                  <PhotoPackageIcon />
                  <Text variant='h6' color={Theme.light60} className='event-header__title'>
                    1:{' '}
                    <NumberFormat
                      displayType='text'
                      prefix='R$ '
                      thousandSeparator=' '
                      decimalSeparator=','
                      isNumericString
                      decimalScale={2}
                      fixedDecimalScale
                      value={packages[0].price}
                    />{' '}
                    a partir de {packages[0].quantity} fotos
                  </Text>
                </div>
              )}
              {!!packages[1]?.price?.length && !!packages[1]?.quantity && packages[1]?.enabled && (
                <div className='event-header__content__item'>
                  <PhotoPackageIcon />
                  <Text variant='h6' color={Theme.light60} className='event-header__title'>
                    2:{' '}
                    <NumberFormat
                      displayType='text'
                      prefix='R$ '
                      thousandSeparator=' '
                      decimalSeparator=','
                      isNumericString
                      decimalScale={2}
                      fixedDecimalScale
                      value={packages[1]?.price}
                    />{' '}
                    a partir de {packages[1].quantity} fotos
                  </Text>
                </div>
              )}
            </>
          )}
        </div>
      </Container>
    </div>
  );
};

EventHeader.propTypes = {
  variant: PropTypes.oneOf(['photographer', 'shopper']),
  requesting: PropTypes.bool,
  onClickEditEvent: PropTypes.func,
};

EventHeader.defaultProps = {
  variant: 'photographer',
  requesting: false,
  onClickEditEvent: null,
};

export default EventHeader;
