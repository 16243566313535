import { SwipeableDrawer } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Avatar, Button, Menu } from '..';
import * as Theme from '../../constants/Theme';
import { MenuIcon } from '../svg';
import './styles.scss';

const Drawer = (props) => {
  const { user, publicRoutes } = props;
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();

  const open = () => {
    setIsOpen(true);
  };

  const close = () => {
    setIsOpen(false);
  };

  return (
    <div className='drawer'>
      <Button onClick={open} className='drawer__button'>
        <MenuIcon color={Theme.light87} />
      </Button>
      <SwipeableDrawer anchor={'left'} open={isOpen} onClose={close} onOpen={open}>
        <div className='drawer__content'>
          {user && (
            <div
              className='drawer__user'
              onClick={() => {
                history.push('/profile');
                close();
              }}
            >
              <Avatar image={user?.avatar_url} name={user?.name} />
            </div>
          )}
          <div className='drawer__menu'>
            <Menu vertical drawer={{ close }} user={user} publicRoutes={publicRoutes} />
          </div>
        </div>
      </SwipeableDrawer>
    </div>
  );
};

Drawer.propTypes = {
  user: PropTypes.shape({
    type: PropTypes.number,
    name: PropTypes.string,
    avatar_url: PropTypes.string,
  }),
  publicRoutes: PropTypes.bool,
};

Drawer.defaultProps = {
  user: null,
  publicRoutes: false,
};

export default Drawer;
