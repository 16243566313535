import PropTypes from 'prop-types';
import { Fade } from 'react-reveal';

import './styles.scss';

const Banner = ({ src }) => {
  if (!src?.length) return null;

  return (
    <div className='banner'>
      <Fade distance='10px'>
        <img src={src} />
      </Fade>
    </div>
  );
};

Banner.propTypes = {
  src: PropTypes.string.isRequired,
};

export default Banner;
