import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useState } from 'react';
import NumberFormat from 'react-number-format';

import { PhotoModal, Text } from '..';
import './styles.scss';

const OrderListItemPhoto = (props) => {
  const { photo } = props;
  const [photoModalIsOpen, setPhotoModalIsOpen] = useState(false);

  return (
    <div className='orders__list__item__thumbs__photo' key={photo.id}>
      <img
        src={photo.thumbnail_url}
        onClick={() => setPhotoModalIsOpen(true)}
        alt={photo.original_filename ? photo.original_filename : ''}
      />
      <span className='original_filename'>{photo.original_filename}</span>
      <PhotoModal
        modalProps={{
          open: photoModalIsOpen,
          onCancel: () => {
            setPhotoModalIsOpen(false);
          },
          showBtCancel: false,
          showBtConfirm: false,
          showBtClose: true,
        }}
        photo={photo}
        variant='photographer'
      />
    </div>
  );
};

const OrderListItem = (props) => {
  const {
    id,
    created_at,
    amount_total,
    amount_master,
    amount_sub,
    amount_gateway_cost,
    photos,
  } = props;

  return (
    <tr className='orders__list__item' key={id}>
      <td className='orders__list__item__order'>
        <Text variant='h6'>{id}</Text>
      </td>
      <td className='orders__list__item__date'>
        <Text variant='h6'>{created_at}</Text>
      </td>
      <td className='orders__list__item__count'>
        <Text variant='h6'>{`${photos.length} fotos`}</Text>
      </td>
      <td className='orders__list__item__thumbs'>
        {photos.map((photo) => (
          <OrderListItemPhoto key={photo.id} photo={photo} />
        ))}
      </td>
      <td className='orders__list__item__total'>
        <Text variant='h6'>
          <NumberFormat
            displayType='text'
            prefix='R$ '
            thousandSeparator=' '
            decimalSeparator=','
            isNumericString
            decimalScale={2}
            fixedDecimalScale
            value={amount_sub || 0}
          />
        </Text>
      </td>
      <td className='orders__list__item__total'>
        <Text variant='h6'>
          <NumberFormat
            displayType='text'
            prefix='R$ '
            thousandSeparator=' '
            decimalSeparator=','
            isNumericString
            decimalScale={2}
            fixedDecimalScale
            value={amount_master || 0}
          />
        </Text>
      </td>
      <td className='orders__list__item__total'>
        <Text variant='h6'>
          <NumberFormat
            displayType='text'
            prefix='R$ '
            thousandSeparator=' '
            decimalSeparator=','
            isNumericString
            decimalScale={2}
            fixedDecimalScale
            value={amount_gateway_cost || 0}
          />
        </Text>
      </td>
      <td className='orders__list__item__total'>
        <Text variant='h6'>
          <NumberFormat
            displayType='text'
            prefix='R$ '
            thousandSeparator=' '
            decimalSeparator=','
            isNumericString
            decimalScale={2}
            fixedDecimalScale
            value={amount_total || 0}
          />
        </Text>
      </td>
    </tr>
  );
};

OrderListItemPhoto.propTypes = {
  photo: PropTypes.shape({
    id: PropTypes.string,
    preview_url: PropTypes.string,
    thumbnail_url: PropTypes.string,
    original_filename: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    price: PropTypes.number,
  }).isRequired,
};

OrderListItem.propTypes = {
  id: PropTypes.number.isRequired,
  created_at: PropTypes.string.isRequired,
  amount_total: PropTypes.number.isRequired,
  amount_master: PropTypes.number.isRequired,
  amount_sub: PropTypes.number.isRequired,
  amount_gateway_cost: PropTypes.number.isRequired,
  photos: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      preview_url: PropTypes.string,
      thumbnail_url: PropTypes.string,
      original_filename: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
      price: PropTypes.number,
    }),
  ).isRequired,
};

export default observer(OrderListItem);
