import { observer } from 'mobx-react';
import { useState } from 'react';

import { Screen, Tabs, Text } from '../../components';
import * as Theme from '../../constants/Theme';
import { useStore } from '../../hooks';
import PrivateProfileForm from './PrivateProfileForm';
import PublicProfileForm from './PublicProfileForm';
import './styles.scss';

const tabs = ['Dados Cadastrais', 'Minha Página'];

const ProfileScreen = () => {
  const store = useStore();
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const renderTabsContent = () => {
    switch (activeTab) {
      case 1: {
        return <PublicProfileForm />;
      }

      default: {
        return <PrivateProfileForm />;
      }
    }
  };

  return (
    <Screen className='profile'>
      <div className='profile__header'>
        <Text variant='h5' responsive color={Theme.primary}>
          Meu Perfil
        </Text>
      </div>
      {store.UserStore.profile?.type === 1 && (
        <div className='profile__tabs'>
          <Tabs onChange={handleTabChange} tabs={tabs.map((label) => ({ label }))} />
        </div>
      )}
      {renderTabsContent()}
    </Screen>
  );
};

export default observer(ProfileScreen);
