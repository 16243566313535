import { makeAutoObservable } from 'mobx';

import baseAPI from '../../services/baseAPI';
import { compressImage, timestampDateTime } from '../../utils';

export default class UploadStore {
  rootStore;
  queue = [];
  uploading = false;
  queueIsOpen = false;
  queueIsMaximized = false;
  queueIsVisible = false;
  queueIsCancelled = false;
  compressImages = false;
  active_status_filter = '';

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this, { rootStore: false });

    if (localStorage.getItem('compressImages')) {
      this.compressImages = JSON.parse(localStorage.getItem('compressImages'));
    }
  }

  get queueProgress() {
    return parseInt(
      (this.queue.filter(({ status }) => !['idle', 'requesting'].includes(status)).length /
        this.queue.length) *
        100,
    );
  }

  get queueIsCompleted() {
    return !!this.queue.filter(({ status }) => !['idle', 'requesting'].includes(status)).length;
  }

  get queuePendingFilesCount() {
    return this.queue.filter(({ status }) => ['idle', 'requesting'].includes(status)).length;
  }

  get queueRetryFilesCount() {
    return this.queue.filter(({ status }) =>
      ['cancelled', 'error', 'duplicated'].includes(status),
    ).length;
  }

  get filterQueueByStatus() {
    if (this.active_status_filter == 'todas') {
      return this.queue;
    }
    return this.queue.filter(({ status }) => status == this.active_status_filter);
  }

  toggleImageCompression = () => {
    this.compressImages = !this.compressImages;

    if (!this.compressImages) {
      localStorage.setItem('compressImages', false);
      return;
    }

    localStorage.setItem('compressImages', true);
  };

  toggleQueueMinimized = () => {
    this.queueIsOpen = !this.queueIsOpen;

    if (!this.queueIsOpen) {
      this.queueIsMaximized = false;
    }
  };

  toggleQueueMaximized = () => {
    this.queueIsMaximized = !this.queueIsMaximized;

    if (!this.queueIsOpen) {
      this.queueIsOpen = true;
    }
  };

  resetQueue = () => {
    this.queue = [];
    this.uploading = false;
    this.queueIsCancelled = false;
  };

  hideQueue = () => {
    this.queueIsVisible = false;
  };

  closeQueue = () => {
    this.queueIsOpen = false;
    this.queueIsMaximized = false;

    setTimeout(this.hideQueue, 800);
    setTimeout(this.resetQueue, 1200);
  };

  removeItemFromQueue = (id) => {
    this.setQueueItemStatus(id, 'cancelled');
  };

  setQueueItemStatus = (id, status = 'idle') => {
    try {
      this.queue = this.queue.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            status,
          };
        }

        return item;
      });
    } catch (error) {
      return {
        error: {
          status: 400,
        },
      };
    }
  };

  cancelQueue = () => {
    try {
      this.queueIsCancelled = true;
      this.uploading = false;

      this.queue = this.queue.map((item) => {
        if (item.status === 'idle') {
          return {
            ...item,
            status: 'cancelled',
          };
        }

        return item;
      });

      return true;
    } catch (error) {
      return {
        error: {
          status: 400,
        },
      };
    }
  };

  feedQueue = (payload = {}) => {
    try {
      const { newFiles = [], eventId } = payload;
      // const acceptedImageTypes = ["image/jpeg", "image/png", "image/jpg"];
      const acceptedImageTypes = ['image/jpeg', 'image/jpg'];

      let index = this.queue.length;

      this.queueIsVisible = true;
      this.queueIsOpen = true;
      this.queueIsCancelled = false;

      for (const file of newFiles) {
        if (acceptedImageTypes.includes(file.type)) {
          this.queue = [
            ...this.queue,
            {
              id: `${index}${new Date().getTime()}`,
              eventId,
              file,
              status: 'idle', // "idle", "success", "cancelled", "error", "duplicated"
              process_timestamp_frontend_queue_assigned: timestampDateTime(),
              process_timestamp_frontend_optimization_started: false,
              process_timestamp_frontend_optimization_ended: false,
              process_timestamp_frontend_submitted: false,
            },
          ];

          index++;
        }
      }

      if (!this.uploading) this.startQueue();

      return true;
    } catch (error) {
      return {
        error: {
          status: 400,
        },
      };
    }
  };

  upload = async (payload = {}) => {
    try {
      const {
        id,
        file,
        eventId,
        photo_frontend_optimized,
        photo_size_original,
        photo_size_frontend_optimized,
        process_timestamp_frontend_queue_assigned,
        process_timestamp_frontend_optimization_started,
        process_timestamp_frontend_optimization_ended,
      } = payload;

      let process_timestamp_frontend_submitted = timestampDateTime();

      const formData = new FormData();

      if (!file) {
        return {
          error: {
            status: 400,
          },
        };
      }

      formData.append('photo', file);
      formData.append('filename', file.name);
      formData.append('photo_frontend_optimized', photo_frontend_optimized);
      formData.append('photo_size_original', photo_size_original);
      formData.append('photo_size_frontend_optimized', photo_size_frontend_optimized);

      formData.append(
        'process_timestamp_frontend_queue_assigned',
        process_timestamp_frontend_queue_assigned,
      );
      formData.append(
        'process_timestamp_frontend_optimization_started',
        process_timestamp_frontend_optimization_started,
      );
      formData.append(
        'process_timestamp_frontend_optimization_ended',
        process_timestamp_frontend_optimization_ended,
      );
      formData.append(
        'process_timestamp_frontend_submitted',
        process_timestamp_frontend_submitted,
      );

      const response = await baseAPI.post(`/events/${eventId}/photos`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const { status, data } = response;

      if (status !== 201 || !data?.id) {
        return {
          id,
          error: {
            status,
          },
        };
      }

      return { id };
    } catch (error) {
      console.warn(error);

      return {
        id: payload.id,
        error: {
          status: error?.response?.status || 400,
        },
      };
    }
  };

  startQueue = async () => {
    try {
      this.uploading = true;

      const eventDetail = this.rootStore.EventStore.detail;

      const refreshCurrentEvent = async () => {
        await this.rootStore.AlbumStore.getList({
          event_id: eventDetail.id,
          page: 1,
        });

        const albumList = this.rootStore.AlbumStore.list;

        if (!eventDetail.collaborative && albumList?.length) {
          this.rootStore.PhotoStore.getList({
            event_id: eventDetail.id,
            album_id: albumList[0].id,
            page: 1,
          });
        }
      };

      let currentItems = [];
      let refreshCurrentEventCounter = 1;

      for (let i = 0; i < this.queue.length; i++) {
        if (!this.queueIsCancelled && this.rootStore.AuthStore.status === 'authenticated') {
          if (this.queue[i].status === 'idle' && currentItems.length < 3) {
            this.setQueueItemStatus(this.queue[i].id, 'requesting');

            this.queue[i].process_timestamp_frontend_optimization_started = timestampDateTime();

            let file = this.queue[i].file;
            let photo_size_original = this.queue[i].file.size;
            let photo_size_frontend_optimized = false;

            let photo_frontend_optimized = this.compressImages;

            if (this.compressImages) {
              file = await compressImage(this.queue[i].file);
            }

            photo_size_frontend_optimized = file.size;

            this.queue[i].process_timestamp_frontend_optimization_ended = timestampDateTime();

            currentItems.push(
              this.upload({
                ...this.queue[i],
                file,
                photo_frontend_optimized,
                photo_size_original,
                photo_size_frontend_optimized,
              }),
            );
          }

          if (currentItems.length >= 3 || i > this.queue.length - 2) {
            const response = await Promise.all(currentItems);

            response.forEach(({ id, error }) => {
              error
                ? error.status == 409
                  ? this.setQueueItemStatus(id, 'duplicated')
                  : this.setQueueItemStatus(id, 'error')
                : this.setQueueItemStatus(id, 'success');
            });

            currentItems = [];
          }

          if (refreshCurrentEventCounter >= 15 && eventDetail?.id === this.queue[i]?.eventId) {
            refreshCurrentEvent();

            refreshCurrentEventCounter = 0;
          } else {
            refreshCurrentEventCounter++;
          }
        }
      }

      if (eventDetail && this.rootStore.AuthStore.status === 'authenticated') {
        refreshCurrentEvent();
      }

      return true;
    } catch (error) {
      console.warn(error);

      return {
        error: {
          status: 400,
        },
      };
    } finally {
      this.uploading = false;
    }
  };

  restartQueue = () => {
    try {
      this.queue = this.queue.map((item) => {
        if (['cancelled', 'error'].includes(item.status)) {
          return { ...item, status: 'idle' };
        }

        return item;
      });

      this.queueIsCancelled = false;

      if (!this.uploading) this.startQueue();

      return true;
    } catch (error) {
      console.warn(error);

      return {
        error: {
          status: 400,
        },
      };
    }
  };
}
