import { Container } from '@material-ui/core';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { Fade } from 'react-reveal';

import { Text } from '..';
import { WHATSAPP_MESSAGE } from '../../constants/Messages';
import * as Theme from '../../constants/Theme';
import { WhatsAppIcon } from '../svg';
import './styles.scss';

const Footer = (props) => {
  const { user } = props;

  if (!user) return null;

  const getUrl = (url) => {
    if (url.startsWith('http') || url.startsWith('https')) return url;
    return `https://${url}`;
  };

  const renderSocial = () => {
    if (!user?.social?.find((social) => social.url)) return null;

    return (
      <section className='footer__section social'>
        <Fade bottom distance='10px'>
          <Text className='footer__section__title' variant='h5' responsive>
            MINHAS REDES SOCIAIS
          </Text>
        </Fade>

        <div className='social-icons'>
          {user.social
            .filter((social) => social.url)
            .map(({ id, url, label, image_url }, index) => (
              <Fade bottom distance='10px' delay={(index + 1) * 100} key={id}>
                <article key={id}>
                  <a
                    href={getUrl(url)}
                    target='_blank'
                    rel='noreferrer'
                    className={label.toLowerCase()}
                  >
                    <img src={image_url} />
                  </a>
                </article>
              </Fade>
            ))}
        </div>
      </section>
    );
  };

  const renderContact = () => {
    if (
      !user.email?.length &&
      !user.phone?.length &&
      !user.whatsapp?.length &&
      !user.address?.street?.length &&
      !user.address?.number?.length &&
      !user.address?.complement?.length &&
      !user.address?.zip?.length &&
      !user.address?.city?.length &&
      !user.address?.state?.length &&
      !user.address?.country?.length
    ) {
      return null;
    }

    return (
      <Fade bottom distance='10px'>
        <section className='footer__section contact'>
          <Text className='footer__section__title' variant='h5' responsive>
            CONTATOS
          </Text>

          {!!user.email?.length && (
            <article>
              <Text className='footer__section__subtitle' variant='h6' responsive>
                E-mail
              </Text>
              <a href={`mailto:${user.email}`} target='_blank' rel='noreferrer'>
                <Text variant='subtitle-1'>{user.email}</Text>
              </a>
            </article>
          )}
          {(!!user.phone?.length || !!user.whatsapp?.length) && (
            <article>
              <Text className='footer__section__subtitle' variant='h6' responsive>
                Telefone
              </Text>
              {!!user.phone_prefix?.length && !!user.phone?.length && (
                <Text variant='subtitle-1'>
                  <NumberFormat
                    format='(##) #####-####'
                    isNumericString
                    value={`${user.phone_prefix}${user.phone}`}
                    displayType='text'
                  />
                </Text>
              )}
              {!!user.whatsapp_prefix?.length && !!user.whatsapp?.length && (
                <a
                  className='whatsapp-link'
                  href={encodeURI(
                    `https://api.whatsapp.com/send?phone=55${user.whatsapp_prefix}${user.whatsapp}&text=${WHATSAPP_MESSAGE}`,
                  )}
                  target='_blank'
                  rel='noreferrer'
                >
                  <Text variant='subtitle-1'>
                    <NumberFormat
                      format='(##) #####-####'
                      isNumericString
                      value={`${user.whatsapp_prefix}${user.whatsapp}`}
                      displayType='text'
                    />
                  </Text>
                  <WhatsAppIcon />
                </a>
              )}
            </article>
          )}
          {(!!user.address?.street?.length ||
            !!user.address?.number?.length ||
            !!user.address?.complement?.length ||
            !!user.address?.zip?.length ||
            !!user.address?.city?.length ||
            !!user.address?.state?.length ||
            !!user.address?.country?.length) && (
            <article>
              <Text className='footer__section__subtitle' variant='h6' responsive>
                Endereço
              </Text>
              {!!user.address?.street?.length && !!user.address?.number && (
                <Text variant='subtitle-1'>{`Rua: ${user.address.street}, ${
                  user.address.number
                }${
                  user.address.complement?.length ? ` - ${user.address.complement}` : ''
                }`}</Text>
              )}
              {!!user.address?.zip?.length && (
                <Text variant='subtitle-1'>{`CEP: ${user.address.zip}`}</Text>
              )}
              {!!user.address?.city?.length && !!user.address?.state?.length && (
                <Text variant='subtitle-1'>{`${user.address.city} / ${user.address.state}${
                  user.address.country?.length ? ` - ${user.address.country}` : ''
                }`}</Text>
              )}
            </article>
          )}
        </section>
      </Fade>
    );
  };

  return (
    <footer className='footer'>
      <Container maxWidth={Theme.containerMaxWidth}>
        {renderSocial()}
        {renderContact()}
      </Container>
    </footer>
  );
};

Footer.propTypes = {
  user: PropTypes.shape({
    phone: PropTypes.string,
    phone_prefix: PropTypes.string,
    whatsapp: PropTypes.string,
    whatsapp_prefix: PropTypes.string,
    email: PropTypes.string,
    social: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        cover_url: PropTypes.string,
        name: PropTypes.string,
        date: PropTypes.string,
        photo_count: PropTypes.number,
      }),
    ),
    address: PropTypes.shape({
      zip: PropTypes.string,
      street: PropTypes.string,
      number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      complement: PropTypes.string,
      district: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      country: PropTypes.string,
    }),
  }).isRequired,
};

export default Footer;
