import PropTypes from 'prop-types';

import * as Theme from '../../../constants/Theme';

const ShareIcon = (props) => {
  const { color } = props;

  return (
    <svg
      width='28'
      height='30'
      viewBox='0 0 28 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M23 21.12C21.86 21.12 20.84 21.57 20.06 22.275L9.365 16.05C9.44 15.705 9.5 15.36 9.5 15C9.5 14.64 9.44 14.295 9.365 13.95L19.94 7.785C20.75 8.535 21.815 9 23 9C25.49 9 27.5 6.99 27.5 4.5C27.5 2.01 25.49 0 23 0C20.51 0 18.5 2.01 18.5 4.5C18.5 4.86 18.56 5.205 18.635 5.55L8.06 11.715C7.25 10.965 6.185 10.5 5 10.5C2.51 10.5 0.5 12.51 0.5 15C0.5 17.49 2.51 19.5 5 19.5C6.185 19.5 7.25 19.035 8.06 18.285L18.74 24.525C18.665 24.84 18.62 25.17 18.62 25.5C18.62 27.915 20.585 29.88 23 29.88C25.415 29.88 27.38 27.915 27.38 25.5C27.38 23.085 25.415 21.12 23 21.12Z'
        fill={color}
      />
    </svg>
  );
};

ShareIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

ShareIcon.defaultProps = {
  color: Theme.light60,
  size: 24,
};

export default ShareIcon;
