import {
  AlbumDetailScreen,
  CartScreen,
  ChangePasswordScreen,
  CheckoutConfirmationScreen,
  CheckoutFreeConfirmationScreen,
  CheckoutPixConfirmationScreen,
  CheckoutPagarmeConfirmationScreen,
  CheckoutScreen,
  CollectionScreen,
  ConfirmEmailScreen,
  CreateEventScreen,
  EditEventScreen,
  EventDetailScreen,
  EventNotFoundScreen,
  LoginScreen,
  NotFoundScreen,
  OrdersScreen,
  PasswordRecoveryScreen,
  PhotographerAllJobsScreen,
  PhotographerHomeScreen,
  PhotographerMyJobsScreen,
  ProfileScreen,
  PublicEventDetailScreen,
  PublicEventsScreen,
  PublicProfileScreen,
  RegisterScreen,
  ShopperAllEventsScreen,
  ShopperEventsScreen,
  ShopperHomeScreen,
  ShopperMyEventsScreen,
  UseTermsScreen,
} from '../screens';

const GlobalRoutes = [
  {
    path: '/photographer/:username',
    name: 'Perfil Público do Fotógrafo',
    component: PublicProfileScreen,
  },
  {
    path: '/use-terms',
    name: 'Termos de Uso e Responsabilidade',
    component: UseTermsScreen,
  },
  {
    path: '*',
    name: '404',
    component: NotFoundScreen,
  },
];

const PublicRoutes = [
  {
    path: '/register',
    name: 'Cadastro',
    component: RegisterScreen,
  },
  {
    path: '/register/confirmation',
    name: 'Confirmação de Email',
    component: ConfirmEmailScreen,
  },
  {
    path: '/password-recovery',
    name: 'Recuperação de Senha',
    component: PasswordRecoveryScreen,
  },
  {
    path: '/password-recovery/:token/:email',
    name: 'Redefinição de Senha',
    component: ChangePasswordScreen,
  },
  {
    path: '/event/:id',
    name: 'Detalhe de Evento',
    component: PublicEventDetailScreen,
  },
  {
    path: '/private-events/:accessCode',
    name: 'Detalhe de Evento Privado',
    component: PublicEventDetailScreen,
  },
  {
    path: '/event-not-found',
    name: 'Evento Não Econtrado',
    component: EventNotFoundScreen,
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginScreen,
  },
  {
    path: '/',
    name: 'Eventos',
    component: PublicEventsScreen,
    drawer: 1,
    menu: 1,
  },
];

const PrivateRoutes = [
  {
    path: '/private-events/:accessCode',
    name: 'Detalhe de Evento Privado',
    component: EventDetailScreen,
  },
  {
    path: '/event/:id',
    name: 'Detalhe de Evento',
    component: EventDetailScreen,
  },
  {
    path: '/event-not-found',
    name: 'Evento Não Econtrado',
    component: EventNotFoundScreen,
  },
  {
    path: '/change-password',
    name: 'Redefinição de Senha',
    component: ChangePasswordScreen,
  },
  {
    path: '/help',
    name: 'Ajuda',
    component: OrdersScreen,
    // menu: 4,
    // drawer: 4,
  },
];

const PhotographerRoutes = [
  {
    path: '/event/create',
    name: 'Criação de Evento',
    component: CreateEventScreen,
  },
  {
    path: '/event/:id/edit',
    name: 'Edição de Evento',
    component: EditEventScreen,
  },
  {
    path: '/event/:id/:albumId',
    name: 'Detalhe de Album',
    component: AlbumDetailScreen,
  },
  {
    path: '/profile',
    name: 'Meu Perfil',
    component: ProfileScreen,
    drawer: 3,
  },
  {
    path: '/orders',
    name: 'Minhas Vendas',
    component: OrdersScreen,
    drawer: 2,
    menu: 3,
  },
  {
    path: '/jobs',
    name: 'Jobs',
    component: PhotographerHomeScreen,
  },
  {
    path: '/',
    name: 'Meus Jobs',
    component: PhotographerMyJobsScreen,
  },
  {
    path: '/jobs/mine',
    name: 'Meus Jobs',
    component: PhotographerMyJobsScreen,
    drawer: 1,
    menu: 1,
  },
  {
    path: '/jobs/all',
    name: 'Todos os Jobs',
    component: PhotographerAllJobsScreen,
    drawer: 1,
    menu: 1,
  },
];

const ShopperRoutes = [
  {
    path: '/checkout/cart',
    name: 'Carrinho',
    component: CartScreen,
  },
  {
    path: '/checkout/confirmation',
    name: 'Confirmação de Compra',
    component: CheckoutConfirmationScreen,
  },
  {
    path: '/checkout/free-confirmation',
    name: 'Confirmação de Compra',
    component: CheckoutFreeConfirmationScreen,
  },
  {
    path: '/checkout/pix-confirmation',
    name: 'Confirmação de Compra PIX',
    component: CheckoutPixConfirmationScreen,
  },
  {
    path: '/checkout/pagarme-confirmation',
    name: 'Confirmação de Compra Pagarme',
    component: CheckoutPagarmeConfirmationScreen,
  },
  {
    path: '/checkout',
    name: 'Finalização de Compra',
    component: CheckoutScreen,
  },
  {
    path: '/profile',
    name: 'Meu Perfil',
    component: ProfileScreen,
    drawer: 5,
  },
  {
    path: '/events',
    name: 'Eventos',
    component: ShopperEventsScreen,
  },
  {
    path: '/events/mine',
    name: 'Meus Eventos',
    component: ShopperMyEventsScreen,
    drawer: 2,
    menu: 2,
  },
  {
    path: '/events/all',
    name: 'Todos os Eventos',
    component: ShopperAllEventsScreen,
    drawer: 3,
    menu: 3,
  },
  {
    path: '/collection',
    name: 'Minhas Compras',
    component: CollectionScreen,
    drawer: 4,
    menu: 4,
  },
  {
    path: '/',
    name: 'Fotos',
    component: ShopperHomeScreen,
    drawer: 1,
    menu: 1,
  },
];

export { GlobalRoutes, PublicRoutes, PrivateRoutes, PhotographerRoutes, ShopperRoutes };
