import { useHistory } from 'react-router-dom';

import { Button, Logo, Screen, Text } from '../../components';
import * as Theme from '../../constants/Theme';
import './styles.scss';

const UseTermsScreen = () => {
  const history = useHistory();

  return (
    <Screen className='use-terms'>
      <main>
        <div className='use-terms__logo'>
          <Logo big />
        </div>
        <div className='use-terms__title'>
          <Text variant='h6' responsive color={Theme.primary}>
            Termos de uso e responsabilidade
          </Text>
        </div>
        <div className='use-terms__text'>
          <Text variant='h6' color={Theme.light60}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Porta magna aliquet tellus
            lacinia integer nulla amet. Blandit pellentesque metus, at cursus lectus molestie in
            pharetra in. Diam auctor pulvinar ultrices et accumsan tempus. Sollicitudin facilisi
            nibh feugiat quis facilisi vel non. Felis, in metus, sollicitudin egestas leo
            ultrices. Dui scelerisque vel nulla erat nunc dui ut. Mi sed id congue nullam magna
            feugiat aenean laoreet faucibus. Id tristique massa, semper ut. Congue nisi non
            quisque commodo condimentum sit.
          </Text>
          <Text variant='h6' color={Theme.light60}>
            Aliquet cursus sit at lacus nulla eu nisl, ligula. Sed accumsan risus congue.
          </Text>
        </div>
        <div className='use-terms__actions'>
          <Button variant='contained' onClick={() => history.push('/register')}>
            Confirmar
          </Button>
        </div>
      </main>
    </Screen>
  );
};

export default UseTermsScreen;
