import _ from 'lodash';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';

import { Button } from '..';
import {
  PhotographerRoutes,
  PrivateRoutes,
  PublicRoutes,
  ShopperRoutes,
} from '../../constants/Routes';
import { useStore } from '../../hooks';
import './styles.scss';

const Menu = (props) => {
  const { user, drawer, publicRoutes } = props;
  const history = useHistory();
  const location = useLocation();
  const store = useStore();

  const handleClick = (path) => {
    history.push(path);
    if (drawer) drawer.close();
  };

  const getMenuStyle = () => ({
    flexDirection: drawer ? 'column' : 'row',
  });

  const getButtonClassName = (path) => {
    if (path === location?.pathname) return 'active';

    const _path = path.replace('/', '');

    return location?.pathname?.includes(_path) && _path.length ? 'active' : '';
  };

  const renderMenu = () => {
    let routes = [];

    if (publicRoutes) {
      routes = [...PublicRoutes];
    } else {
      routes = [...PrivateRoutes];
      switch (user?.type) {
        case 2: {
          routes = [...routes, ...ShopperRoutes];
          break;
        }

        default: {
          routes = [...routes, ...PhotographerRoutes];
          break;
        }
      }
    }

    if (drawer) routes = _.orderBy(routes, 'drawer');
    else routes = _.orderBy(routes, 'menu');

    return routes
      .filter(({ menu, drawer: showInDrawer }) => (drawer ? showInDrawer : menu))
      .map(({ path, name }) => (
        <Button
          key={path}
          className={getButtonClassName(path)}
          fullWidth={!!drawer}
          onClick={() => handleClick(path)}
          size='medium'
        >
          {name}
        </Button>
      ));
  };

  return (
    <nav className='menu' style={getMenuStyle()}>
      {renderMenu()}
      <Button
        key='website'
        fullWidth={!!drawer}
        onClick={() => window.open('https://rokkos.app/', '_blank')}
        size='medium'
      >
        Conheça a Rokkos
      </Button>
      {drawer && (
        <>
          <Button
            key='politica'
            fullWidth={!!drawer}
            onClick={() => window.open('https://rokkos.app/politica-de-privacidade/', '_blank')}
            size='medium'
          >
            Política de Privacidade
          </Button>
          <Button
            key='termos'
            fullWidth={!!drawer}
            onClick={() => window.open('https://rokkos.app/termos-de-uso-e-servico/', '_blank')}
            size='medium'
          >
            Termos de Uso
          </Button>
        </>
      )}
      {drawer && !publicRoutes && (
        <Button
          key='exit'
          fullWidth={!!drawer}
          onClick={() => store.AuthStore.unauthenticate()}
          size='medium'
        >
          Sair
        </Button>
      )}
      {!drawer && publicRoutes && (
        <Button variant='contained' onClick={() => history.push('/login')} size='small'>
          Login | Encontre suas fotos
        </Button>
      )}
    </nav>
  );
};

Menu.propTypes = {
  user: PropTypes.shape({
    type: PropTypes.number,
    name: PropTypes.string,
    image: PropTypes.string,
  }),
  drawer: PropTypes.instanceOf(Object),
  publicRoutes: PropTypes.bool,
};

Menu.defaultProps = {
  drawer: null,
  public: false,
};

export default Menu;
