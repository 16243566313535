import PropTypes from 'prop-types';

import * as Theme from '../../../constants/Theme';

const InfoIcon = (props) => {
  const { color, size } = props;

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 17H11V11H13V17ZM13 9H11V7H13V9Z'
        fill={color}
      />
    </svg>
  );
};

InfoIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

InfoIcon.defaultProps = {
  color: Theme.light60,
  size: 24,
};

export default InfoIcon;
