import PropTypes from 'prop-types';

const GoogleIcon = (props) => {
  const { size } = props;

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0)'>
        <path
          d='M4.3882 8.1375C4.77811 6.95787 5.53059 5.93151 6.5383 5.20483C7.54601 4.47815 8.75748 4.08828 9.99987 4.09083C11.4082 4.09083 12.6815 4.59083 13.6815 5.40917L16.5915 2.5C14.8182 0.954167 12.5457 0 9.99987 0C6.0582 0 2.66487 2.24833 1.0332 5.54167L4.3882 8.1375Z'
          fill='#EA4335'
        />
        <path
          d='M13.3666 15.011C12.4583 15.5968 11.3049 15.9093 9.99993 15.9093C8.76249 15.9118 7.5556 15.5251 6.55013 14.8038C5.54465 14.0825 4.79155 13.0631 4.39743 11.8901L1.03076 14.446C1.8568 16.1179 3.13515 17.5246 4.72061 18.5064C6.30608 19.4882 8.1351 20.0057 9.99993 20.0001C12.4441 20.0001 14.7791 19.131 16.5283 17.5001L13.3674 15.011H13.3666Z'
          fill='#34A853'
        />
        <path
          d='M16.5283 17.5C18.3575 15.7933 19.545 13.2533 19.545 9.99997C19.545 9.40831 19.4542 8.77247 19.3183 8.18164H10V12.0458H15.3633C15.0992 13.345 14.3883 14.3508 13.3675 15.0108L16.5283 17.5Z'
          fill='#4A90E2'
        />
        <path
          d='M4.3976 11.8898C4.19373 11.2806 4.09016 10.6423 4.09093 9.99984C4.09093 9.34817 4.1951 8.72234 4.38843 8.13734L1.03343 5.5415C0.347079 6.92708 -0.00673329 8.4536 9.70454e-05 9.99984C9.70454e-05 11.5998 0.37093 13.1082 1.03093 14.4457L4.3976 11.8898Z'
          fill='#FBBC05'
        />
      </g>
      <defs>
        <clipPath id='clip0'>
          <rect width={size} height={size} fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

GoogleIcon.propTypes = {
  size: PropTypes.number,
};

GoogleIcon.defaultProps = {
  size: 20,
};

export default GoogleIcon;
