import PropTypes from 'prop-types';

const Logo = ({ big }) =>
  big ? (
    <svg width='110' height='59' viewBox='0 0 110 59' fill='none'>
      <path
        d='M68.7425 41.4758H63.135L62.3972 42.5668L59.8975 46.2595V36.762L59.8905 36.7531H55.2705V59H59.8975V54.5504L62.3972 50.8572L67.9057 58.9987L73.5166 59L65.2007 46.711L68.7425 41.4758Z'
        fill='white'
      />
      <path
        d='M38.5074 41.4758H44.1148L44.8525 42.5668L47.3525 46.2595V36.762L47.3593 36.7531H51.9795V59H47.3525V54.5504L44.8525 50.8572L39.3452 58.9987L33.7334 59L42.0493 46.711L38.5074 41.4758Z'
        fill='white'
      />
      <path
        d='M99.9368 45.8214H107.051L110.001 41.476H99.9368C96.9591 41.476 94.5376 43.928 94.5376 46.9435C94.5376 48.0631 94.8744 49.106 95.449 49.9741L95.4201 50.0168C95.6256 50.3228 95.8616 50.6069 96.1223 50.864C96.5569 51.2907 97.0649 51.6463 97.6234 51.9053C98.3121 52.2272 99.0759 52.4082 99.8816 52.4082H99.91C100.52 52.4082 101.018 52.9136 101.018 53.5328C101.018 54.1521 100.52 54.6524 99.91 54.6524H92.2745L89.3359 59H89.8441H99.91C102.886 59 105.31 56.548 105.31 53.5328C105.31 52.2471 104.868 51.0647 104.126 50.1295C103.904 49.8478 103.679 49.5494 103.4 49.317C102.932 48.9258 102.4 48.6129 101.82 48.3963C101.241 48.1817 100.614 48.0631 99.9657 48.0631H99.9368C99.3253 48.0631 98.8286 47.5601 98.8286 46.9435C98.8286 46.3243 99.3253 45.8214 99.9368 45.8214Z'
        fill='white'
      />
      <path
        d='M12.6484 52.1326C12.8668 52.0139 13.08 51.8811 13.2873 51.7354C13.7557 51.4043 14.1709 51.0181 14.5217 50.5865C14.8828 50.143 15.17 49.6406 15.3755 49.0938C15.5854 48.5377 15.6916 47.9358 15.6916 47.3059C15.6916 46.4798 15.5462 45.7006 15.2581 44.9871C14.966 44.2679 14.5449 43.6402 14.006 43.1208C13.4736 42.6071 12.8283 42.1992 12.0855 41.9071C11.3509 41.6207 10.5308 41.4754 9.6483 41.4754H0L2.97144 45.8517H9.67037C9.92202 45.8517 10.1432 45.8931 10.3251 45.9726C10.5105 46.0547 10.6569 46.1561 10.7692 46.2782C10.8882 46.4092 10.975 46.5527 11.0328 46.7138C11.0959 46.8924 11.1282 47.0764 11.1282 47.2614C11.1282 47.6754 11.0021 47.9892 10.7308 48.2498C10.4516 48.5185 10.0905 48.649 9.62689 48.649H6.81549H6.08416H4.87051L7.68632 52.7935V52.7924L11.903 58.999H17.3863L12.6484 52.1326Z'
        fill='white'
      />
      <path
        d='M81.4391 41.476C76.6604 41.476 72.7861 45.3989 72.7861 50.2383C72.7861 55.0775 76.6604 59 81.4391 59C86.2182 59 90.0925 55.0775 90.0925 50.2383C90.0925 45.3989 86.2182 41.476 81.4391 41.476ZM81.4391 45.9466C83.7763 45.9466 85.6776 47.8717 85.6776 50.2383C85.6776 52.6049 83.7763 54.5296 81.4391 54.5296C79.1023 54.5296 77.201 52.6049 77.201 50.2383C77.201 47.8717 79.1023 45.9466 81.4391 45.9466Z'
        fill='white'
      />
      <path
        d='M25.8912 41.476C21.1123 41.476 17.2383 45.3989 17.2383 50.2383C17.2383 55.0775 21.1123 59 25.8912 59C30.6704 59 34.5444 55.0775 34.5444 50.2383C34.5444 45.3989 30.6704 41.476 25.8912 41.476ZM25.8912 45.9466C28.2283 45.9466 30.1295 47.8717 30.1295 50.2383C30.1295 52.6049 28.2283 54.5296 25.8912 54.5296C23.5542 54.5296 21.6532 52.6049 21.6532 50.2383C21.6532 47.8717 23.5542 45.9466 25.8912 45.9466Z'
        fill='white'
      />
      <path
        d='M63.6028 29.8214L63.6006 29.8198L63.6408 34.292L67.7731 32.716L67.7744 32.7166L67.7351 28.2454L63.6028 29.8214Z'
        fill='url(#paint0_linear)'
      />
      <path
        d='M63.5625 27.6103L69.8228 31.9551L72.4537 28.069L66.1931 23.7243L63.5625 27.6103Z'
        fill='url(#paint1_linear)'
      />
      <path
        d='M79.2595 0.761299L70.7479 13.3344L67.0093 14.0409L64.0346 14.6029L62.3238 17.1299L59.3848 21.4713L57.6738 23.9989L60.6488 23.4365L64.3873 22.73L63.7198 23.7161L73.3538 30.4027L73.997 29.4523L74.6837 33.1781L75.2389 36.1904L76.9499 33.6631L79.8889 29.322L81.5997 26.7949L81.0445 23.7821L80.3575 20.0565L88.8933 7.44805L86.8313 0.000431061L79.2595 0.761299ZM66.6948 23.1544L80.4615 2.81855L85.2556 2.33598L86.5631 7.05823L72.7984 27.3908L66.6948 23.1544ZM65.2981 16.568L69.0371 15.8617L66.0981 20.2031L62.3598 20.9092L65.2981 16.568ZM75.7082 26.9249L78.6468 22.5836L79.3333 26.3092L76.3945 30.6508L75.7082 26.9249Z'
        fill='url(#paint2_linear)'
      />
      <defs>
        <linearGradient
          id='paint0_linear'
          x1='63.1714'
          y1='31.7438'
          x2='68.2089'
          y2='30.8155'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#F1552C' />
          <stop offset='0.5' stopColor='#F36C42' />
          <stop offset='1' stopColor='#F6916A' />
        </linearGradient>
        <linearGradient
          id='paint1_linear'
          x1='63.7529'
          y1='28.6435'
          x2='72.2704'
          y2='27.0739'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#221F1F' />
          <stop offset='0.5' stopColor='#5C5D5F' />
          <stop offset='1' stopColor='#757779' />
        </linearGradient>
        <linearGradient
          id='paint2_linear'
          x1='66.4562'
          y1='30.0948'
          x2='87.1785'
          y2='0.241569'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#F1552C' />
          <stop offset='0.5' stopColor='#F36C42' />
          <stop offset='1' stopColor='#F6916A' />
        </linearGradient>
      </defs>
    </svg>
  ) : (
    <svg width='110' height='23' viewBox='0 0 110 23' fill='none'>
      <path
        d='M68.7425 5.47576H63.135L62.3972 6.56675L59.8975 10.2595V0.761997L59.8905 0.753057H55.2705V23H59.8975V18.5504L62.3972 14.8572L67.9057 22.9987L73.5166 23L65.2007 10.711L68.7425 5.47576Z'
        fill='white'
      />
      <path
        d='M38.5074 5.47576H44.1148L44.8525 6.56675L47.3525 10.2595V0.761997L47.3593 0.753057H51.9795V23H47.3525V18.5504L44.8525 14.8572L39.3452 22.9987L33.7334 23L42.0493 10.711L38.5074 5.47576Z'
        fill='white'
      />
      <path
        d='M99.9368 9.8214H107.051L110.001 5.47599H99.9368C96.9591 5.47599 94.5376 7.92798 94.5376 10.9435C94.5376 12.0631 94.8744 13.106 95.449 13.9741L95.4201 14.0168C95.6256 14.3228 95.8616 14.6069 96.1223 14.864C96.5569 15.2907 97.0649 15.6463 97.6234 15.9053C98.3121 16.2272 99.0759 16.4082 99.8816 16.4082H99.91C100.52 16.4082 101.018 16.9136 101.018 17.5328C101.018 18.1521 100.52 18.6524 99.91 18.6524H92.2745L89.3359 23H89.8441H99.91C102.886 23 105.31 20.548 105.31 17.5328C105.31 16.2471 104.868 15.0647 104.126 14.1295C103.904 13.8478 103.679 13.5494 103.4 13.317C102.932 12.9258 102.4 12.6129 101.82 12.3963C101.241 12.1817 100.614 12.0631 99.9657 12.0631H99.9368C99.3253 12.0631 98.8286 11.5601 98.8286 10.9435C98.8286 10.3243 99.3253 9.8214 99.9368 9.8214Z'
        fill='white'
      />
      <path
        d='M12.6484 16.1326C12.8668 16.0139 13.08 15.8811 13.2873 15.7354C13.7557 15.4043 14.1709 15.0181 14.5217 14.5865C14.8828 14.143 15.17 13.6406 15.3755 13.0938C15.5854 12.5377 15.6916 11.9358 15.6916 11.3059C15.6916 10.4798 15.5462 9.70061 15.2581 8.98714C14.966 8.26786 14.5449 7.64022 14.006 7.12076C13.4736 6.60712 12.8283 6.1992 12.0855 5.90706C11.3509 5.62073 10.5308 5.47545 9.6483 5.47545H0L2.97144 9.8517H9.67037C9.92202 9.8517 10.1432 9.89305 10.3251 9.97263C10.5105 10.0547 10.6569 10.1561 10.7692 10.2782C10.8882 10.4092 10.975 10.5527 11.0328 10.7138C11.0959 10.8924 11.1282 11.0764 11.1282 11.2614C11.1282 11.6754 11.0021 11.9892 10.7308 12.2498C10.4516 12.5185 10.0905 12.649 9.62689 12.649H6.81549H6.08416H4.87051L7.68632 16.7935V16.7924L11.903 22.999H17.3863L12.6484 16.1326Z'
        fill='white'
      />
      <path
        d='M81.4391 5.47599C76.6604 5.47599 72.7861 9.39895 72.7861 14.2383C72.7861 19.0775 76.6604 23 81.4391 23C86.2182 23 90.0925 19.0775 90.0925 14.2383C90.0925 9.39895 86.2182 5.47599 81.4391 5.47599ZM81.4391 9.94657C83.7763 9.94657 85.6776 11.8717 85.6776 14.2383C85.6776 16.6049 83.7763 18.5296 81.4391 18.5296C79.1023 18.5296 77.201 16.6049 77.201 14.2383C77.201 11.8717 79.1023 9.94657 81.4391 9.94657Z'
        fill='white'
      />
      <path
        d='M25.8912 5.47599C21.1123 5.47599 17.2383 9.39895 17.2383 14.2383C17.2383 19.0775 21.1123 23 25.8912 23C30.6704 23 34.5444 19.0775 34.5444 14.2383C34.5444 9.39895 30.6704 5.47599 25.8912 5.47599ZM25.8912 9.94657C28.2283 9.94657 30.1295 11.8717 30.1295 14.2383C30.1295 16.6049 28.2283 18.5296 25.8912 18.5296C23.5542 18.5296 21.6532 16.6049 21.6532 14.2383C21.6532 11.8717 23.5542 9.94657 25.8912 9.94657Z'
        fill='white'
      />
    </svg>
  );
Logo.defautProps = {
  big: false,
  alt: 'Rokkos logo',
};

Logo.propTypes = {
  big: PropTypes.bool,
  alt: PropTypes.string,
};

export default Logo;
