import { Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Fade } from 'react-reveal';
import { toast } from 'react-toastify';

import { Button, Text } from '..';
import { COPY_URL_SUCCESS } from '../../constants/Messages';
import * as Theme from '../../constants/Theme';
import { useStore } from '../../hooks';
import { ShareIcon, VerifiedIcon } from '../svg';
import './styles.scss';

const PublicProfileAbout = (props) => {
  const store = useStore();

  const { user } = props;

  if (!user) return null;

  const copyUrl = () => {
    navigator.clipboard.writeText(window.location.href);
    toast.success(COPY_URL_SUCCESS);
  };

  const renderAvatar = () => {
    return (
      <Fade distance='10px'>
        <div className='public-profile__about__avatar'>
          {user.avatar_url?.length && <img src={user.avatar_url} />}
          {store.UserStore.profile?.id === user?.user_id && (
            <Button variant='contained' onClick={copyUrl}>
              <ShareIcon color='#000' />
            </Button>
          )}
        </div>
      </Fade>
    );
  };

  const renderName = () => {
    return (
      <Fade bottom distance='10px'>
        <div className='public-profile__about__name'>
          <Text variant='h5' responsive color={Theme.primary}>
            {user.name}
          </Text>
          {(store.UserStore.profile?.id === user?.user_id || user.verified) && (
            <Tooltip title={user.verified ? 'Perfil verificado' : 'Perfil não verificado'}>
              <div className='public-profile__about__verified'>
                <VerifiedIcon color={user.verified ? '#2962FF' : '#121212'} />
              </div>
            </Tooltip>
          )}
          {store.UserStore.profile?.id === user?.user_id && (
            <Button variant='contained' onClick={copyUrl}>
              <ShareIcon color='#000' />
            </Button>
          )}
        </div>
      </Fade>
    );
  };

  const renderBadges = () => {
    if (!user?.badges?.length) return null;

    return (
      <div className='public-profile__about__badges'>
        {user.badges
          .filter(({ enabled }) => store.UserStore.profile?.id === user?.user_id || enabled)
          .map(({ id, image_url, value, how_to_obtain, enabled }, index) => (
            <Fade bottom distance='10px' delay={(index + 1) * 100} key={id}>
              <Tooltip title={how_to_obtain}>
                <div
                  key={id}
                  className='badge'
                  style={enabled ? {} : { filter: 'grayScale(1) opacity(0.7)' }}
                >
                  <div className='badge__image'>
                    <img src={image_url} />
                    {!!value && (
                      <div className='badge__value'>
                        <Text variant='subtitle-1'>{value}</Text>
                      </div>
                    )}
                  </div>
                </div>
              </Tooltip>
            </Fade>
          ))}
      </div>
    );
  };

  const renderBio = () => {
    if (!user.bio?.length) return null;

    return (
      <Fade bottom distance='10px' delay={(user?.badges?.length + 1) * 100 || 0}>
        <div className='public-profile__about__bio'>
          <Text variant='h6'>{user.bio}</Text>
        </div>
      </Fade>
    );
  };

  return (
    <section className='public-profile__about'>
      {renderAvatar()}

      <div className='public-profile__about__text'>
        {renderName()}

        {renderBadges()}

        {renderBio()}
      </div>
    </section>
  );
};

PublicProfileAbout.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    user_id: PropTypes.number,
    name: PropTypes.string,
    avatar_url: PropTypes.string,
    bio: PropTypes.string,
    verified: PropTypes.bool,
    badges: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        cover_url: PropTypes.string,
        name: PropTypes.string,
        date: PropTypes.string,
        photo_count: PropTypes.number,
      }),
    ),
  }),
};

export default PublicProfileAbout;
