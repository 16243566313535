import { observer } from 'mobx-react';
import { useEffect } from 'react';

import { Button, Screen, Stepper, Text } from '../../components';
import * as Theme from '../../constants/Theme';
import { useStore } from '../../hooks';
import './styles.scss';

const CheckoutPagarmeConfirmationScreen = () => {
  const store = useStore();

  useEffect(() => {
    // if (!store.CheckoutStore.checkoutConfirmation) {
    //   history.push('/checkout/cart');
    // }
  }, []);

  return (
    <Screen className='checkout-pagarme-confirmation'>
      <main>
        <div className='checkout__header'>
          <Text variant='h5' responsive color={Theme.primary}>
            Comprar
          </Text>
        </div>
        <div className='checkout__stepper'>
          <Stepper
            activeStep={1}
            steps={[
              {
                label: 'Compra',
                completed: true,
              },
              {
                label: 'Pagamento',
              },
            ]}
          />
        </div>
        <div className='checkout__surface surface-7'>
          <div className='checkout__surface__title'>
            <Text variant='h5'>Pedido gerado e pronto para ser pago!</Text>
          </div>

          <div className='checkout-confirmation__text'>
            <Text color={Theme.light60}>
              Eba! Seu pedido foi gerado com sucesso!
            </Text>

            <Text color={Theme.light60}>
              Você poderá realizar o pagamento através do link de pagamento disponibizado pela plataforma Pagarme, um parceiro Rokkos!
            </Text>
            <Text color={Theme.light60}>
              Realize o pagamento por PIX, clicando abaixo:
            </Text>

            <Button
              className='btn_prosseguir'
              variant='contained'
              onClick={() =>
                window.open(
                  store.CheckoutStore.checkoutConfirmation?.payment_url?.toString(),
                  '_blank',
                )
              }
            >
              Clique para prosseguir com o Pagamento
            </Button>

            <Text variant='h6' color={Theme.light60}>
              Valor do pedido:{' '}
              {store.CheckoutStore.checkoutConfirmation?.amount.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                style: 'currency',
                currency: 'BRL',
              })}
            </Text>
            <Text variant='body-1' color={Theme.light60}>
              Lembre-se que seu link de pagamento expira em
              <strong> {store.CheckoutStore.checkoutConfirmation?.invoice_expires_at}</strong>
            </Text>

          </div>
        </div>
      </main>
    </Screen>
  );
};

export default observer(CheckoutPagarmeConfirmationScreen);
