/* eslint-disable no-undef */
import { makeAutoObservable } from 'mobx';

import baseAPI from '../../services/baseAPI';
import pagarmeAPI from '../../services/pagarmeAPI';
import { maskCEP } from '../../utils';

export default class CheckoutStore {
  rootStore;
  cart = {};
  checkoutConfirmation = null;

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this, { rootStore: false });
  }

  get cartLength() {
    const events = this.cart?.cart_events;

    if (!events?.length) return 0;

    return this.cart.cart_events.reduce((sum, event) => sum + event.products.length, 0);
  }

  get pixData() {
    return this.checkoutConfirmation?.pix_data;
  }

  getCart = async () => {
    try {
      const response = await baseAPI.get('/users/shopper/cart');

      const { status, data } = response;

      if (status === 204) {
        this.cart = null;
        return true;
      }

      if (status !== 200 || !data) {
        return {
          error: {
            status,
          },
        };
      }

      this.cart = data;

      return true;
    } catch (error) {
      console.warn(error);

      return {
        error: {
          status: error?.response?.status || 400,
        },
      };
    }
  };

  addItem = async (item) => {
    try {
      const itemFound = !!this.cart?.cart_events?.find(
        ({ products }) => !!products?.find(({ photo_id }) => photo_id === item.id),
      );

      if (itemFound) return false;

      const response = await baseAPI.post(`/users/shopper/cart/product/${item.id}`);

      const { status, data } = response;

      if (status === 204) {
        this.cart = null;
        return true;
      }

      if (status !== 200 || !data) {
        return {
          error: {
            status,
          },
        };
      }

      this.cart = data;

      return true;
    } catch (error) {
      console.warn(error);

      return {
        error: {
          status: error?.response?.status || 400,
        },
      };
    }
  };

  removeItem = async (item) => {
    try {
      const response = await baseAPI.delete(`/users/shopper/cart/product/${item.id}`);

      const { status, data } = response;

      if (status === 204) {
        this.cart = null;
        return true;
      }

      if (status !== 200 || !data) {
        return {
          error: {
            status,
          },
        };
      }

      this.cart = data;

      return true;
    } catch (error) {
      console.warn(error);

      return {
        error: {
          status: error?.response?.status || 400,
        },
      };
    }
  };

  createPaymentToken = (payload = {}) => {
    return new Promise((resolve, reject) => {
      const { card_number, holder_name, expire_date, verification_value } = payload;

      pagarmeAPI
        .post('/core/v5/tokens', {
          type: 'card',
          card: {
            number: card_number,
            holder_name: holder_name,
            exp_month: expire_date.substr(0, 2),
            exp_year: expire_date.substr(2),
            cvv: verification_value,
            label: `${holder_name}_creditcard`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data.id);
          } else if (res.status === 400) {
            reject({ error: res.data.message });
          }
        })
        .catch((err) => {
          reject({ error: err.response.data.message });
        });
    });
  };

  checkoutCreditCard = async (payload = {}) => {
    try {
      const {
        holder_name,
        zip,
        street,
        number,
        complement,
        district,
        city,
        state,
        card_number,
        expire_date,
        verification_value,
      } = payload;

      const cc_token = await this.createPaymentToken({
        card_number,
        holder_name,
        expire_date,
        verification_value,
      });
      if (cc_token.error || typeof cc_token !== 'string' || !cc_token?.length) {
        throw cc_token.error || 'Erro ao ao gerar token de cartão de crédito.';
      }

      const address = {
        zip: maskCEP(zip),
        street,
        number,
        district,
        city,
        state,
      };

      if (complement?.length) address.complement = complement;

      const response = await baseAPI.post('/orders/checkout/credit_card', {
        cc_token,
        address,
        payment_method: 'credit_card',
      });

      const { status, data } = response;

      if (status !== 201 || !data?.data) {
        return {
          error: {
            status,
            message: data?.message,
          },
        };
      }

      this.cart = null;
      this.checkoutConfirmation = data.data;

      this.getCart();

      return true;
    } catch (error) {
      console.warn(error);

      return {
        error: {
          status: error?.response?.status || 400,
        },
      };
    }
  };

  checkoutPix = async (payload = {}) => {
    try {
      const { zip, street, number, complement, district, city, state } = payload;

      const address = {
        zip: maskCEP(zip),
        street,
        number,
        district,
        city,
        state,
      };

      if (complement?.length) address.complement = complement;

      const response = await baseAPI.post('/orders/checkout/pix', {
        cc_token: 'none',
        address,
        payment_method: 'pix',
      });

      const { status, data } = response;

      if (status !== 201 || !data?.data) {
        return {
          error: {
            status,
            message: data?.message,
          },
        };
      }

      this.cart = null;
      this.checkoutConfirmation = data.data;

      this.getCart();

      return true;
    } catch (error) {
      console.warn(error);

      return {
        error: {
          status: error?.response?.status || 400,
        },
      };
    }
  };

  checkout = async (payload = {}) => {
    try {
      const { zip, street, number, complement, district, city, state, cpf, phone } = payload;

      console.log(payload);

      const address = {
        zip: maskCEP(zip),
        street,
        number,
        district,
        city,
        state,
      };

      if (complement?.length) address.complement = complement;

      const phone_prefix = phone.substr(0, 2);
      const phone_body = phone.substr(2);

      const response = await baseAPI.post('/orders/checkout', {
        cc_token: 'none',
        address,
        cpf,
        phone_prefix,
        phone: phone_body,
        payment_method: 'pagarme',
      });

      const { status, data } = response;

      if (status !== 201 || !data?.data) {
        return {
          error: {
            status,
            message: data?.message,
          },
        };
      }

      this.cart = null;
      this.checkoutConfirmation = data.data;

      this.getCart();

      return response.data;
    } catch (error) {
      console.warn(error);

      return {
        error: {
          status: error?.response?.status || 400,
        },
      };
    }
  };
}
