/* eslint-disable react/prop-types */
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';

import Theme from '../../constants/Theme';

const Provider = (props) => {
  const { children } = props;
  return (
    <ThemeProvider theme={createTheme(Theme)}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default Provider;
