import PropTypes from 'prop-types';

import * as Theme from '../../../constants/Theme';

const YoutubeIcon = (props) => {
  const { color } = props;

  return (
    <svg
      width='40'
      height='28'
      viewBox='0 0 40 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16 20L26.38 14L16 8V20ZM39.12 4.34C39.38 5.28 39.56 6.54 39.68 8.14C39.82 9.74 39.88 11.12 39.88 12.32L40 14C40 18.38 39.68 21.6 39.12 23.66C38.62 25.46 37.46 26.62 35.66 27.12C34.72 27.38 33 27.56 30.36 27.68C27.76 27.82 25.38 27.88 23.18 27.88L20 28C11.62 28 6.4 27.68 4.34 27.12C2.54 26.62 1.38 25.46 0.88 23.66C0.62 22.72 0.44 21.46 0.32 19.86C0.18 18.26 0.12 16.88 0.12 15.68L0 14C0 9.62 0.32 6.4 0.88 4.34C1.38 2.54 2.54 1.38 4.34 0.88C5.28 0.62 7 0.44 9.64 0.32C12.24 0.18 14.62 0.12 16.82 0.12L20 0C28.38 0 33.6 0.32 35.66 0.88C37.46 1.38 38.62 2.54 39.12 4.34Z'
        fill={color}
      />
    </svg>
  );
};

YoutubeIcon.propTypes = {
  color: PropTypes.string,
};

YoutubeIcon.defaultProps = {
  color: Theme.light87,
};

export default YoutubeIcon;
