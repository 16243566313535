import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { FileUploader, Modal, Text } from '..';
import * as Theme from '../../constants/Theme';
import './styles.scss';

const FileUploaderModal = (props) => {
  const { modalProps, title, description, uploaderDescription, preview, multiple, onChange } =
    props;

  return (
    <Modal rounded={false} className='file-uploader-modal' {...modalProps}>
      {title?.length ? (
        <div className='file-uploader-modal__title'>
          <Text variant='h5' color={Theme.primary}>
            {title}
          </Text>
        </div>
      ) : null}

      {description?.length ? (
        <div className='file-uploader-modal__description'>
          <Text variant='body-1' color={Theme.light87}>
            {description}
          </Text>
        </div>
      ) : null}

      <div className='file-uploader-modal__uploader'>
        <FileUploader
          onChange={onChange}
          preview={preview}
          multiple={multiple}
          uploaderDescription={uploaderDescription}
        />
      </div>
    </Modal>
  );
};

FileUploaderModal.propTypes = {
  modalProps: PropTypes.instanceOf(Object),
  title: PropTypes.string,
  description: PropTypes.string,
  uploaderDescription: PropTypes.string,
  preview: PropTypes.string,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
};

FileUploaderModal.defaultProps = {
  modalProps: {},
  title: null,
  description: null,
  uploaderDescription: null,
  preview: null,
  multiple: false,
  onChange: () => [],
};

export default observer(FileUploaderModal);
