import { makeObservable, observable } from 'mobx';

export default class Album {
  id = null;
  owner = null;
  event = null;
  photo_count = null;
  cover_url = null;

  constructor(newAlbum = {}) {
    makeObservable(this, {
      id: observable,
      owner: observable,
      event: observable,
      photo_count: observable,
      cover_url: observable,
    });

    if (newAlbum.id == null) {
      throw new Error('Invalid Album constructor');
    }

    const { id, owner, event, photo_count, cover_url } = newAlbum;

    this.id = id;
    this.owner = owner || null;
    this.event = event || null;
    this.photo_count = photo_count || null;
    this.cover_url = cover_url || '';
  }
}
