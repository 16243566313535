const validateURL = (value) => {
  if (typeof value !== 'string') return false;

  let text = value;

  if (value?.slice(value.length - 1) === '/') {
    text = text.slice(0, -1);
  }

  return /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm.test(
    text,
  );
};

export default validateURL;
