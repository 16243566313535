import PropTypes from 'prop-types';

import { Text } from '..';
import * as Theme from '../../constants/Theme';
import { CheckIcon } from '../svg';
import './styles.scss';

const BulletList = (props) => {
  const { options } = props;

  return (
    <div className='bullet-list'>
      {options?.length
        ? options.map(({ label, description, checked }) => (
            <div key={label} className='bullet-list__item'>
              <div className='bullet-list__item__icon'>
                <CheckIcon color={checked ? Theme.success : Theme.light60} />
              </div>
              <div className='bullet-list__item__text'>
                <Text variant='body-1' className='bullet-list__item__title'>
                  {label}
                </Text>
                <Text variant='body-1' className='bullet-list__item__description'>
                  {description}
                </Text>
              </div>
            </div>
          ))
        : null}
    </div>
  );
};

BulletList.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      description: PropTypes.string,
      checked: PropTypes.bool,
    }).isRequired,
  ).isRequired,
};

export default BulletList;
