import PropTypes from 'prop-types';
import { Fade } from 'react-reveal';

import { Text } from '..';
import './styles.scss';

const PublicProfileAchievements = (props) => {
  const { achievements } = props;

  if (!achievements?.length) return null;

  return (
    <section className='public-profile__achievements'>
      <Fade bottom distance='10px'>
        <Text className='public-profile__achievements__title' variant='h5' responsive>
          MINHAS CONQUISTAS
        </Text>
      </Fade>

      <div className='public-profile__achievements__list'>
        {achievements.map(({ id, image_url, label, value }, index) => (
          <Fade bottom distance='10px' delay={(index + 1) * 100} key={id}>
            <div key={id} className='achievement'>
              <div className='achievement__image'>
                <img src={image_url} />
                <div className='achievement__value'>
                  <Text variant='subtitle-1'>{value}</Text>
                </div>
              </div>
              <div className='achievement__label'>
                <Text variant='subtitle-1'>{label}</Text>
              </div>
            </div>
          </Fade>
        ))}
      </div>
    </section>
  );
};

PublicProfileAchievements.propTypes = {
  achievements: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      cover_url: PropTypes.string,
      name: PropTypes.string,
      date: PropTypes.string,
      photo_count: PropTypes.number,
    }),
  ),
};

export default PublicProfileAchievements;
