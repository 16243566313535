import PropTypes from 'prop-types';

import * as Theme from '../../../constants/Theme';

const LinkIcon = (props) => {
  const { color, size } = props;

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3.9 12C3.9 10.29 5.29 8.9 7 8.9H11V7H7C4.24 7 2 9.24 2 12C2 14.76 4.24 17 7 17H11V15.1H7C5.29 15.1 3.9 13.71 3.9 12ZM8 13H16V11H8V13ZM17 7H13V8.9H17C18.71 8.9 20.1 10.29 20.1 12C20.1 13.71 18.71 15.1 17 15.1H13V17H17C19.76 17 22 14.76 22 12C22 9.24 19.76 7 17 7Z'
        fill={color}
      />
    </svg>
  );
};

LinkIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

LinkIcon.defaultProps = {
  color: Theme.light60,
  size: 24,
};

export default LinkIcon;
