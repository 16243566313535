import { makeObservable, observable } from 'mobx';

import Photo from './Photo';

export default class Order {
  id = null;
  amount_total = null;
  amount_master = null;
  amount_sub = null;
  amount_gateway_cost = null;
  created_at = null;
  photos = null;
  photographer = null;

  constructor(newOrder = {}) {
    makeObservable(this, {
      id: observable,
      amount_total: observable,
      amount_master: observable,
      amount_sub: observable,
      amount_gateway_cost: observable,
      created_at: observable,
      photos: observable,
      photographer: observable,
    });

    const {
      id,
      amount_total,
      amount_master,
      amount_sub,
      amount_gateway_cost,
      created_at,
      photos,
      photographer,
    } = newOrder;

    this.id = id || null;
    this.amount_total = amount_total || null;
    this.amount_master = amount_master || null;
    this.amount_sub = amount_sub || null;
    this.amount_gateway_cost = amount_gateway_cost || null;
    this.created_at = created_at || null;
    this.photographer = photographer || null;

    if (photos?.length) {
      this.photos = photos.filter(({ photo }) => photo).map(({ photo }) => new Photo(photo));
    }
  }
}
