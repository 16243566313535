import { TextField } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Button, Screen, Stepper, Text } from '../../components';
import * as Theme from '../../constants/Theme';
import { useStore } from '../../hooks';
import './styles.scss';

const CheckoutPixConfirmationScreen = () => {
  const history = useHistory();
  const store = useStore();

  const handleCopyClipboard = () => {
    navigator.clipboard.writeText(store.CheckoutStore.pixData?.qrcode_text);
    toast.success('Código PIX copiado!');
  };

  useEffect(() => {
    if (!store.CheckoutStore.checkoutConfirmation) {
      history.push('/checkout/cart');
    }
  }, []);

  return (
    <Screen className='checkout-pix-confirmation'>
      <main>
        <div className='checkout__header'>
          <Text variant='h5' responsive color={Theme.primary}>
            Comprar
          </Text>
        </div>
        <div className='checkout__stepper'>
          <Stepper
            activeStep={1}
            steps={[
              {
                label: 'Compra',
                completed: true,
              },
              {
                label: 'Confirmação',
              },
            ]}
          />
        </div>
        <div className='checkout__surface surface-7'>
          <div className='checkout__surface__title'>
            <Text variant='h5'>Confirmação de pedido</Text>
          </div>

          <div className='checkout-confirmation__text'>
            <Text variant='h6' color={Theme.light60}>
              Eba! Seu pedido foi gerado com sucesso!
            </Text>
          </div>

          <div className='checkout__surface__columns'>
            <div className='checkout__surface__columns__col col1'>
              <Text variant='h6' color={Theme.light60}>
                Valor do pedido:{' '}
                {store.CheckoutStore.checkoutConfirmation?.amount.toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  style: 'currency',
                  currency: 'BRL',
                })}
              </Text>
              <Text variant='body-1' color={Theme.light60}>
                Lembre-se que seu pagamento PIX expira em
                <strong> {store.CheckoutStore.checkoutConfirmation?.invoice_expires_at}</strong>
              </Text>
            </div>
            <div className='checkout__surface__columns__col col2'>
              <Text variant='h6' color={Theme.light60}>
                Opção 1
              </Text>
              <Text variant='body-1' color={Theme.light60}>
                Identificando sua transferência com um identificador único
              </Text>

              <TextField
                className='qr_code_text'
                value={store.CheckoutStore.pixData?.qrcode_text?.toString()}
                disabled={true}
                multiline={true}
                maxRows={5}
                onClick={handleCopyClipboard}
              />
              <Button className='btn_copy' variant='contained' onClick={handleCopyClipboard}>
                Copiar código
              </Button>

              <Text variant='body-1' color={Theme.light60}>
                Instruções de pagamento com ID:
                <br />
                1. Copie o ID da transação.
                <br />
                2. Use o ID para identificar seu pagamento PIX no aplicativo do seu banco.
              </Text>
            </div>
            <div className='checkout__surface__columns__col col3'>
              <Text variant='h6' color={Theme.light60}>
                Opção 2
              </Text>

              <Text variant='body-1' color={Theme.light60}>
                Lendo o código QR no aplicativo do seu banco
              </Text>

              <img
                className='qr_code_image'
                src={store.CheckoutStore.pixData?.qrcode}
                alt='QR-Code para pagamento'
              />
            </div>
            <div className='checkout__surface__columns__col col4'>
              <Text variant='h6' color={Theme.light60}>
                Conclusão
              </Text>

              <Text variant='body-1' color={Theme.light60}>
                Após realizar o pagamento, as fotos serão liberadas na página Minhas Compras
              </Text>

              <Button
                className='btn_collection'
                variant='outlined'
                size='micro'
                onClick={() => history.push('/collection')}
              >
                Minhas compras
              </Button>
            </div>
          </div>
        </div>
      </main>
    </Screen>
  );
};

export default observer(CheckoutPixConfirmationScreen);
