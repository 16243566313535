import PropTypes from 'prop-types';

import * as Theme from '../../../constants/Theme';

const TrophyIcon = (props) => {
  const { color } = props;

  return (
    <svg
      width='204'
      height='300'
      viewBox='0 0 204 300'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M72.7062 129.92C72.1595 129.92 71.6064 129.793 71.0873 129.525L48.9066 118.074C39.1045 113.013 31.9197 103.978 29.1945 93.2857L22.8886 68.5252C21.7865 64.1992 22.7257 59.6969 25.4656 56.1725C28.2048 52.648 32.3351 50.626 36.7988 50.626H72.7097C74.6621 50.626 76.2453 52.2086 76.2453 54.1615C76.2453 56.1139 74.6627 57.6971 72.7097 57.6971H36.7988C34.5341 57.6971 32.4383 58.723 31.0484 60.5113C29.6586 62.299 29.1804 64.5842 29.7406 66.7803L36.0476 91.5408C38.2736 100.275 44.1435 107.657 52.1509 111.791L74.3316 123.243C76.0672 124.139 76.748 126.271 75.8515 128.007C75.2222 129.222 73.9865 129.92 72.7062 129.92Z'
        fill={color}
      />
      <path
        d='M131.297 129.92C130.016 129.92 128.781 129.222 128.152 128.005C127.257 126.269 127.937 124.137 129.673 123.241L151.854 111.789C159.862 107.655 165.73 100.274 167.957 91.5387L174.264 66.777C174.824 64.582 174.346 62.2969 172.956 60.5092C171.566 58.7215 169.47 57.6961 167.206 57.6961H131.295C129.343 57.6961 127.759 56.1135 127.759 54.1605C127.759 52.2082 129.342 50.625 131.295 50.625H167.206C171.67 50.625 175.801 52.6465 178.54 56.1703C181.279 59.6947 182.219 64.1965 181.117 68.523L174.81 93.2859C172.086 103.978 164.9 113.012 155.098 118.074L132.917 129.526C132.398 129.793 131.844 129.92 131.297 129.92Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M93.8594 150.696H102.173H110.142H110.147V201.424H110.142H102.173H93.8594V150.696Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M64.5016 106.499C64.6737 127.032 81.5634 143.695 102.469 143.695C123.375 143.695 140.265 127.033 140.437 106.499L140.951 45.1064H63.9879L64.5016 106.499ZM57.9877 45.1064L57.9375 39.1064H63.9377H141.001H147.001L146.951 45.1064L146.437 106.55C146.236 130.436 126.61 149.695 102.469 149.695C78.3286 149.695 58.702 130.435 58.5018 106.55L57.9877 45.1064Z'
        fill={color}
      />
      <path
        d='M74.707 204.131H129.293V207.046H74.707V204.131Z'
        stroke={color}
        strokeWidth='4'
      />
      <path
        d='M135.653 212.5C139.138 212.5 142.096 214.759 143.141 217.893H60.9042C61.9499 214.759 64.9076 212.5 68.3922 212.5C68.3923 212.5 68.3924 212.5 68.3924 212.5H135.653Z'
        stroke={color}
        strokeWidth='5'
      />
      <path
        d='M48.8896 271.688H156.028C158.73 271.688 160.918 269.499 160.918 266.799V225.89C160.918 223.188 158.728 221 156.028 221H48.8896C46.1885 221 44 223.19 44 225.89V266.799C44 269.499 46.1896 271.688 48.8896 271.688Z'
        fill={color}
      />
      <path d='M155 227H50V265H155V227Z' fill='black' fillOpacity='0.74' />
    </svg>
  );
};

TrophyIcon.propTypes = {
  color: PropTypes.string,
};

TrophyIcon.defaultProps = {
  color: Theme.light60,
};

export default TrophyIcon;
