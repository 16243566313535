import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  FilledInput as MUIFilledInput,
  Input as MUIInput,
  OutlinedInput as MUIOutlinedInput,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useState } from 'react';

import { VisibilityIcon, VisibilityOffIcon } from '../svg';
import {
  CEPMask,
  CNPJMask,
  CPFMask,
  CVVMask,
  CreditCardMask,
  CurrencyMask,
  IntegerMask,
  MonthYearMask,
  NumberMask,
  PhoneMask,
} from './inputMasks';
import './styles.scss';

const Input = (props) => {
  const {
    id,
    className,
    value,
    label,
    placeholder,
    variant,
    color,
    secureText,
    labelWidth,
    startAdornment,
    endAdornment,
    helperText,
    error,
    fullWidth,
    mask,
    autoFocus,
    autoComplete,
    disabled,
    lineThrough,
    onChange,
    onChangeText,
    inputProps,
  } = props;
  const [secureValue, setSecureValue] = useState(secureText);

  const getInputClassNames = () => {
    let newClassNames = 'input';
    if (variant?.length) newClassNames += ` input--${variant}`;
    if (color?.length) newClassNames += ` input--${color}`;
    if (className?.length) newClassNames += ` ${className}`;
    if (lineThrough) newClassNames += ` line-through`;
    return newClassNames;
  };

  const toggleShowPassword = () => {
    setSecureValue(!secureValue);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const _onChange = (event) => {
    if (onChange) onChange(event);
    if (onChangeText) onChangeText(event.target.value);
  };

  const type = secureText && secureValue ? 'password' : 'text';

  const passwordIcon = secureValue ? <VisibilityIcon /> : <VisibilityOffIcon />;

  const passwordButtonAriaLabel = secureValue ? 'Hide text' : 'Show text';

  const _startAdornment = startAdornment ? (
    <InputAdornment position='start'>{startAdornment}</InputAdornment>
  ) : null;

  const _endAdornment = secureText ? (
    <InputAdornment position='end'>
      <IconButton
        className='bt-password'
        title={passwordButtonAriaLabel}
        aria-label={passwordButtonAriaLabel}
        onClick={toggleShowPassword}
        onMouseDown={handleMouseDownPassword}
        edge='end'
        disableRipple
      >
        {passwordIcon}
      </IconButton>
    </InputAdornment>
  ) : endAdornment ? (
    <InputAdornment position='end'>{endAdornment}</InputAdornment>
  ) : null;

  const _inputProps = {
    id,
    name: id,
    type,
    value: value || '',
    placeholder,
    startAdornment: _startAdornment,
    endAdornment: _endAdornment,
    autoFocus,
    autoComplete,
    onChange: _onChange,
    'aria-describedby': `${id}-helper-text`,
    inputProps: { ...inputProps },
  };

  switch (mask) {
    case 'integer': {
      _inputProps.inputComponent = IntegerMask;
      break;
    }

    case 'number': {
      _inputProps.inputComponent = NumberMask;
      break;
    }

    case 'currency': {
      _inputProps.inputComponent = CurrencyMask;
      break;
    }

    case 'phone': {
      _inputProps.inputComponent = PhoneMask;
      break;
    }

    case 'cpf': {
      _inputProps.inputComponent = CPFMask;
      break;
    }

    case 'cnpj': {
      _inputProps.inputComponent = CNPJMask;
      break;
    }

    case 'cep': {
      _inputProps.inputComponent = CEPMask;
      break;
    }

    case 'credit-card': {
      _inputProps.inputComponent = CreditCardMask;
      break;
    }

    case 'month-year': {
      _inputProps.inputComponent = MonthYearMask;
      break;
    }

    case 'cvv': {
      _inputProps.inputComponent = CVVMask;
      break;
    }

    default: {
      break;
    }
  }

  const formHelperText = helperText ? (
    <FormHelperText id={`${id}-helper-text`}>{helperText}</FormHelperText>
  ) : null;

  const inputLabel = label ? <InputLabel htmlFor={id}>{label}</InputLabel> : null;

  const renderTextField = () => {
    switch (variant) {
      case 'outlined': {
        return <MUIOutlinedInput {..._inputProps} labelWidth={labelWidth} />;
      }

      case 'filled': {
        return <MUIFilledInput {..._inputProps} />;
      }

      default: {
        return <MUIInput {..._inputProps} />;
      }
    }
  };

  return (
    <FormControl
      className={getInputClassNames()}
      variant={variant}
      error={error}
      fullWidth={fullWidth}
      disabled={disabled}
    >
      {inputLabel}
      {renderTextField()}
      {formHelperText}
    </FormControl>
  );
};

Input.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  autoFocus: PropTypes.bool,
  variant: PropTypes.oneOf(['standard', 'outlined', 'filled']),
  color: PropTypes.oneOf(['primary', 'light']),
  secureText: PropTypes.bool,
  startAdornment: PropTypes.node,
  endAdornment: PropTypes.node,
  labelWidth: PropTypes.number,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  fullWidth: PropTypes.bool,
  mask: PropTypes.oneOf([
    'integer',
    'number',
    'currency',
    'phone',
    'cpf',
    'cnpj',
    'cep',
    'credit-card',
    'month-year',
    'cvv',
    null,
  ]),
  disabled: PropTypes.bool,
  lineThrough: PropTypes.bool,
  autoComplete: PropTypes.string,
  onChange: PropTypes.func,
  onChangeText: PropTypes.func,
  inputProps: PropTypes.object,
};

Input.defaultProps = {
  placeholder: null,
  className: null,
  value: '',
  label: null,
  labelWidth: 0,
  variant: 'outlined',
  color: 'primary',
  secureText: false,
  startAdornment: null,
  endAdornment: null,
  helperText: null,
  error: false,
  autoFocus: false,
  autoComplete: null,
  fullWidth: false,
  mask: null,
  disabled: false,
  lineThrough: false,
  inputProps: {},
  onChange: () => {},
  onChangeText: () => '',
};

export default Input;
