import { Pagination } from '@material-ui/lab';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Button, EmptyState, Loader, OrderListItem, Screen, Text } from '../../components';
import { EmptyStatePhotoSearchIcon } from '../../components/svg';
import { SYSTEM_INSTABILITY } from '../../constants/Messages';
import * as Theme from '../../constants/Theme';
import { useStore } from '../../hooks';
import './styles.scss';

const OrdersScreen = () => {
  const history = useHistory();
  const store = useStore();
  const [mounted, setMounted] = useState(false);
  const [requesting, setRequesting] = useState(true);
  const [page, setPage] = useState(1);

  const orders = store.UserStore.ordersList;
  const statistics = store.UserStore.ordersStatistics;

  const getOrders = async () => {
    try {
      setRequesting(true);

      const response = await flowResult(store.UserStore.getOrders({ page }));

      if (response.error) toast.error(SYSTEM_INSTABILITY);
    } catch (error) {
      console.log(error);
    } finally {
      setRequesting(false);
    }
  };

  const renderOrders = () => {
    if (requesting) return <Loader color={Theme.primary} />;

    if (mounted && !requesting && !orders?.length) {
      return (
        <EmptyState
          icon={<EmptyStatePhotoSearchIcon />}
          title='Você ainda não possui nenhuma venda.'
          description='Busque ou crie um evento e comece a vender  suas fotos na Rokkos!'
          button={
            <Button variant='contained' onClick={() => history.push('/')}>
              Vender minhas fotos
            </Button>
          }
        />
      );
    }

    return (
      <>
        <div>
          {/* <RestoreIcon size={40} color={Theme.primary} /> */}
          <Text className='order_statistics' variant='h4'>
            {`Minha receita: R$${statistics.sum_amount_sub}`}
          </Text>
          <Text className='order_statistics' variant='h5'>
            {`Total de vendas: R$${statistics.sum_amount_total}`}
          </Text>
          <Text className='order_statistics' variant='h5'>
            {`Comissão Rokkos: R$${statistics.sum_amount_master}`}
          </Text>
          <Text className='order_statistics' variant='h5'>
            {`Custo com gateway: R$${statistics.sum_amount_gateway_cost}`}
          </Text>
        </div>
        <table className='checkout__surface surface-7'>
          <thead className='orders__list-header'>
            <tr>
              <th className='orders__list-header__order'>
                <Text variant='body-1' color={Theme.primary}>
                  Código
                </Text>
              </th>
              <th className='orders__list-header__date'>
                <Text variant='body-1' color={Theme.primary}>
                  Data
                </Text>
              </th>
              <th className='orders__list-header__count'>
                <Text variant='body-1' color={Theme.primary}>
                  Quantidade
                </Text>
              </th>
              <th className='orders__list-header__thumbs'>
                <Text variant='body-1' color={Theme.primary}>
                  Miniaturas
                </Text>
              </th>
              <th className='orders__list-header__total'>
                <Text variant='body-1' color={Theme.primary}>
                  Total Fotógrafo
                </Text>
              </th>
              <th className='orders__list-header__total'>
                <Text variant='body-1' color={Theme.primary}>
                  Comissão Rokkos
                </Text>
              </th>
              <th className='orders__list-header__total'>
                <Text variant='body-1' color={Theme.primary}>
                  Custo do Gateway
                </Text>
              </th>
              <th className='orders__list-header__total'>
                <Text variant='body-1' color={Theme.primary}>
                  Valor Total
                </Text>
              </th>
            </tr>
          </thead>

          <tbody className='orders__list'>
            {orders.map(
              ({
                id,
                created_at,
                amount_total,
                amount_master,
                amount_sub,
                amount_gateway_cost,
                photos,
              }) => (
                <OrderListItem
                  key={id}
                  id={id}
                  created_at={created_at}
                  amount_total={amount_total}
                  amount_master={amount_master}
                  amount_sub={amount_sub}
                  amount_gateway_cost={amount_gateway_cost}
                  photos={photos}
                />
              ),
            )}
          </tbody>
        </table>
      </>
    );
  };

  const init = async () => {
    await getOrders();
    setMounted(true);
  };

  useEffect(() => {
    if (!mounted) return;

    getOrders();

    if (window.innerWidth > 1024) {
      window.scrollTo(0, 140);
      return;
    }

    window.scrollTo(0, 90);
  }, [page]);

  useEffect(() => {
    init();
  }, []);

  return (
    <Screen className='orders'>
      <main>
        <div className='checkout__header'>
          <Text variant='h5' responsive color={Theme.primary}>
            Minhas vendas
          </Text>
        </div>

        {renderOrders()}

        {mounted && orders?.length && store.UserStore.ordersMeta?.last_page > 1 ? (
          <Pagination
            page={page}
            count={store.UserStore.ordersMeta.last_page}
            color='primary'
            onChange={(_, page) => setPage(page)}
            disabled={requesting}
          />
        ) : null}
      </main>
    </Screen>
  );
};

export default observer(OrdersScreen);
