import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { useEffect } from 'react';

import { Autocomplete, Button, DatePicker, Input } from '..';
import { searchBarFormDefinitions } from '../../constants/FormDefinitions';
import { useStore } from '../../hooks';
import { SearchIcon } from '../svg';
import './styles.scss';

const SearchBar = (props) => {
  const {
    placeholder,
    buttonLabel,
    requesting,
    onChangeText,
    onChangeLocation,
    onChangeDate,
    onSubmitSearch,
  } = props;

  const store = useStore();

  const { initialValues, validationSchema } = searchBarFormDefinitions;

  const cities = store.ConstantStore.cities.map((city) => ({
    value: String(city.id),
    label: `${city.name} - ${city.state}`,
  }));

  const onSubmit = (formValues) => {
    if (new Date(formValues.date) instanceof Date) {
      onSubmitSearch(formValues);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit,
  });

  useEffect(() => {
    onChangeText(formik.values.text);
  }, [formik.values.text]);

  useEffect(() => {
    onChangeLocation(formik.values.location);
  }, [formik.values.location]);

  useEffect(() => {
    onChangeDate(formik.values.date);
  }, [formik.values.date]);

  return (
    <form className='search-bar' noValidate onSubmit={formik.handleSubmit}>
      <Input
        id='text'
        placeholder={placeholder}
        variant='filled'
        value={formik.values.text}
        onChange={formik.handleChange}
      />
      <Autocomplete
        id='location'
        options={cities}
        onChange={(option) =>
          option?.value
            ? formik.setFieldValue('location', option.value)
            : formik.setFieldValue('location', null)
        }
        fullWidth
      />
      <DatePicker
        value={formik.values.date}
        onChange={(date) => formik.setFieldValue('date', date)}
      />
      <Button variant='contained' type='submit' loading={requesting}>
        <div className='search-bar__bt-icon'>
          <SearchIcon color='#000' size={40} />
        </div>
        <div className='search-bar__bt-text'>{buttonLabel}</div>
      </Button>
    </form>
  );
};

SearchBar.propTypes = {
  placeholder: PropTypes.string,
  buttonLabel: PropTypes.string,
  requesting: PropTypes.bool,
  onChangeText: PropTypes.func,
  onChangeLocation: PropTypes.func,
  onChangeDate: PropTypes.func,
  onSubmitSearch: PropTypes.func.isRequired,
};

SearchBar.defaultProps = {
  placeholder: 'Pesquisar',
  buttonLabel: 'Buscar',
  requesting: false,
  onChangeText: () => '',
  onChangeLocation: () => '',
  onChangeDate: () => '',
};

export default SearchBar;
