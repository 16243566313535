/* eslint-disable no-undef */
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

const sentryService = () => {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
};

export default sentryService;
