import { Tab, Tabs } from '@material-ui/core';
import { useFormik } from 'formik';
import { flowResult } from 'mobx';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import banner from '../../assets/images/banner-login.png';
import { Button, Checkbox, Input, Logo, Screen, Text } from '../../components';
import { registerFormDefinitions } from '../../constants/FormDefinitions';
import { EMAIL_FOUND, REGISTER_SUCCESS, SYSTEM_INSTABILITY } from '../../constants/Messages';
import * as Theme from '../../constants/Theme';
import { useQuery, useStore } from '../../hooks';
import './styles.scss';

const RegisterScreen = () => {
  const history = useHistory();
  const store = useStore();
  const query = useQuery();
  const redirectUri = query.get('redirect');

  const [mounted, setMounted] = useState(false);

  const { initialValues, validationSchema } = registerFormDefinitions;

  const getScreenClassNames = () => {
    let newClassNames = 'register';
    if (mounted) newClassNames += ' register--active';
    return newClassNames;
  };

  const onSubmit = async (formValues) => {
    try {
      const response = await flowResult(store.UserStore.register(formValues));

      if (response?.error) {
        switch (response?.error?.status) {
          case 422: {
            toast.error(EMAIL_FOUND);
            return;
          }

          default: {
            toast.error(SYSTEM_INSTABILITY);
            return;
          }
        }
      }

      // history.push("/register/confirmation");

      history.push(`/login${redirectUri?.length ? `?redirect=${redirectUri}` : ''}`);
      toast.success(REGISTER_SUCCESS);
    } catch (error) {
      console.log(error);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit,
  });

  useEffect(() => {
    const email = query.get('email');
    if (email?.length) {
      formik.setFieldValue('email', email);

      const nameInput = document.querySelector('#name');

      if (nameInput) nameInput.focus();
    }

    const animationTimeout = setTimeout(() => {
      setMounted(true);
    }, 100);

    return () => {
      clearTimeout(animationTimeout);
    };
  }, []);

  return (
    <Screen className={getScreenClassNames()} container={false}>
      <div className='register__banner'>
        <img src={banner} alt='Photographer' />
      </div>
      <div
        className='register__content'
        style={{
          pointerEvents: formik.isSubmitting ? 'none' : 'initial',
        }}
      >
        <div
          className='register__content__logo'
          onClick={() =>
            history.push(`/login${redirectUri?.length ? `?redirect=${redirectUri}` : ''}`)
          }
        >
          <Logo big />
        </div>
        <div className='register__content__title'>
          <Text variant='h5' responsive color={Theme.primary}>
            Criar conta
          </Text>
        </div>
        <div className='register__content__tabs'>
          <Tabs
            className='tabs'
            onChange={(_, activeTab) => {
              if (typeof activeTab === 'number') {
                formik.setFieldValue('type', activeTab);
              }
            }}
            variant='scrollable'
            indicatorColor='primary'
            textColor='primary'
            value={formik.values.type}
          >
            <Tab label='Sou fotógrafo' />
            <Tab label='Sou cliente' />
          </Tabs>
          {formik.touched.type && !!formik.errors.type ? (
            <div className='form-error'>{formik.errors.type}</div>
          ) : null}
        </div>
        <form noValidate onSubmit={formik.handleSubmit}>
          <div className='register__content__inputs'>
            <div className='register__content__inputs__input-wrapper'>
              <Input
                variant='filled'
                placeholder='Nome'
                fullWidth
                id='name'
                autoComplete='new-password'
                value={formik.values.name}
                error={formik.touched.name && !!formik.errors.name}
                helperText={formik.touched.name && formik.errors.name}
                onChange={formik.handleChange}
              />
            </div>
            <div className='register__content__inputs__input-wrapper'>
              <Input
                variant='filled'
                placeholder='E-mail'
                fullWidth
                id='email'
                autoComplete='new-password'
                value={formik.values.email}
                error={formik.touched.email && !!formik.errors.email}
                helperText={formik.touched.email && formik.errors.email}
                onChange={formik.handleChange}
              />
            </div>
            <div className='register__content__inputs__input-wrapper'>
              <Input
                variant='filled'
                placeholder='Senha'
                fullWidth
                id='password'
                autoComplete='new-password'
                value={formik.values.password}
                error={formik.touched.password && !!formik.errors.password}
                helperText={formik.touched.password && formik.errors.password}
                onChange={formik.handleChange}
                secureText
              />
            </div>
            <div className='register__content__use-terms'>
              <div className='register__content__use-terms__checkbox'>
                <Checkbox
                  id='use-terms'
                  label=''
                  onChange={(value) => formik.setFieldValue('legal_consent', value)}
                />
                <div className='use-terms-text-wrapper'>
                  <Text
                    variant='subtitle-1'
                    color={Theme.light60}
                    className='bt-use-terms__text'
                  >
                    Aceito a{' '}
                  </Text>
                  <Button
                    className='bt-use-terms'
                    onClick={() => {
                      window.open('https://rokkos.app/politica-de-privacidade/', '_blank');
                    }}
                  >
                    <Text
                      variant='subtitle-1'
                      color={Theme.primary}
                      className='bt-use-terms__text'
                    >
                      {' '}
                      política de privacidade
                    </Text>
                  </Button>
                  <Text
                    variant='subtitle-1'
                    color={Theme.light60}
                    className='bt-use-terms__text'
                  >
                    {' '}
                    e os{' '}
                  </Text>
                  <Button
                    className='bt-use-terms'
                    onClick={() => {
                      window.open('https://www.rokkos.app/termos-de-uso-e-servico/', '_blank');
                    }}
                  >
                    <Text
                      variant='subtitle-1'
                      color={Theme.primary}
                      className='bt-use-terms__text'
                    >
                      termos de uso
                    </Text>
                  </Button>
                </div>
              </div>
              {formik.touched.legal_consent && !!formik.errors.legal_consent ? (
                <div className='register__content__use-terms__error'>
                  {formik.errors.legal_consent}
                </div>
              ) : null}
            </div>
          </div>

          <div className='register__content__actions'>
            <div className='register__content__actions__input-wrapper'>
              <Button variant='contained' fullWidth type='submit' loading={formik.isSubmitting}>
                Criar uma conta
              </Button>
            </div>
            <div className='register__content__actions__input-wrapper'>
              <Button
                className='bt-register'
                onClick={() =>
                  history.push(`/login${redirectUri?.length ? `?redirect=${redirectUri}` : ''}`)
                }
              >
                <Text variant='subtitle-1' color={Theme.light60} className='bt-register__text'>
                  Já possui uma conta?
                </Text>
                <Text variant='subtitle-1' color={Theme.primary} className='bt-register__text'>
                  Faça login
                </Text>
              </Button>
            </div>
          </div>
        </form>
      </div>
    </Screen>
  );
};

export default RegisterScreen;
