/* eslint-disable no-unused-vars */
import { Container } from '@material-ui/core';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  Banner,
  Footer,
  PublicProfileAbout,
  PublicProfileAchievements,
  PublicProfileCategories,
  PublicProfileEvents,
  Screen,
} from '../../components';
import { SYSTEM_INSTABILITY } from '../../constants/Messages';
import * as Theme from '../../constants/Theme';
import { useStore } from '../../hooks';
import './styles.scss';

const PublicProfileScreen = () => {
  const history = useHistory();
  const store = useStore();
  const params = useParams();

  const [mounted, setMounted] = useState(false);

  const publicProfile = store.UserStore.publicPhotographerProfile?.user;

  const init = async () => {
    try {
      const response = await flowResult(
        store.UserStore.getPublicProfile({ username: params.username }),
      );

      if (response?.error) {
        switch (response?.error?.status) {
          default: {
            toast.error(SYSTEM_INSTABILITY);
            return;
          }
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setMounted(true);
    }
  };

  useEffect(() => {
    init();
  }, []);

  if (!mounted || !publicProfile) return null;

  return (
    <Screen className='public-profile' container={false}>
      <Banner src={publicProfile.banner_url} />
      <Container maxWidth={Theme.containerMaxWidth}>
        <main>
          <PublicProfileAbout user={publicProfile} />
          <PublicProfileCategories categories={publicProfile.categories} />
          <PublicProfileEvents user={publicProfile} />
          <PublicProfileAchievements achievements={publicProfile.achievements} />
        </main>
      </Container>
      <Footer user={publicProfile} />
    </Screen>
  );
};

export default observer(PublicProfileScreen);
