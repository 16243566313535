/* eslint-disable no-undef */
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { CameraLoader, Header, PublicHeader, UploadQueue } from '..';
import {
  GlobalRoutes,
  PhotographerRoutes,
  PrivateRoutes,
  PublicRoutes,
  ShopperRoutes,
} from '../../constants/Routes';
import { useStore } from '../../hooks';

const renderRoutes = (status, getCitiesStatus, user) => {
  let routes = PublicRoutes;

  if (status === 'authenticated') {
    switch (user?.type) {
      case 1: {
        routes = PhotographerRoutes;
        break;
      }

      case 2: {
        routes = ShopperRoutes;
        break;
      }

      default: {
        break;
      }
    }

    routes = [...routes, ...PrivateRoutes];
  }

  routes = [...routes, ...GlobalRoutes];

  if (status === 'fetching' || getCitiesStatus === 'fetching') {
    return (
      <div className='app-loader'>
        <CameraLoader />
      </div>
    );
  }

  return (
    <>
      {status === 'authenticated' && user?.type ? <Header user={user} /> : <PublicHeader />}
      <Switch>
        {status !== 'authenticated'
          ? [...ShopperRoutes, ...PhotographerRoutes, ...PrivateRoutes]
              .filter(({ path }) => {
                return (
                  !['/event', '/private-events', '/event-not-found', '/photographer'].find(
                    (route) => path.startsWith(route),
                  ) &&
                  path !== '/' &&
                  path !== '*'
                );
              })
              .map(({ path }) => <Redirect key={path} from={path} to='/' />)
          : null}
        {routes.map(({ path, component }) => (
          <Route key={path} path={path} component={component} exact />
        ))}
      </Switch>
      {user?.type === 1 ? <UploadQueue /> : null}
    </>
  );
};

const Routes = () => {
  const store = useStore();
  const location = useLocation();

  useEffect(() => {
    if (process.env.REACT_APP_PROD) {
      ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
    }
  }, [location.pathname, location.search]);

  return renderRoutes(
    store.AuthStore.status,
    store.ConstantStore.getCitiesStatus,
    store.UserStore.profile,
  );
};

export default observer(Routes);
