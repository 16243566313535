import PropTypes from 'prop-types';

import * as Theme from '../../../constants/Theme';

const MaximizeIcon = (props) => {
  const { color, size } = props;

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 13 13'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='maximize-icon'
    >
      <line x1='0.5' y1='5' x2='0.5' stroke={color} />
      <line x1='-8.74228e-08' y1='0.5' x2='5' y2='0.499999' stroke={color} />
      <line x1='5' y1='12.5' x2='2.18557e-08' y2='12.5' stroke={color} />
      <line x1='0.5' y1='13' x2='0.499999' y2='8' stroke={color} />
      <line x1='12.5' y1='8' x2='12.5' y2='13' stroke={color} />
      <line x1='13' y1='12.5' x2='8' y2='12.5' stroke={color} />
      <line x1='8' y1='0.5' x2='13' y2='0.5' stroke={color} />
      <line x1='12.5' y1='-6.55671e-08' x2='12.5' y2='5' stroke={color} />
    </svg>
  );
};

MaximizeIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

MaximizeIcon.defaultProps = {
  color: Theme.light60,
  size: 24,
};

export default MaximizeIcon;
