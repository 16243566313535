import { makeAutoObservable } from 'mobx';

import baseAPI from '../../services/baseAPI';

export default class ConstantStore {
  rootStore;
  useTerms = '';
  cities = [];
  eventCategories = [];
  banks = [];
  getCitiesStatus = 'idle';

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this, { rootStore: false });
  }

  getUseTerms = async () => {
    try {
      const response = await baseAPI.get('/use-terms');

      const { status, data } = response;

      if (status !== 200 || !data) {
        return {
          error: {
            status,
          },
        };
      }

      this.useTerms = data;

      return true;
    } catch (error) {
      console.warn(error);

      return {
        error: {
          status: error?.response?.status || 400,
        },
      };
    }
  };

  getCities = async () => {
    try {
      this.getCitiesStatus = 'fetching';

      const response = await baseAPI.get('/cities');

      const { status, data } = response;

      if (status !== 200 || !data) {
        this.getCitiesStatus = 'error';

        return {
          error: {
            status,
          },
        };
      }

      this.cities = data;

      this.getCitiesStatus = 'success';

      return true;
    } catch (error) {
      console.warn(error);

      this.getCitiesStatus = 'error';

      return {
        error: {
          status: error?.response?.status || 400,
        },
      };
    }
  };

  getEventCategories = async () => {
    try {
      const response = await baseAPI.get('/event-categories');

      const { status, data } = response;

      if (status !== 200 || !data) {
        return {
          error: {
            status,
          },
        };
      }

      this.eventCategories = data;

      return true;
    } catch (error) {
      console.warn(error);

      return {
        error: {
          status: error?.response?.status || 400,
        },
      };
    }
  };

  getBanks = async () => {
    try {
      const response = await baseAPI.get('/banks');

      const { status, data } = response;

      if (status !== 200 || !data) {
        return {
          error: {
            status,
          },
        };
      }

      this.banks = data;

      return true;
    } catch (error) {
      console.warn(error);

      return {
        error: {
          status: error?.response?.status || 400,
        },
      };
    }
  };

  init = () => {
    this.getCities();
  };
}
