import PropTypes from 'prop-types';

import * as Theme from '../../../constants/Theme';

const TiktokIcon = (props) => {
  const { color } = props;

  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M38 12V28C38 33.5228 33.5228 38 28 38H12C6.47716 38 2 33.5228 2 28V12C2 6.47716 6.47716 2 12 2H28C33.5228 2 38 6.47716 38 12Z'
        stroke={color}
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16 20C12.6863 20 10 22.6862 10 26C10 29.3138 12.6863 32 16 32C19.3138 32 22 29.3138 22 26V8C22.6666 10 25.2 14 30 14'
        stroke={color}
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

TiktokIcon.propTypes = {
  color: PropTypes.string,
};

TiktokIcon.defaultProps = {
  color: Theme.light87,
};

export default TiktokIcon;
