/* eslint-disable react/prop-types */
import { TextareaAutosize } from '@material-ui/core';
import { useFormik } from 'formik';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Avatar, Button, FileUploaderModal, Input, Loader, Text } from '../../components';
import { PencilIcon, TrophyIcon } from '../../components/svg';
import {
  addressFormDefinitions,
  publicProfileFormDefinitions,
} from '../../constants/FormDefinitions';
import {
  INVALID_USERNAME,
  PROFILE_EDIT_SUCCESS,
  SYSTEM_INSTABILITY,
} from '../../constants/Messages';
import * as Theme from '../../constants/Theme';
import { useDebounce, useStore } from '../../hooks';
import { compressImage, getAddressByZipCode, removeSpecialCharacters } from '../../utils';
import ProfileFormItem from './ProfileFormItem';

const PublicProfileForm = () => {
  const store = useStore();
  const history = useHistory();

  const user = store.UserStore.publicProfile;

  const [editing, setEditing] = useState(false);
  const [requestingAddress, setRequestingAddress] = useState(false);
  const [avatarModalIsOpen, setAvatarModalIsOpen] = useState(false);
  const [avatarPreview, setAvatarPreview] = useState(null);
  const [coverPreview, setCoverPreview] = useState(null);
  const [coverModalIsOpen, setCoverModalIsOpen] = useState(false);
  const [mounted, setMounted] = useState(false);

  const getFormClassName = () => {
    let newClassName = 'profile__form surface-7';
    if (editing) newClassName += ' profile__form--editing';
    return newClassName;
  };

  const getAddress = async (zipCode) => {
    try {
      setRequestingAddress(true);

      const address = await getAddressByZipCode(zipCode);

      if (address) {
        formik.setValues((values) => ({ ...values, ...address }));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setRequestingAddress(false);
    }
  };

  const onSubmit = async (formValues) => {
    const response = await flowResult(store.UserStore.editPublicProfile(formValues));

    if (response?.error?.status) {
      toast.error(SYSTEM_INSTABILITY);
      return;
    }

    toast.success(PROFILE_EDIT_SUCCESS);

    setEditing(false);
  };

  let initialValues = {
    ...publicProfileFormDefinitions.initialValues,
    ...addressFormDefinitions.initialValues,
    ...user,
  };

  if (user?.address) initialValues = { ...initialValues, ...user.address };
  if (user?.address?.zip) {
    initialValues = { ...initialValues, zip: String(user.address.zip).replace('-', '') };
  }
  if (user?.phone && user?.phone_prefix) {
    initialValues = { ...initialValues, phone: `${user.phone_prefix}${user.phone}` };
  }
  if (user?.whatsapp && user?.whatsapp_prefix) {
    initialValues = { ...initialValues, whatsapp: `${user.whatsapp_prefix}${user.whatsapp}` };
  }

  const validationSchema = publicProfileFormDefinitions.validationSchema.concat(
    addressFormDefinitions.validationSchema,
  );

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit,
  });
  const debouncedUsername = useDebounce(formik.values.username, 1000);

  const handleAvatarChange = async (files) => {
    if (files?.length) {
      const compressedImage = await compressImage(files[0]);

      if (compressedImage) {
        formik.setFieldValue(
          'avatar',
          new File([compressedImage], compressedImage.name, {
            type: compressedImage.type,
          }),
        );
      }
    }
  };

  const handleCoverChange = async (files) => {
    if (files?.length) {
      const compressedImage = await compressImage(files[0]);

      if (compressedImage) {
        formik.setFieldValue(
          'cover',
          new File([compressedImage], compressedImage.name, {
            type: compressedImage.type,
          }),
        );
      }
    }
  };

  const validateUsername = async (username) => {
    const response = await flowResult(store.UserStore.validateUsername({ username }));

    if (response?.error) {
      formik.setFieldTouched('username', true, false);
      formik.setFieldError('username', INVALID_USERNAME);
    }
  };

  useEffect(() => {
    if (debouncedUsername?.length) validateUsername(debouncedUsername);
  }, [debouncedUsername]);

  useEffect(() => {
    if (formik.values?.zip?.length >= 8 && mounted) {
      getAddress(formik.values.zip);
    }
  }, [formik.values.zip]);

  useEffect(() => {
    if (formik.values?.avatar) {
      if (formik.values.avatar instanceof File) {
        const newAvatarPreview = URL.createObjectURL(formik.values.avatar);
        setAvatarPreview(newAvatarPreview);
        return () => {
          URL.revokeObjectURL(newAvatarPreview);
        };
      } else if (typeof formik.values.avatar === 'string') {
        setAvatarPreview(formik.values.avatar);
      }
    }
  }, [formik.values.avatar]);

  useEffect(() => {
    if (formik.values.cover) {
      if (formik.values.cover instanceof File) {
        const newCoverPreview = URL.createObjectURL(formik.values.cover);
        setCoverPreview(newCoverPreview);
        return () => {
          URL.revokeObjectURL(newCoverPreview);
        };
      } else if (typeof formik.values.cover === 'string') {
        setCoverPreview(formik.values.cover);
      }
    }
  }, [formik.values.cover]);

  useEffect(() => {
    if (formik.values?.avatar_url?.length) setAvatarPreview(formik.values.avatar_url);
    if (formik.values?.cover_url?.length) setCoverPreview(formik.values.cover_url);
    setMounted(true);
  }, []);

  return (
    <main
      style={{
        pointerEvents: formik.isSubmitting ? 'none' : 'initial',
      }}
    >
      <form className={getFormClassName()} noValidate onSubmit={formik.handleSubmit}>
        {editing ? null : (
          <div className='profile__form__actions'>
            <Button
              startIcon={<PencilIcon color={Theme.primary} />}
              onClick={() => setEditing(true)}
            >
              Editar minha página
            </Button>
          </div>
        )}

        <div className='profile__form__avatar'>
          <Button
            disabled={!editing}
            className='profile__form__avatar__action'
            onClick={() => setAvatarModalIsOpen(true)}
          >
            <Avatar size='extra-large' image={avatarPreview} />
          </Button>
          {editing ? (
            <>
              <Button
                className='profile__form__avatar__button'
                onClick={() => setAvatarModalIsOpen(true)}
              >
                <Text variant='subtitle-1' color={Theme.primary}>
                  Alterar foto de perfil
                </Text>
              </Button>
              <FileUploaderModal
                modalProps={{
                  open: avatarModalIsOpen,
                  btCancelText: 'Cancelar',
                  onCancel: () => {
                    setAvatarModalIsOpen(false);
                    // setAvatarPreview(null);
                    // formik.setFieldValue("avatar", null);
                  },
                  onConfirm: () => {
                    setAvatarModalIsOpen(false);
                  },
                  btCancelVariant: 'outlined',
                  btConfirmVariant: 'contained',
                }}
                title='Foto de perfil'
                description='Defina a sua foto de perfil.'
                preview={avatarPreview}
                onChange={handleAvatarChange}
              />
            </>
          ) : null}
        </div>

        <section className='profile__form__section banner'>
          <div className='profile__form__title'>
            <Text variant='h5' color={Theme.primary}>
              Banner da Minha Página
            </Text>
          </div>
          <div className='profile__form__description'>
            <Text variant='h6'>
              Selecione uma imagem que aparecerá no topo da Minha Página.
            </Text>
          </div>
          {coverPreview?.length ? (
            <div className='profile__form__image'>
              <img src={coverPreview} />
            </div>
          ) : null}
          {editing && (
            <Button
              variant='outlined'
              className='bt-search-photo'
              onClick={() => setCoverModalIsOpen(true)}
            >
              Alterar foto
            </Button>
          )}

          <FileUploaderModal
            modalProps={{
              open: coverModalIsOpen,
              btCancelText: 'Cancelar',
              btConfirmText: 'Definir capa',
              onCancel: () => {
                // setCoverPreview(null);
                // formik.setFieldValue("cover", null);
                setCoverModalIsOpen(false);
              },
              onConfirm: () => {
                setCoverModalIsOpen(false);
              },
              btCancelVariant: 'outlined',
              btConfirmVariant: 'contained',
            }}
            title='Defina o banner da Minha Página'
            description='Selecione uma imagem que aparecerá no topo da Minha Página.'
            preview={coverPreview}
            onChange={handleCoverChange}
          />
        </section>

        <section className='profile__form__section'>
          <div className='profile__form__title'>
            <Text variant='h5' color={Theme.primary}>
              Contato
            </Text>
          </div>

          {editing ? (
            <>
              <div className='profile__form__input'>
                <Input
                  variant='filled'
                  placeholder='Nome'
                  fullWidth
                  id='name'
                  value={formik.values.name}
                  error={formik.touched.name && !!formik.errors.name}
                  helperText={formik.touched.name && formik.errors.name}
                  onChange={formik.handleChange}
                />
              </div>
              <div className='profile__form__input'>
                <Input
                  variant='filled'
                  placeholder='Telefone'
                  fullWidth
                  id='phone'
                  value={formik.values.phone}
                  error={formik.touched.phone && !!formik.errors.phone}
                  helperText={formik.touched.phone && formik.errors.phone}
                  onChange={formik.handleChange}
                  mask='phone'
                />
              </div>
              <div className='profile__form__input'>
                <Input
                  variant='filled'
                  placeholder='WhatsApp'
                  fullWidth
                  id='whatsapp'
                  value={formik.values.whatsapp}
                  error={formik.touched.whatsapp && !!formik.errors.whatsapp}
                  helperText={formik.touched.whatsapp && formik.errors.whatsapp}
                  onChange={formik.handleChange}
                  mask='phone'
                />
              </div>
              <div className='profile__form__input'>
                <Input
                  variant='filled'
                  placeholder='E-mail comercial'
                  fullWidth
                  id='email'
                  value={formik.values.email}
                  error={formik.touched.email && !!formik.errors.email}
                  helperText={formik.touched.email && formik.errors.email}
                  onChange={formik.handleChange}
                />
              </div>
            </>
          ) : (
            <>
              <ProfileFormItem label='Nome fantasia' value={user?.name} />
              <ProfileFormItem
                label='Telefone'
                value={
                  !!user?.phone &&
                  !!user?.phone_prefix && (
                    <NumberFormat
                      format='(##) #####-####'
                      isNumericString
                      value={`${user.phone_prefix}${user.phone}`}
                      displayType='text'
                    />
                  )
                }
              />
              <ProfileFormItem
                label='WhatsApp'
                value={
                  !!user?.whatsapp &&
                  !!user?.whatsapp_prefix && (
                    <NumberFormat
                      format='(##) #####-####'
                      isNumericString
                      value={`${user.whatsapp_prefix}${user.whatsapp}`}
                      displayType='text'
                    />
                  )
                }
              />
              <ProfileFormItem label='E-mail comercial' value={user?.email} />
            </>
          )}
        </section>

        <section className='profile__form__section'>
          <div className='profile__form__title'>
            <Text variant='h5' color={Theme.primary}>
              Endereço comercial
            </Text>
          </div>

          {editing ? (
            <>
              <div className='profile__form__input'>
                <Input
                  variant='filled'
                  placeholder='CEP'
                  fullWidth
                  id='zip'
                  value={formik.values.zip}
                  error={formik.touched.zip && !!formik.errors.zip}
                  helperText={formik.touched.zip && formik.errors.zip}
                  disabled={requestingAddress}
                  onChange={formik.handleChange}
                  endAdornment={requestingAddress && <Loader />}
                  mask='cep'
                />
              </div>

              <div className='profile__form__row'>
                <div className='profile__form__input street'>
                  <Input
                    variant='filled'
                    placeholder='Rua'
                    fullWidth
                    id='street'
                    value={formik.values.street}
                    error={formik.touched.street && !!formik.errors.street}
                    helperText={formik.touched.street && formik.errors.street}
                    onChange={formik.handleChange}
                  />
                </div>
                <div className='profile__form__input number'>
                  <Input
                    variant='filled'
                    placeholder='Número'
                    fullWidth
                    id='number'
                    value={formik.values.number}
                    error={formik.touched.number && !!formik.errors.number}
                    helperText={formik.touched.number && formik.errors.number}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>

              <div className='profile__form__row'>
                <div className='profile__form__input complement'>
                  <Input
                    variant='filled'
                    placeholder='Complemento'
                    fullWidth
                    id='complement'
                    value={formik.values.complement}
                    error={formik.touched.complement && !!formik.errors.complement}
                    helperText={formik.touched.complement && formik.errors.complement}
                    onChange={formik.handleChange}
                  />
                </div>
                <div className='profile__form__input district'>
                  <Input
                    variant='filled'
                    placeholder='Bairro'
                    fullWidth
                    id='district'
                    value={formik.values.district}
                    error={formik.touched.district && !!formik.errors.district}
                    helperText={formik.touched.district && formik.errors.district}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>

              <div className='profile__form__row'>
                <div className='profile__form__input city'>
                  <Input
                    variant='filled'
                    placeholder='Cidade'
                    fullWidth
                    id='city'
                    value={formik.values.city}
                    error={formik.touched.city && !!formik.errors.city}
                    helperText={formik.touched.city && formik.errors.city}
                    onChange={formik.handleChange}
                  />
                </div>
                <div className='profile__form__input state'>
                  <Input
                    variant='filled'
                    placeholder='Estado'
                    fullWidth
                    id='state'
                    value={formik.values.state}
                    error={formik.touched.state && !!formik.errors.state}
                    helperText={formik.touched.state && formik.errors.state}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <ProfileFormItem label='CEP' value={user?.address?.zip} />
              <ProfileFormItem label='Rua' value={user?.address?.street} />
              <ProfileFormItem label='Número' value={user?.address?.number} />
              <ProfileFormItem label='Complemento' value={user?.address?.complement} />
              <ProfileFormItem label='Bairro' value={user?.address?.district} />
              <ProfileFormItem label='Cidade' value={user?.address?.city} />
              <ProfileFormItem label='Estado' value={user?.address?.state} />
            </>
          )}
        </section>

        <section className='profile__form__section bio'>
          <div className='profile__form__title'>
            <Text variant='h5' color={Theme.primary}>
              Biografia
            </Text>
          </div>
          <div className='profile__form__description'>
            <Text variant='h6'>Conte um pouco sobre a sua história com a fotográfia.</Text>
          </div>
          <div className='profile__form__text'>
            {editing ? (
              <TextareaAutosize
                className='input input--filled'
                variant='filled'
                placeholder='Biografia'
                multiline={1}
                id='bio'
                minRows={3}
                value={formik.values.bio || ''}
                // error={formik.touched.bio && !!formik.errors.bio}
                // helperText={formik.touched.bio && formik.errors.bio}
                onChange={({ target }) => {
                  if (target?.value && target?.value?.length <= 255) {
                    formik.setFieldValue('bio', target.value);
                  }
                }}
              />
            ) : (
              <Text variant='body-1' color={Theme.light87}>
                {user?.bio?.length ? user.bio : ''}
              </Text>
            )}
          </div>
        </section>

        <section className='profile__form__section social'>
          <div className='profile__form__title'>
            <Text variant='h5' color={Theme.primary}>
              Minhas Redes Sociais
            </Text>
          </div>
          <div className='profile__form__description'>
            <Text variant='h6'>Adicione o link do perfil das suas redes sociais.</Text>
          </div>

          {editing ? (
            <>
              <div className='profile__form__input'>
                <Input
                  variant='filled'
                  placeholder='Behance'
                  fullWidth
                  id='behance_url'
                  value={formik.values.behance_url}
                  error={formik.touched.behance_url && !!formik.errors.behance_url}
                  helperText={formik.touched.behance_url && formik.errors.behance_url}
                  onChange={formik.handleChange}
                />
              </div>
              <div className='profile__form__input'>
                <Input
                  variant='filled'
                  placeholder='Facebook'
                  fullWidth
                  id='facebook_url'
                  value={formik.values.facebook_url}
                  error={formik.touched.facebook_url && !!formik.errors.facebook_url}
                  helperText={formik.touched.facebook_url && formik.errors.facebook_url}
                  onChange={formik.handleChange}
                />
              </div>
              <div className='profile__form__input'>
                <Input
                  variant='filled'
                  placeholder='Instagram'
                  fullWidth
                  id='instagram_url'
                  value={formik.values.instagram_url}
                  error={formik.touched.instagram_url && !!formik.errors.instagram_url}
                  helperText={formik.touched.instagram_url && formik.errors.instagram_url}
                  onChange={formik.handleChange}
                />
              </div>
              <div className='profile__form__input'>
                <Input
                  variant='filled'
                  placeholder='TikTok'
                  fullWidth
                  id='tiktok_url'
                  value={formik.values.tiktok_url}
                  error={formik.touched.tiktok_url && !!formik.errors.tiktok_url}
                  helperText={formik.touched.tiktok_url && formik.errors.tiktok_url}
                  onChange={formik.handleChange}
                />
              </div>
              <div className='profile__form__input'>
                <Input
                  variant='filled'
                  placeholder='YouTube'
                  fullWidth
                  id='youtube_url'
                  value={formik.values.youtube_url}
                  error={formik.touched.youtube_url && !!formik.errors.youtube_url}
                  helperText={formik.touched.youtube_url && formik.errors.youtube_url}
                  onChange={formik.handleChange}
                />
              </div>
            </>
          ) : (
            <>
              <ProfileFormItem label='Behance' value={user?.behance_url} />
              <ProfileFormItem label='Facebook' value={user?.facebook_url} />
              <ProfileFormItem label='Instagram' value={user?.instagram_url} />
              <ProfileFormItem label='TikTok' value={user?.tiktok_url} />
              <ProfileFormItem label='YouTube' value={user?.youtube_url} />
            </>
          )}
        </section>

        <section className='profile__form__section url'>
          <div className='profile__form__title'>
            <Text variant='h5' color={Theme.primary}>
              URL na plataforma Rokkos
            </Text>
          </div>
          <div className='profile__form__description'>
            <Text variant='h6'>Crie sua URL personalizada.</Text>
          </div>
          {editing ? (
            <>
              <Input
                variant='filled'
                placeholder='username'
                fullWidth
                id='username'
                value={formik.values.username}
                error={formik.touched.username && !!formik.errors.username}
                helperText={formik.touched.username && formik.errors.username}
                onChange={(event) => {
                  formik.setFieldValue(
                    'username',
                    removeSpecialCharacters(event.target.value).toLowerCase(),
                    false,
                  );
                }}
              />
              <Text variant='h6' className='preview'>
                {`Sua URL ficará assim: ${window.location.origin}/photographer/${
                  formik.values?.username?.length
                    ? formik.values.username.toLowerCase()
                    : 'username'
                }`}
              </Text>
            </>
          ) : (
            <ProfileFormItem label='Rokkos' value={user?.username} />
          )}

          {!editing && user?.username?.length && (
            <Button
              variant='contained'
              onClick={() => history.push(`/photographer/${user.username}`)}
            >
              Visualizar minha Página
            </Button>
          )}
        </section>

        {editing && (
          <section>
            <Button
              variant='contained'
              type='submit'
              className='profile__form__bt-submit'
              loading={formik.isSubmitting}
            >
              Salvar Alterações
            </Button>
          </section>
        )}

        <section className='profile__form__section certifications'>
          <div className='profile__form__title'>
            <Text variant='h5' color={Theme.primary}>
              Certificações Rokkos
            </Text>
          </div>

          <div className='certifications__icon'>{<TrophyIcon />}</div>

          <div className='certifications__link'>
            <Text variant='h6'>
              Para saber como conquistar sua certificação Rokkos acesse este{' '}
              <a href='https://rokkos.club/certificacao/' target='_blank' rel='noreferrer'>
                link
              </a>
              .
            </Text>
          </div>
        </section>
      </form>
    </main>
  );
};
export default observer(PublicProfileForm);
