import AlbumStore from './AlbumStore';
import AuthStore from './AuthStore';
import CheckoutStore from './CheckoutStore';
import ConstantStore from './ConstantStore';
import EventStore from './EventStore';
import PhotoStore from './PhotoStore';
import UploadStore from './UploadStore';
import UserStore from './UserStore';

export default class RootStore {
  /** @type AlbumStore */
  AlbumStore = {};

  /** @type AuthStore */
  AuthStore = {};

  /** @type CheckoutStore */
  CheckoutStore = {};

  /** @type ConstantStore */
  ConstantStore = {};

  /** @type EventStore */
  EventStore = {};

  /** @type PhotoStore */
  PhotoStore = {};

  /** @type UploadStore */
  UploadStore = {};

  /** @type UserStore */
  UserStore = {};

  constructor() {
    this.AlbumStore = new AlbumStore(this);
    this.AuthStore = new AuthStore(this);
    this.CheckoutStore = new CheckoutStore(this);
    this.EventStore = new EventStore(this);
    this.PhotoStore = new PhotoStore(this);
    this.ConstantStore = new ConstantStore(this);
    this.UploadStore = new UploadStore(this);
    this.UserStore = new UserStore(this);

    this.AuthStore.init();
    this.ConstantStore.init();
  }
}
