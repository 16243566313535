import { flowResult } from 'mobx';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';

import { EventForm, Screen, Text } from '../../components';
import { eventFormDefinitions } from '../../constants/FormDefinitions';
import { EVENT_CREATED, SYSTEM_INSTABILITY } from '../../constants/Messages';
import { useStore } from '../../hooks';
import './styles.scss';

const CreateEventScreen = () => {
  const store = useStore();
  const history = useHistory();
  const { initialValues, validationSchema } = eventFormDefinitions;

  const onSubmit = async (formValues) => {
    try {
      const response = await flowResult(store.EventStore.create(formValues));

      if (response?.error) {
        switch (response?.error?.status) {
          default:
            toast.error(SYSTEM_INSTABILITY);
            break;
        }
        return;
      }

      toast.success(EVENT_CREATED);

      history.push(`/event/${response.id}`);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // if (!store.UserStore.profile?.bank_data?.validated) history.push("/");
  }, []);

  return (
    <Screen className='create-event'>
      <div className='create-event__header'>
        <Text variant='h5' responsive className='create-event__title'>
          Criar Evento
        </Text>
        <Text variant='h6' responsive className='create-event__description'>
          Crie o evento aonde você tirou/tirará as suas fotos para publicá-las.
        </Text>
      </div>

      <main>
        <EventForm
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        />
      </main>
    </Screen>
  );
};

export default observer(CreateEventScreen);
