import PropTypes from 'prop-types';

import { Avatar, Text } from '..';
import coverPlaceholder from '../../assets/images/event-cover-placeholder.png';
import * as Theme from '../../constants/Theme';
import './styles.scss';

const PublicProfileEventCard = (props) => {
  const { event, onClick } = props;

  if (!event) return null;

  return (
    <div className='public-profile-event-card'>
      <div className='public-profile-event-card__image' onClick={onClick}>
        <img src={event.cover_url?.length ? event.cover_url : coverPlaceholder} />
      </div>
      <div className='public-profile-event-card__content' onClick={onClick}>
        <div className='public-profile-event-card__content__avatar'>
          <Avatar image={event.user.avatar_url} size='medium' />
          <div className='public-profile-event-card__text'>
            <div className='public-profile-event-card__name'>
              <Text variant='h6'>{event.name}</Text>
            </div>
            <div className='public-profile-event-card__photo-count'>
              {!!event?.photo_count && (
                <Text variant='body-2' color={Theme.light60}>{`${event.photo_count} ${
                  event?.photo_count > 1 ? ' fotos' : ' foto'
                }`}</Text>
              )}
              <div className='public-profile-event-card__content__date'>
                <Text variant='body-2' color={Theme.light60}>
                  {event.date}
                </Text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

PublicProfileEventCard.propTypes = {
  event: PropTypes.shape({
    cover_url: PropTypes.string,
    name: PropTypes.string,
    date: PropTypes.string,
    user: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      avatar_url: PropTypes.string,
    }),
    photo_count: PropTypes.number,
  }).isRequired,
  onClick: PropTypes.func,
};

PublicProfileEventCard.defaultProps = {
  onClick: () => '',
};
export default PublicProfileEventCard;
