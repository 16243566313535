import { Backdrop, Fade, Modal } from '@material-ui/core';
import PropTypes from 'prop-types';

import { Text } from '..';
import './styles.scss';

const CheckoutLoader = (props) => {
  const { open } = props;

  return (
    <Modal
      className='checkout-loader'
      open={open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 250,
      }}
    >
      <Fade in={open}>
        <div>
          <div className='checkout-loader__image'>
            <svg width='248' height='189' viewBox='0 0 248 189' fill='none'>
              <g clipPath='url(#clip0_2755_28707)'>
                <path
                  d='M188.952 35.4375H233.238C241.381 35.4375 248 42.0643 248 50.2031V156.516C248 164.654 241.381 171.281 233.238 171.281H191.905C191.122 171.281 190.371 170.97 189.817 170.416C189.263 169.862 188.952 169.111 188.952 168.328C188.952 167.545 189.263 166.794 189.817 166.24C190.371 165.686 191.122 165.375 191.905 165.375H233.238C238.121 165.375 242.095 161.4 242.095 156.516V82.6875H195C193.5 80 192.814 78 192.148 76.7812H193.381H242.095V50.2031C242.095 45.3187 238.121 41.3438 233.238 41.3438H188.952C174.94 41.3438 171.238 29.7557 171.238 23.625V17.7188C171.238 13.7261 165.888 5.90625 153.524 5.90625H94.6592C81.6629 5.90625 76.945 12.9701 76.945 17.7188V23.625C76.8741 29.7793 73.0301 41.3438 59.0476 41.3438H14.7619C9.87867 41.3438 5.90476 45.3187 5.90476 50.2031V76.7812L56.5 76.5H57L55.5 79.5L54 82.6875H53.0758H5.90476V156.516C5.90476 161.4 9.87867 165.375 14.7619 165.375H50.1905C50.9735 165.375 51.7244 165.686 52.2781 166.24C52.8318 166.794 53.1429 167.545 53.1429 168.328C53.1429 169.111 52.8318 169.862 52.2781 170.416C51.7244 170.97 50.9735 171.281 50.1905 171.281H14.7619C6.61924 171.281 0 164.654 0 156.516V50.2031C0.00468796 46.2885 1.56146 42.5355 4.32884 39.7674C7.09622 36.9994 10.8482 35.4422 14.7619 35.4375H59.0476C70.7568 35.4375 71.0343 24.0739 71.0402 23.5896V17.7188C71.0402 10.5958 77.3288 0 94.6592 0H153.524C168.935 0 177.143 10.2887 177.143 17.7188V23.625C177.155 24.824 177.574 35.4375 188.952 35.4375Z'
                  fill='white'
                  fillOpacity='0.6'
                />
                <path
                  d='M56.0953 29.5312C56.8783 29.5312 57.6293 29.2201 58.183 28.6663C58.7366 28.1125 59.0477 27.3613 59.0477 26.5781V20.6719C59.0477 15.7874 55.0738 11.8125 50.1905 11.8125H26.5715C21.6883 11.8125 17.7144 15.7874 17.7144 20.6719V26.5781C17.7144 27.3613 18.0254 28.1125 18.5791 28.6663C19.1328 29.2201 19.8837 29.5312 20.6667 29.5312C21.4498 29.5312 22.2007 29.2201 22.7544 28.6663C23.3081 28.1125 23.6191 27.3613 23.6191 26.5781V20.6719C23.6191 19.8887 23.9302 19.1375 24.4839 18.5837C25.0375 18.0299 25.7885 17.7187 26.5715 17.7188H50.1905C50.9736 17.7188 51.7245 18.0299 52.2782 18.5837C52.8319 19.1375 53.1429 19.8887 53.1429 20.6719V26.5781C53.1429 27.3613 53.454 28.1125 54.0077 28.6663C54.5613 29.2201 55.3123 29.5312 56.0953 29.5312Z'
                  fill='white'
                  fillOpacity='0.6'
                />
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M124.5 69C100.475 69 80.9995 88.4756 80.9995 112.5C80.9995 136.524 100.475 156 124.5 156C148.524 156 168 136.524 168 112.5C168 88.4756 148.524 69 124.5 69ZM124.499 80.9998C107.102 80.9998 92.9992 95.1028 92.9992 112.5C92.9992 129.897 107.102 144 124.499 144C141.896 144 155.999 129.897 155.999 112.5C155.999 95.1028 141.896 80.9998 124.499 80.9998Z'
                  fill='white'
                  fillOpacity='0.6'
                />
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M139.177 106.353C135.765 106.353 133 103.588 133 100.176C133 96.7653 135.765 94 139.177 94C142.588 94 145.353 96.7653 145.353 100.176C145.353 103.588 142.588 106.353 139.177 106.353Z'
                  fill='white'
                  fillOpacity='0.6'
                />
              </g>
            </svg>
            <svg
              width='154'
              height='154'
              viewBox='0 0 100 100'
              preserveAspectRatio='xMidYMid'
              className='checkout-loader__spinner'
            >
              <circle
                cx='50'
                cy='50'
                fill='none'
                stroke='rgba(255, 255, 255, 0.6)'
                strokeWidth='8'
                r='42'
                strokeDasharray='197.92033717615698 67.97344572538566'
              >
                <animateTransform
                  attributeName='transform'
                  type='rotate'
                  repeatCount='indefinite'
                  dur='1s'
                  values='0 50 50;360 50 50'
                  keyTimes='0;1'
                ></animateTransform>
              </circle>
            </svg>
          </div>
          <div className='checkout-loader__text'>
            <Text variant='h5' color='white'>
              Gerando pedido...
            </Text>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

CheckoutLoader.propTypes = {
  open: PropTypes.bool,
};

CheckoutLoader.defaultProps = {
  open: false,
};

export default CheckoutLoader;
