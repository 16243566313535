import { flowResult } from 'mobx';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { toast } from 'react-toastify';

import { Button, Modal, PhotoModal, Text } from '..';
import { REMOVE_PHOTO_FROM_CART_SUCCESS, SYSTEM_INSTABILITY } from '../../constants/Messages';
import * as Theme from '../../constants/Theme';
import { useStore } from '../../hooks';
import { DownloadIcon, TrashIcon } from '../svg';
import './styles.scss';

const CartListItem = (props) => {
  const { photo, variant, showBtRemove } = props;
  const store = useStore();
  const [photoModalIsOpen, setPhotoModalIsOpen] = useState(false);
  const [removePhotoModalIsOpen, setRemovePhotoModalIsOpen] = useState(false);
  const [requesting, setRequesting] = useState(false);

  const { id, thumbnail_url, owner, width, height, price, date } = photo;

  const cartListItemClassName = () => {
    let newClassName = 'cart-list-item';

    if (variant) newClassName += ` cart-list-item--${variant}`;

    return newClassName;
  };

  const removeItem = async () => {
    try {
      const response = await store.CheckoutStore.removeItem(photo);

      if (response.error) {
        toast.error(SYSTEM_INSTABILITY);
        return;
      }

      toast.success(REMOVE_PHOTO_FROM_CART_SUCCESS);
    } catch (error) {
      console.log(error);
    } finally {
      setRemovePhotoModalIsOpen(false);
      setPhotoModalIsOpen();
    }
  };

  const downloadPhoto = async () => {
    try {
      setRequesting(true);
      const response = await flowResult(store.PhotoStore.download({ id }));

      if (response.error) toast.error(SYSTEM_INSTABILITY);
    } catch (error) {
      console.log(error);
    } finally {
      setRequesting(false);
    }
  };

  if (!photo) return null;

  return (
    <div className={cartListItemClassName()}>
      <div className='cart-list-item__photo'>
        {!!thumbnail_url?.length && (
          <>
            <img src={thumbnail_url} onClick={() => setPhotoModalIsOpen(true)} />
            <PhotoModal
              modalProps={{
                open: photoModalIsOpen,
                onCancel: () => {
                  setPhotoModalIsOpen(false);
                },
                showBtCancel: false,
                showBtConfirm: false,
                showBtClose: true,
              }}
              photo={photo}
              variant='photographer'
              showBtRemove={false}
              onClickAction={() => setRemovePhotoModalIsOpen(true)}
            />
            <Modal
              open={removePhotoModalIsOpen}
              btConfirmText='Retirar'
              btCancelColor='light'
              onCancel={() => setRemovePhotoModalIsOpen(false)}
              onConfirm={removeItem}
            >
              <Text variant='subtitle-1' color={Theme.light87}>
                Retirar foto do carrinho?
              </Text>
            </Modal>
          </>
        )}
      </div>
      <div className='cart-list-item__content'>
        <div className='cart-list-item__id'>
          <Text variant='h6'>{variant === 'download' ? date : id}</Text>
        </div>
        <div className='cart-list-item__owner'>
          {!!owner && <Text variant='h6'>{owner.name}</Text>}
        </div>
        <div className='cart-list-item__resolution'>
          {width && height && <Text variant='h6'>{`${width}x${height}px`}</Text>}
        </div>
        <div className='cart-list-item__bottom'>
          {variant === 'download' ? (
            <div className='cart-list-item__download'>
              <Button
                variant='contained'
                startIcon={<DownloadIcon color='#000' />}
                onClick={downloadPhoto}
                loading={requesting}
              >
                Baixar foto
              </Button>
            </div>
          ) : (
            <div className='cart-list-item__price'>
              {!!price && (
                <Text variant={variant === 'order' ? 'h4' : 'h5'}>
                  {`R$ ${String(price.toFixed(2)).replace('.', ',')}`}
                </Text>
              )}
            </div>
          )}
          {!!showBtRemove && (
            <div className='cart-list-item__action'>
              <Button
                className='cart-list-item__action__bt-remove'
                onClick={() => setRemovePhotoModalIsOpen(true)}
              >
                <TrashIcon color={Theme.light87} />
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

CartListItem.propTypes = {
  variant: PropTypes.oneOf(['cart', 'order', 'download']),
  photo: PropTypes.shape({
    id: PropTypes.number,
    thumbnail_url: PropTypes.string,
    preview_url: PropTypes.string,
    price: PropTypes.number,
    event: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    owner: PropTypes.shape({
      name: PropTypes.string,
      avatar_url: PropTypes.string,
    }),
    width: PropTypes.number,
    height: PropTypes.number,
    date: PropTypes.string,
  }).isRequired,
  requesting: PropTypes.bool,
  showBtRemove: PropTypes.bool,
};

CartListItem.defaultProps = {
  showBtRemove: true,
  variant: 'cart',
};

export default observer(CartListItem);
