import PropTypes from 'prop-types';
import { Fade } from 'react-reveal';

import { Text } from '..';
import './styles.scss';

const PublicProfileCategories = (props) => {
  const { categories } = props;

  if (!categories?.length) return null;

  return (
    <section className='public-profile__categories'>
      <Fade bottom distance='10px'>
        <Text className='public-profile__categories__title' variant='h5' responsive>
          CATEGORIAS
        </Text>
      </Fade>

      <Fade bottom distance='10px'>
        <div className='public-profile__categories__list'>
          {categories.map(({ id, icon, name }, index) => (
            <Fade bottom distance='10px' delay={(index + 1) * 100} key={id}>
              <div key={id} className='category'>
                <div className='category__image'>
                  <img src={icon} />
                </div>
                <div className='category__name'>
                  <Text variant='h6' responsive>
                    {name}
                  </Text>
                </div>
              </div>
            </Fade>
          ))}
        </div>
      </Fade>
    </section>
  );
};

PublicProfileCategories.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      image_url: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
};

export default PublicProfileCategories;
