/* eslint-disable no-undef */
import Axios from 'axios';

const baseURL = process.env.REACT_APP_PAYMENT_GATEWAY_API_URL;
const publicToken = process.env.REACT_APP_PROD
  ? process.env.REACT_APP_PAYMENT_GATEWAY_API_KEY
  : process.env.REACT_APP_PAYMENT_GATEWAY_API_TEST_KEY;
const pagarmeApi = Axios.create({
  baseURL,
  timeout: 40000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  validateStatus: (status) => status !== 401,
});

pagarmeApi.interceptors.request.use(
  (config) => {
    config.url = `${config.url}?appId=${publicToken}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

export default pagarmeApi;
