import { Container } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  BottomBar,
  Button,
  Card,
  EmptyState,
  EventHeader,
  FileUploader,
  FileUploaderModal,
  ListLoader,
  Loader,
  PhotoGrid,
  PhotoTableCondensed,
  Screen,
  Text,
} from '../../components';
import { EmptyStatePhotoSearchIcon, GridIcon, ListIcon, PlusIcon } from '../../components/svg';
import { SYSTEM_INSTABILITY } from '../../constants/Messages';
import * as Theme from '../../constants/Theme';
import { useStore } from '../../hooks';
import './styles.scss';

const EventDetailScreen = () => {
  const history = useHistory();
  const store = useStore();
  const params = useParams();

  const user = store.UserStore.profile;
  const event = store.EventStore.detail;
  const albums = store.AlbumStore.list;
  const photos = store.PhotoStore.list;

  const [mounted, setMounted] = useState(false);
  const [requestingDetails, setRequestingDetails] = useState(true);
  const [requestingList, setRequestingList] = useState(true);
  const [uploadModalIsOpen, setUploadModalIsOpen] = useState(false);
  const [listType, setListType] = useState('grid');
  const [page, setPage] = useState(1);

  const getEventDetail = async () => {
    try {
      setRequestingDetails(true);

      const response = await flowResult(store.EventStore.getDetail({ id: params.id }));

      if (response?.error) {
        switch (response?.error?.status) {
          default: {
            history.push('/404');
            return;
          }
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setRequestingDetails(false);
    }
  };

  const getEventDetailByAccessCode = async () => {
    try {
      setRequestingDetails(true);

      const response = await flowResult(
        store.EventStore.getDetailByAccessCode({
          access_code: params.accessCode,
        }),
      );
      if (response?.error) {
        switch (response?.error?.status) {
          default: {
            history.push('/event-not-found');
            return;
          }
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setRequestingDetails(false);
    }
  };

  const getAlbums = async () => {
    try {
      setRequestingList(true);

      const response = await flowResult(
        store.AlbumStore.getList({
          event_id: event.id,
          page,
        }),
      );

      if (response.error) toast.error(SYSTEM_INSTABILITY);
    } catch (error) {
      console.log(error);
    } finally {
      setRequestingList(false);
    }
  };

  const getPhotos = async () => {
    try {
      setRequestingList(true);

      const response = await flowResult(
        store.PhotoStore.getList({
          event_id: event.id,
          album_id: albums?.length ? albums[0].id : null,
          page,
          per_page: 50,
        }),
      );

      if (response.error) toast.error(SYSTEM_INSTABILITY);
    } catch (error) {
      console.log(error);
    } finally {
      setRequestingList(false);
    }
  };

  const feedUploadQueue = async (newFiles) => {
    const response = store.UploadStore.feedQueue({
      newFiles,
      eventId: event.id,
    });

    setUploadModalIsOpen(false);

    if (response.error) toast.error('Erro ao enviar arquivos para a fila');
  };

  const renderPhotoGrid = () => {
    if (requestingList) {
      return <ListLoader variant='grid' length={16} interval={300} />;
    }

    if (mounted && !requestingList && !photos?.length) {
      if (user?.type === 2) {
        if (event?.restricted_photos) {
          return (
            <EmptyState
              icon={<EmptyStatePhotoSearchIcon />}
              title='Nenhuma foto sua foi encontrada neste evento.'
              description='Conheça outros eventos da Rokkos!'
              button={
                <Button variant='contained' onClick={() => history.push('/events/all')}>
                  Conferir eventos
                </Button>
              }
            />
          );
        }

        return (
          <EmptyState
            icon={<EmptyStatePhotoSearchIcon />}
            title='Este evento ainda não tem fotos.'
            description='Conheça outros eventos da Rokkos!'
            button={
              <Button variant='contained' onClick={() => history.push('/events/all')}>
                Conferir eventos
              </Button>
            }
          />
        );
      } else if (user?.type === 1 && !event?.user?.authorized) {
        return (
          <EmptyState
            icon={<EmptyStatePhotoSearchIcon />}
            title='Este evento ainda não tem fotos.'
            description='Conheça outros eventos da Rokkos!'
            button={
              <Button variant='contained' onClick={() => history.push('/events/all')}>
                Conferir eventos
              </Button>
            }
          />
        );
      }

      return (
        <FileUploader
          multiple
          description='Arraste suas fotos aqui para compartilhar com seus clientes.'
          uploaderDescription='Você não precisa redimensionar suas fotos, nós cuidaremos de tudo. Também iremos fazer miniaturas e afixar marca d’água automaticamente para mostrá-las de forma segura.'
          onChange={feedUploadQueue}
        />
      );
    }

    return (
      <PhotoGrid
        variant={user?.type === 1 ? 'photographer' : 'shopper'}
        photos={photos}
        showActionButton={user?.type === 2 || event?.user?.authorized}
        onDeletePhoto={onDeletePhoto}
      />
    );
  };
  const renderPhotoTableCondensed = () => {
    if (requestingList) {
      return <ListLoader variant='grid' length={16} interval={300} />;
    }

    if (mounted && !requestingList && !photos?.length) {
      if (user?.type === 2) {
        if (event?.restricted_photos) {
          return (
            <EmptyState
              icon={<EmptyStatePhotoSearchIcon />}
              title='Nenhuma foto sua foi encontrada neste evento.'
              description='Conheça outros eventos da Rokkos!'
              button={
                <Button variant='contained' onClick={() => history.push('/events/all')}>
                  Conferir eventos
                </Button>
              }
            />
          );
        }

        return (
          <EmptyState
            icon={<EmptyStatePhotoSearchIcon />}
            title='Este evento ainda não tem fotos.'
            description='Conheça outros eventos da Rokkos!'
            button={
              <Button variant='contained' onClick={() => history.push('/events/all')}>
                Conferir eventos
              </Button>
            }
          />
        );
      } else if (user?.type === 1 && !event?.user?.authorized) {
        return (
          <EmptyState
            icon={<EmptyStatePhotoSearchIcon />}
            title='Este evento ainda não tem fotos.'
            description='Conheça outros eventos da Rokkos!'
            button={
              <Button variant='contained' onClick={() => history.push('/events/all')}>
                Conferir eventos
              </Button>
            }
          />
        );
      }

      return (
        <FileUploader
          multiple
          description='Arraste suas fotos aqui para compartilhar com seus clientes.'
          uploaderDescription='Você não precisa redimensionar suas fotos, nós cuidaremos de tudo. Também iremos fazer miniaturas e afixar marca d’água automaticamente para mostrá-las de forma segura.'
          onChange={feedUploadQueue}
        />
      );
    }

    return (
      <PhotoTableCondensed
        variant={user?.type === 1 ? 'photographer' : 'shopper'}
        photos={photos}
        showActionButton={user?.type === 2 || event?.user?.authorized}
        onDeletePhoto={onDeletePhoto}
      />
    );
  };

  const renderAlbums = () => {
    if (requestingList) {
      return <Loader color={Theme.primary} />;
    }

    if (mounted && !requestingList && !albums?.length) {
      if (!event?.user?.authorized) {
        return (
          <EmptyState
            icon={<EmptyStatePhotoSearchIcon />}
            title='Este evento ainda não tem fotos.'
            description='Conheça outros eventos da Rokkos!'
            button={
              <Button variant='contained' onClick={() => history.push('/events/all')}>
                Conferir eventos
              </Button>
            }
          />
        );
      }

      return (
        <FileUploader
          multiple
          description='Arraste suas fotos aqui para compartilhar com seus clientes.'
          uploaderDescription='Você não precisa redimensionar suas fotos, nós cuidaremos de tudo. Também iremos fazer miniaturas e afixar marca d’água automaticamente para mostrá-las de forma segura.'
          onChange={feedUploadQueue}
        />
      );
    }

    return (
      <div className='album-list'>
        {albums.map((album) => (
          <Card
            varian='album'
            album={album}
            key={album.id}
            onClick={() => history.push(`/event/${event.id}/${album.id}`)}
          />
        ))}
        {!albums.find((album) => album.owner.id === user.id) ? (
          <div className='album-list__placeholder'>
            <Text variant='h6' color={Theme.light60}>
              Seu album aparecerá aqui
            </Text>
          </div>
        ) : null}
      </div>
    );
  };

  const renderEventContent = () => {
    if (event?.collaborative && user?.type === 1) return renderAlbums();

    if (listType === 'list_condensed') return renderPhotoTableCondensed();

    return renderPhotoGrid();
  };

  const renderPagination = () => {
    if (
      !mounted ||
      !event?.photo_count ||
      (!event?.collaborative && store.PhotoStore.meta?.last_page <= 1) ||
      (event?.collaborative && user?.type === 1 && store.AlbumStore.meta?.last_page <= 1) ||
      (event?.collaborative && user?.type === 2 && store.PhotoStore.meta?.last_page <= 1)
    ) {
      return null;
    }

    return (
      <Pagination
        page={page}
        count={
          event?.collaborative && user?.type === 1
            ? store.AlbumStore.meta.last_page
            : store.PhotoStore.meta.last_page
        }
        color='primary'
        onChange={(_, page) => setPage(page)}
        disabled={requestingList}
      />
    );
  };

  const renderBottomBar = () => {
    if (user?.type !== 1 || !event?.user?.authorized || !albums?.length) {
      return null;
    }

    return (
      <BottomBar>
        <FileUploaderModal
          modalProps={{
            open: uploadModalIsOpen,
            showBtConfirm: false,
            showBtCancel: false,
            showBtClose: true,
            onCancel: () => setUploadModalIsOpen(false),
          }}
          title='Adicionar mais fotos'
          description='Arraste suas fotos aqui para compartilhar com seus clientes.'
          uploaderDescription='Você não precisa redimensionar suas fotos, nós cuidaremos de tudo. Também iremos fazer miniaturas e afixar marca d’água automaticamente para mostrá-las de forma segura.'
          multiple
          onChange={feedUploadQueue}
        />
        <Button
          startIcon={<PlusIcon color={Theme.primary} />}
          variant='outlined'
          onClick={() => setUploadModalIsOpen(true)}
        >
          Adicionar fotos
        </Button>
      </BottomBar>
    );
  };

  const onDeletePhoto = () => {
    setPage(1);
    getPhotos();
  };

  const init = async () => {
    setPage(1);

    params?.accessCode ? await getEventDetailByAccessCode() : await getEventDetail();

    if (user?.type === 1) {
      setListType('list_condensed');
    }

    setMounted(true);
  };

  useEffect(() => {
    if (!event || !mounted || user?.type === 2) return;
    getAlbums();
  }, [event, mounted]);

  useEffect(() => {
    if (
      !event?.photo_count ||
      (!albums?.length && user?.type === 1) ||
      (event?.collaborative && user?.type === 1)
    ) {
      return;
    }

    getPhotos();
  }, [event, albums]);

  useEffect(() => {
    if (!mounted) return;

    if (event) {
      event.collaborative && user?.type === 1 ? getAlbums() : getPhotos();
    }

    if (window.innerWidth > 1024) {
      if (event.public_event && event.access_code?.length) {
        window.scrollTo(0, 338);
        return;
      }

      window.scrollTo(0, 248);
      return;
    }

    window.scrollTo(0, 368);
  }, [page]);

  useEffect(() => {
    init();
  }, []);

  return (
    <Screen className='event-detail' container={false}>
      {requestingDetails || !event ? (
        <Loader color={Theme.primary} paddingVertical={32} />
      ) : (
        <>
          <EventHeader
            variant={user?.type === 1 ? 'photographer' : 'shopper'}
            requesting={requestingDetails || requestingList}
            onClickEditEvent={() => history.push(`/event/${event.id}/edit`)}
          />
          <Container maxWidth={Theme.containerMaxWidth}>
            {photos?.length && event?.user?.authorized && user?.type === 1 ? (
              <div className='event-detail__list-type'>
                <div className='event-detail__list-type__label'>
                  <Text variant='h6' color={Theme.primary}>
                    Visualizar:
                  </Text>
                </div>
                <Button
                  className='event-detail__list-type__button'
                  onClick={() => setListType('list_condensed')}
                  title='Lista Condensada'
                >
                  <ListIcon
                    color={listType === 'list_condensed' ? Theme.light87 : Theme.light60}
                  />
                </Button>
                <Button
                  className='event-detail__list-type__button'
                  onClick={() => setListType('grid')}
                  title='Grid'
                >
                  <GridIcon color={listType === 'grid' ? Theme.light87 : Theme.light60} />
                </Button>
              </div>
            ) : null}
            {renderEventContent()}
            {renderPagination()}
            {renderBottomBar()}
          </Container>
        </>
      )}
    </Screen>
  );
};

export default observer(EventDetailScreen);
