import PropTypes from 'prop-types';

import * as Theme from '../../../constants/Theme';

const EmptyStateSearchIcon = (props) => {
  const { color } = props;

  return (
    <svg
      width='233'
      height='219'
      viewBox='0 0 233 219'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M86.5312 1C47.0869 1 15 33.0869 15 72.5312C15 111.976 47.0869 144.062 86.5312 144.062C105.095 144.062 121.977 136.896 134.702 125.26C134.852 125.526 134.989 125.805 135.22 126.037L149.874 140.69C148.316 143.277 147.673 146.313 148.049 149.309C148.425 152.305 149.797 155.088 151.945 157.211L209.749 215.015C212.317 217.583 215.737 219 219.375 219C223.013 219 226.433 217.583 229.008 215.008C231.583 212.433 233 209.013 233 205.375C233 201.737 231.583 198.317 229.008 195.742L171.204 137.938C166.885 133.619 159.902 132.972 154.718 135.901L140.037 121.22C139.805 120.989 139.533 120.852 139.26 120.702C151.345 107.57 158.056 90.3777 158.062 72.5312C158.062 33.0869 125.976 1 86.5312 1ZM166.387 142.755L224.191 200.559C225.479 201.846 226.188 203.556 226.188 205.375C226.188 207.194 225.479 208.904 224.191 210.191C222.893 211.428 221.168 212.118 219.375 212.118C217.582 212.118 215.857 211.428 214.559 210.191L156.755 152.387C156.12 151.756 155.616 151.006 155.272 150.179C154.928 149.353 154.751 148.466 154.751 147.571C154.751 146.676 154.928 145.789 155.272 144.963C155.616 144.136 156.12 143.386 156.755 142.755C157.386 142.12 158.136 141.616 158.963 141.272C159.789 140.928 160.676 140.751 161.571 140.751C162.466 140.751 163.353 140.928 164.179 141.272C165.006 141.616 165.756 142.12 166.387 142.755ZM86.5312 137.25C50.8474 137.25 21.8125 108.215 21.8125 72.5312C21.8125 36.8474 50.8474 7.8125 86.5312 7.8125C122.215 7.8125 151.25 36.8474 151.25 72.5312C151.25 108.215 122.215 137.25 86.5312 137.25Z'
        fill={color}
      />
      <path
        d='M86.5312 21.4375C58.3547 21.4375 35.4375 44.3547 35.4375 72.5312C35.4375 100.708 58.3547 123.625 86.5312 123.625C114.708 123.625 137.625 100.708 137.625 72.5312C137.625 44.3547 114.708 21.4375 86.5312 21.4375ZM86.5312 116.812C62.1152 116.812 42.25 96.9473 42.25 72.5312C42.25 48.1152 62.1152 28.25 86.5312 28.25C110.947 28.25 130.812 48.1152 130.812 72.5312C130.812 96.9473 110.947 116.812 86.5312 116.812Z'
        fill={color}
      />
    </svg>
  );
};

EmptyStateSearchIcon.propTypes = {
  color: PropTypes.string,
};

EmptyStateSearchIcon.defaultProps = {
  color: Theme.light60,
};

export default EmptyStateSearchIcon;
