import imageCompression from 'browser-image-compression';

const compressImage = async (
  file,
  options = {
    maxSizeMB: 2,
    maxWidthOrHeight: 5000,
    useWebWorker: true,
  },
) => {
  try {
    const compressedImage = await imageCompression(file, options);

    if (compressedImage) return compressedImage;

    return null;
  } catch (error) {
    console.log('compressImage error', error);

    return null;
  }
};

export default compressImage;
