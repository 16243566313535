import { AppBar, Container } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useHistory, useLocation } from 'react-router-dom';

import { Drawer, InstagramBrowserModal, Logo, Menu } from '..';
import * as Theme from '../../constants/Theme';
import './styles.scss';

const PublicHeader = () => {
  const history = useHistory();
  const location = useLocation();

  const pathname = location?.pathname;

  const locationsWhereHeaderIsHidden = ['/login', '/register', '/password-recovery'];

  if (locationsWhereHeaderIsHidden.find((item) => pathname.startsWith(item))) {
    return null;
  }

  return (
    <AppBar position='fixed' className='public-header'>
      <InstagramBrowserModal />
      <Container size={Theme.containerMaxWidth}>
        <div className='public-header__content'>
          <div className='public-header__drawer'>
            <Drawer publicRoutes />
          </div>

          <div onClick={() => history.push('/')} className='public-header__logo'>
            <Logo />
          </div>

          <div className='public-header__menu'>
            <Menu publicRoutes />
          </div>
        </div>
      </Container>
    </AppBar>
  );
};

export default observer(PublicHeader);
