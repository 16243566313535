import { Pagination } from '@material-ui/lab';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Button, EmptyState, Loader, Screen, Text } from '../../components';
import { EmptyStateWalletIcon, MoneyIcon } from '../../components/svg';
import { SYSTEM_INSTABILITY } from '../../constants/Messages';
import * as Theme from '../../constants/Theme';
import { useStore } from '../../hooks';
import './styles.scss';

const BillingScreen = () => {
  const history = useHistory();
  const store = useStore();
  const [mounted, setMounted] = useState(false);
  const [requesting, setRequesting] = useState(true);
  const [page, setPage] = useState(1);

  const billing = store.UserStore.billingList;
  const meta = store.UserStore.billingMeta;

  const getBilling = async () => {
    try {
      setRequesting(true);

      const response = await flowResult(store.UserStore.getBilling({ page }));

      if (response.error) toast.error(SYSTEM_INSTABILITY);
    } catch (error) {
      console.log(error);
    } finally {
      setRequesting(false);
    }
  };

  const renderBilling = () => {
    if (requesting) return <Loader color={Theme.primary} />;

    if (mounted && !requesting && !billing?.length) {
      return (
        <EmptyState
          icon={<EmptyStateWalletIcon />}
          title='Você ainda não começou a faturar.'
          description='Busque ou crie um evento e comece a faturar vendendo as suas fotos na Rokkos!'
          button={
            <Button variant='contained' onClick={() => history.push('/')}>
              Vender minhas fotos
            </Button>
          }
        />
      );
    }

    return (
      <>
        <div className='surface-group'>
          <div className='checkout__surface surface-7 billing__total'>
            <MoneyIcon size={40} color={Theme.primary} />
            <Text variant='h5' responsive>
              {`Você faturou R$ ${String(store.UserStore.billingTotal.toFixed(2)).replace(
                '.',
                ',',
              )} até agora!`}
            </Text>
          </div>

          <div className='checkout__surface surface-7 billing__balance mobile'>
            <div className='billing__balance__title'>
              <Text variant='h4'>Saldo disponível</Text>
            </div>
            <div className='billing__balance__value'>
              <Text variant='h3' color={Theme.primary}>
                {`R$ ${String(store.UserStore.billingBalance.toFixed(2)).replace('.', ',')}`}
              </Text>
            </div>
            <div className='billing__balance__description'>
              <Text variant='body-2'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit
                amet.
              </Text>
            </div>
            <div className='billing__balance__action'>
              <Button variant='contained'>Sacar valor</Button>
            </div>
          </div>

          <div className='checkout__surface surface-7'>
            <div className='billing__list-header'>
              <div className='billing__list-header__order'>
                <Text variant='body-1' color={Theme.primary}>
                  Histórico de Saques
                </Text>
              </div>
              <div className='billing__list-header__date'>
                <Text variant='body-1' color={Theme.primary}>
                  Data
                </Text>
              </div>
              <div className='billing__list-header__value'>
                <Text variant='body-1' color={Theme.primary}>
                  Valor
                </Text>
              </div>
            </div>

            <div className='billing__list'>
              {billing.map(({ id, order, date, value }) => (
                <div className='billing__list__item' key={id}>
                  <div className='billing__list__item__order'>
                    <Text variant='h6'>{`Saque #${order < 10 ? `0${order}` : order}`}</Text>
                  </div>
                  <div className='billing__list__item__date'>
                    <Text variant='h6'>{date}</Text>
                  </div>
                  <div className='billing__list__item__value'>
                    <Text variant='h6'>{`R$ ${String(value.toFixed(2)).replace(
                      '.',
                      ',',
                    )}`}</Text>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className='checkout__surface surface-7 billing__balance desktop'>
          <div className='billing__balance__title'>
            <Text variant='h4'>Saldo disponível</Text>
          </div>
          <div className='billing__balance__value'>
            <Text variant='h3' responsive color={Theme.primary}>
              {`R$ ${String(store.UserStore.billingBalance.toFixed(2)).replace('.', ',')}`}
            </Text>
          </div>
          <div className='billing__balance__description'>
            <Text variant='body-2' responsive>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit
              amet.
            </Text>
          </div>
          <div className='billing__balance__action'>
            <Button variant='contained' fullWidth>
              Sacar valor
            </Button>
          </div>
        </div>
      </>
    );
  };

  const init = async () => {
    await getBilling();
    setMounted(true);
  };

  useEffect(() => {
    if (!mounted) return;

    getBilling();

    if (window.innerWidth > 1024) {
      window.scrollTo(0, 140);
      return;
    }

    window.scrollTo(0, 594);
  }, [page]);

  useEffect(() => {
    init();
  }, []);

  return (
    <Screen className='billing'>
      <div className='checkout__header'>
        <Text variant='h5' responsive color={Theme.primary}>
          Faturamento
        </Text>
      </div>
      <main>{renderBilling()}</main>

      {mounted && billing?.length && meta?.last_page > 1 ? (
        <Pagination
          page={page}
          count={meta.last_page}
          color='primary'
          onChange={(_, page) => setPage(page)}
          disabled={requesting}
        />
      ) : null}
    </Screen>
  );
};

export default observer(BillingScreen);
