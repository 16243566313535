import { Container, Menu, MenuItem } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Card, EmptyState, Hero, ListLoader, Screen, Text } from '../../components';
import { DropdownIcon, EmptyStateCalendarIcon } from '../../components/svg';
import { SYSTEM_INSTABILITY } from '../../constants/Messages';
import * as Theme from '../../constants/Theme';
import { useStore } from '../../hooks';
import './styles.scss';

const orderByMenuOptions = ['mais recentes', 'mais antigos'];

const PublicEventsScreen = () => {
  const store = useStore();
  const history = useHistory();
  const [mounted, setMounted] = useState(false);
  const [requesting, setRequesting] = useState(true);
  const [searching, setSearching] = useState(false);
  const [name, setName] = useState('');
  const [location, setLocation] = useState('');
  const [date, setDate] = useState(null);
  const [page, setPage] = useState(1);
  const [orderByMenu, setOrderByMenu] = useState(null);
  const [orderBy, setOrderBy] = useState(0);

  const events = store.EventStore.list;
  const meta = store.EventStore.meta;

  const handleOrderByMenuClick = (event) => {
    setOrderByMenu(event.currentTarget);
  };

  const handleCloseOrderByMenu = () => {
    setOrderByMenu(null);
  };

  const getAllEvents = async () => {
    try {
      setRequesting(true);

      const response = await flowResult(
        store.EventStore.getAllList({
          name,
          city_id: location,
          date,
          order_by: orderBy,
          page,
        }),
      );

      if (response.error) toast.error(SYSTEM_INSTABILITY);
    } catch (error) {
      console.log(error);
    } finally {
      setRequesting(false);
    }
  };

  const search = async () => {
    await getAllEvents();
    setSearching(true);
  };

  const renderEventsLength = () => {
    if (requesting) return '';

    let text = 'Nenhum evento identificado';

    if (meta?.total === 1) {
      text = '1 evento identificado';
    } else if (meta?.total > 1) {
      text = `${meta?.total} eventos identificados`;
    }

    return text;
  };

  const renderEventList = () => {
    if (requesting) return <ListLoader />;

    if (mounted && !events?.length) {
      if (searching) {
        return (
          <EmptyState
            title='Desculpe, não achamos o que você estava procurando'
            description='Que tal fazer uma nova busca?'
          />
        );
      }
      return (
        <EmptyState
          icon={<EmptyStateCalendarIcon />}
          title='Desculpe, não achamos o que você estava procurando'
          description='Que tal fazer uma nova busca?'
        />
      );
    }

    return events.map((event) => (
      <Card
        key={event.id}
        event={event}
        variant='event'
        onClick={() => history.push(`/event/${event.id}`)}
      />
    ));
  };

  const init = async () => {
    await getAllEvents();
    setMounted(true);
  };

  useEffect(() => {
    if (mounted) getAllEvents();
  }, [/* activeTab, */ orderBy, page]);

  useEffect(() => {
    if (!mounted) return;

    if (window.innerWidth > 1024) {
      window.scrollTo(0, 104);
      return;
    }

    window.scrollTo(0, 352);
  }, [page]);

  useEffect(() => {
    if (!mounted) init();
  }, []);

  return (
    <Screen className='public-events' container={false}>
      <Hero
        requesting={requesting}
        onChangeText={setName}
        onChangeLocation={setLocation}
        onChangeDate={setDate}
        onSubmitSearch={search}
        variant='shopper'
      />

      <Container maxWidth={Theme.containerMaxWidth}>
        {/* <div className="public-events__tabs">
          <Tabs
            onChange={handleTabChange}
            value={activeTab}
            tabs={tabs.map((label, index) => ({
              label,
              disabled: activeTab !== index && requesting,
            }))}
          />
        </div> */}

        <div className='public-events__actions'>
          <div className='public-events__filter'>
            {events?.length ? (
              <div className='public-events__filter__length'>
                <Text variant='h6' color={Theme.light38}>
                  {renderEventsLength()}
                </Text>
              </div>
            ) : null}
            <div className='public-events__filter__item'>
              <div className='public-events__filter__item__label'>
                <Text variant='h6' color={Theme.primary}>
                  Ordenar por:
                </Text>
              </div>
              <div
                className='public-events__filter__item__value'
                onClick={handleOrderByMenuClick}
              >
                <Text variant='body-2' color={Theme.light87}>
                  {orderByMenuOptions[orderBy]}
                </Text>
                <DropdownIcon />
              </div>
              <Menu
                anchorEl={orderByMenu}
                open={Boolean(orderByMenu)}
                onClose={handleCloseOrderByMenu}
                getContentAnchorEl={null}
              >
                {orderByMenuOptions.map((option, index) => (
                  <MenuItem
                    key={option}
                    disabled={requesting}
                    onClick={() => {
                      handleCloseOrderByMenu();
                      setOrderBy(index);
                    }}
                  >
                    {option}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </div>
        </div>

        <div className='public-events__event-list'>
          {renderEventList()}
          {mounted && events?.length && meta?.last_page > 1 ? (
            <Pagination
              page={page}
              count={meta.last_page}
              color='primary'
              onChange={(_, page) => setPage(page)}
              disabled={requesting}
            />
          ) : null}
        </div>
      </Container>
    </Screen>
  );
};

export default observer(PublicEventsScreen);
