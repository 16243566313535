import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import ReactDOM from 'react-dom';
import 'react-toastify/dist/ReactToastify.css';

import App from './App';
import './scss/index.scss';

ReactDOM.render(<App />, document.getElementById('root'));
