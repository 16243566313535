import { Stepper as MUIStepper, Step, StepLabel } from '@material-ui/core';
import PropTypes from 'prop-types';

import './styles.scss';

const Stepper = (props) => {
  const { steps, activeStep, alternativeLabel } = props;

  const stepperClassNames = () => {
    let classNames = 'stepper';
    return classNames;
  };

  if (!steps?.length) return null;

  return (
    <div className={stepperClassNames()}>
      <MUIStepper activeStep={activeStep} alternativeLabel={alternativeLabel}>
        {steps.map(({ label, completed, error }) => (
          <Step key={label} completed={completed}>
            <StepLabel error={error}>{label}</StepLabel>
          </Step>
        ))}
      </MUIStepper>
    </div>
  );
};

Stepper.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      completed: PropTypes.bool,
      error: PropTypes.bool,
    }),
  ),
  activeStep: PropTypes.number,
  alternativeLabel: PropTypes.bool,
};

Stepper.defaultProps = {
  steps: [],
  activeStep: 1,
  alternativeLabel: false,
};

export default Stepper;
