import { makeAutoObservable } from 'mobx';

import PaginationInitialMeta from '../../constants/PaginationInitialMeta';
import baseAPI from '../../services/baseAPI';
import Album from '../models/Album';

export default class AlbumStore {
  rootStore;
  meta = { ...PaginationInitialMeta };
  list = [];
  detail = null;

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this, { rootStore: false });
  }

  getList = async (payload = {}) => {
    try {
      const { event_id, page = 1 } = payload;

      this.list = [];
      this.meta = { ...PaginationInitialMeta };
      this.rootStore.PhotoStore.list = [];

      const params = {};

      if (page > 1) params.page = page;

      const response = await baseAPI.get(`/events/${event_id}/albums`, {
        params,
      });

      const { status, data } = response;

      if (status === 204) {
        this.list = [];
        this.meta = { ...PaginationInitialMeta };
        this.rootStore.PhotoStore.list = [];

        return true;
      }

      if (status !== 200 || !data?.data || !data?.meta) {
        this.list = [];
        this.meta = { ...PaginationInitialMeta };
        this.rootStore.PhotoStore.list = [];

        return {
          error: {
            status,
          },
        };
      }

      this.meta = data.meta;
      this.list = data.data.map((item) => new Album(item));

      return true;
    } catch (error) {
      console.warn(error);

      this.list = [];
      this.meta = { ...PaginationInitialMeta };
      this.rootStore.PhotoStore.list = [];

      return {
        error: {
          status: error?.response?.status || 400,
        },
      };
    }
  };

  getDetail = async (payload = {}) => {
    try {
      const { event_id, album_id } = payload;

      this.detail = null;

      const response = await baseAPI.get(`/events/${event_id}/albums/${album_id}`);

      const { status, data } = response;

      if (status !== 200 || !data) {
        this.detail = null;

        return {
          error: {
            status,
          },
        };
      }

      this.detail = new Album(data);

      return true;
    } catch (error) {
      console.warn(error);

      this.detail = null;

      return {
        error: {
          status: error?.response?.status || 400,
        },
      };
    }
  };

  remove = async (payload = {}) => {
    try {
      const { event_id, album_id } = payload;

      const response = await baseAPI.delete(`/events/${event_id}/albums/${album_id}`);

      const { status } = response;

      if (status !== 200) {
        return {
          error: {
            status,
          },
        };
      }

      return true;
    } catch (error) {
      console.warn(error);

      return {
        error: {
          status: error?.response?.status || 400,
        },
      };
    }
  };
}
