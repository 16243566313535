import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Button, CartListItem, EmptyState, Screen, Text } from '../../components';
import { EmptyStateCartIcon } from '../../components/svg';
import * as Theme from '../../constants/Theme';
import { useStore } from '../../hooks';
import './styles.scss';

const CartScreen = () => {
  const history = useHistory();
  const store = useStore();

  useEffect(() => {
    store.CheckoutStore.getCart();
  }, []);

  const renderCart = () => {
    if (!store.CheckoutStore.cart?.cart_events?.length) {
      return (
        <EmptyState
          icon={<EmptyStateCartIcon />}
          title='Seu carrinho está vazio.'
          description='Confira o acervo de fotos da Rokkos!'
          button={
            <Button variant='contained' onClick={() => history.push('/')}>
              Conferir fotos
            </Button>
          }
        />
      );
    }

    return (
      <>
        <div className='cart__content'>
          {!!store.CheckoutStore.cart?.cart_events?.length &&
            store.CheckoutStore.cart.cart_events.map((event) => (
              <div className='checkout__surface cart__event surface-7' key={event.id}>
                <div className='cart__event__header'>
                  <Text variant='h5' responsive>
                    {event?.event?.name}
                  </Text>
                </div>
                <div className='cart__list-header'>
                  <div className='cart__list-header__photo'>
                    <Text variant='body-1' color={Theme.primary}>
                      Item
                    </Text>
                  </div>
                  <div className='cart__list-header__id'>
                    <Text variant='body-1' color={Theme.primary}>
                      ID
                    </Text>
                  </div>
                  <div className='cart__list-header__owner'>
                    <Text variant='body-1' color={Theme.primary}>
                      Fotógrafo
                    </Text>
                  </div>
                  <div className='cart__list-header__resolution'>
                    <Text variant='body-1' color={Theme.primary}>
                      Resolução
                    </Text>
                  </div>
                  <div className='cart__list-header__price'>
                    <Text variant='body-1' color={Theme.primary}>
                      Preço
                    </Text>
                  </div>
                </div>

                <div className='cart__list'>
                  {!!event.products?.length &&
                    event.products.map(({ photo }) => (
                      <CartListItem key={photo.id} photo={photo} />
                    ))}
                </div>

                <div className='cart__prices'>
                  <div className='cart__subtotal'>
                    <Text variant='h5'>Subtotal</Text>
                    <Text variant='h5'>{`R$ ${String(
                      Number(event.total_price).toFixed(2),
                    ).replace('.', ',')}`}</Text>
                  </div>
                  {!!event?.packages?.length && (
                    <>
                      {!event?.packages[1]?.applied && event.packages[0]?.enabled && (
                        <div className='cart__package'>
                          {event.packages[0].applied ? (
                            <div className='cart__package__content'>
                              <div className='cart__package__price'>
                                <Text variant='h6'>Pacote 1 aplicado</Text>
                                <Text variant='h5' color={Theme.primary}>{`R$ ${String(
                                  Number(
                                    event.packages[0].price * event.products.length,
                                  ).toFixed(2),
                                ).replace('.', ',')}`}</Text>
                              </div>
                              <div className='cart__package__conditions'>
                                {`A partir de ${event.packages[0].quantity} fotos R$ ${String(
                                  Number(event.packages[0].price).toFixed(2),
                                ).replace('.', ',')}`}
                              </div>
                            </div>
                          ) : (
                            <div className='cart__package__discount'>
                              <Text variant='body-2' color={Theme.warning}>
                                {`Adicionando ${event.packages[0].quantity} fotos ao seu carrinho, você terá ${event.packages[0].discount}% de desconto no
                            valor total pois o PACOTE 1 será aplicado.`}
                              </Text>
                            </div>
                          )}
                        </div>
                      )}
                      {event?.packages?.length > 1 && event.packages[1]?.enabled && (
                        <div className='cart__package'>
                          {event.packages[1]?.applied ? (
                            <div className='cart__package__content'>
                              <div className='cart__package__price'>
                                <Text variant='h6'>Pacote 2 aplicado</Text>
                                <Text variant='h5' color={Theme.primary}>{`R$ ${String(
                                  Number(
                                    event.packages[1].price * event.products.length,
                                  ).toFixed(2),
                                ).replace('.', ',')}`}</Text>
                              </div>
                              <div className='cart__package__conditions'>
                                {`A partir de ${event.packages[1].quantity} fotos R$ ${String(
                                  Number(event.packages[1].price).toFixed(2),
                                ).replace('.', ',')}`}
                              </div>
                            </div>
                          ) : (
                            <div className='cart__package__discount'>
                              <Text variant='body-2' color={Theme.warning}>
                                {`Adicionando ${event.packages[1].quantity} fotos ao seu carrinho, você terá ${event.packages[1].discount}% de desconto no
                            valor total pois o PACOTE 2 será aplicado.`}
                              </Text>
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            ))}
        </div>
        <div className='cart__total'>
          <Text variant='h4' color={Theme.primary}>
            Total
          </Text>
          <Text variant='h4'>
            {`R$ ${String(Number(store.CheckoutStore.cart.total_price).toFixed(2)).replace(
              '.',
              ',',
            )}`}
          </Text>
        </div>

        <div className='cart__actions'>
          <Button variant='contained' onClick={() => history.push('/checkout')}>
            Concluir compra
          </Button>
        </div>
      </>
    );
  };

  return (
    <Screen className='cart'>
      <main>
        <div className='checkout__header'>
          <Text variant='h5' responsive color={Theme.primary}>
            Carrinho
          </Text>
        </div>
        {renderCart()}
      </main>
    </Screen>
  );
};

export default observer(CartScreen);
