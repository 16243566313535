import PropTypes from 'prop-types';

import * as Theme from '../../../constants/Theme';

const CameraIcon = (props) => {
  const { color, size } = props;

  return (
    <svg width={size} height={size} viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
      <circle fill={color} cx='12' cy='12' r='3.2'></circle>
      <path
        fill={color}
        d='M9 2 7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2h-3.17L15 2H9zm3 15c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z'
      ></path>
    </svg>
  );
};

CameraIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

CameraIcon.defaultProps = {
  color: Theme.light60,
  size: 24,
};

export default CameraIcon;
