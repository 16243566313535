import { useFormik } from 'formik';
import { flowResult } from 'mobx';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';

import { Button, Input, Logo, Screen, Text } from '../../components';
import { passwordRecoveryFormDefinitions } from '../../constants/FormDefinitions';
import {
  INVALID_EMAIL,
  PASSWORD_RECOVERY_SUCCESS,
  SYSTEM_INSTABILITY,
} from '../../constants/Messages';
import * as Theme from '../../constants/Theme';
import { useStore } from '../../hooks';
import './styles.scss';

const PasswordRecoveryScreen = () => {
  const store = useStore();
  const history = useHistory();

  const { initialValues, validationSchema } = passwordRecoveryFormDefinitions;

  const onSubmit = async (formValues) => {
    try {
      const response = await flowResult(store.UserStore.recoverPassword(formValues));

      if (response?.error) {
        switch (response?.error?.status) {
          case 403: {
            toast.error(INVALID_EMAIL);
            return;
          }

          default: {
            toast.error(SYSTEM_INSTABILITY);
            return;
          }
        }
      }

      toast.success(PASSWORD_RECOVERY_SUCCESS);
      history.push('/');
    } catch (error) {
      console.log(error);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit,
  });

  return (
    <Screen className='password-recovery'>
      <main
        style={{
          pointerEvents: formik.isSubmitting ? 'none' : 'initial',
        }}
      >
        <div className='password-recovery__logo'>
          <Logo big />
        </div>
        <div className='password-recovery__title'>
          <Text variant='h5' responsive color={Theme.primary}>
            Recuperar Senha
          </Text>
        </div>
        <div className='password-recovery__text'>
          <Text variant='h6'>
            Digite seu e-mail e te enviaremos um link para recuperar a sua senha.
          </Text>
        </div>
        <form onSubmit={formik.handleSubmit} noValidate>
          <div className='password-recovery__input'>
            <Input
              variant='filled'
              placeholder='E-mail'
              fullWidth
              id='email'
              value={formik.values.email}
              error={formik.touched.email && !!formik.errors.email}
              helperText={formik.touched.email && formik.errors.email}
              onChange={formik.handleChange}
            />
          </div>
          <div className='password-recovery__actions'>
            <Button variant='contained' fullWidth loading={formik.isSubmitting} type='submit'>
              Enviar E-mail
            </Button>
          </div>
        </form>
      </main>
    </Screen>
  );
};

export default PasswordRecoveryScreen;
