import { flowResult } from 'mobx';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Button, Loader, Modal, PhotoModal, Text } from '..';
import {
  ADD_PHOTO_TO_CART_SUCCESS,
  DELETE_PHOTO_SUCCESS,
  SYSTEM_INSTABILITY,
} from '../../constants/Messages';
import * as Theme from '../../constants/Theme';
import { useStore } from '../../hooks';
import { ShoppingCartIcon, TrashIcon } from '../svg';
import './styles.scss';

const PhotoGridItem = ({ photo, variant, showActionButton, onDeletePhoto }) => {
  const { id, thumbnail_url, width, height } = photo;
  const store = useStore();
  const history = useHistory();
  const location = useLocation();
  const [requesting, setRequesting] = useState(false);
  const [photoModalIsOpen, setPhotoModalIsOpen] = useState(false);
  const [deletePhotoModalIsOpen, setDeletePhotoModalIsOpen] = useState(false);

  const getItemClassName = () => {
    let newClassName = 'photo-grid__item';

    if (variant) newClassName += ` photo-grid__item--${variant}`;
    if (width > height) newClassName += ` photo-grid__item--wide`;

    return newClassName;
  };

  const deletePhoto = async () => {
    try {
      setRequesting(true);
      setDeletePhotoModalIsOpen(false);
      setPhotoModalIsOpen(false);

      const response = await flowResult(store.PhotoStore.remove({ photos_id: [photo.id] }));

      if (response.error) {
        toast.error(SYSTEM_INSTABILITY);
        return;
      }

      toast.success(DELETE_PHOTO_SUCCESS);
    } catch (error) {
      console.log(error);
    } finally {
      setRequesting(false);
      onDeletePhoto();
    }
  };

  const handleActionClick = async () => {
    try {
      if (!store.UserStore.profile?.type) {
        history.push(`/login?redirect=${location.pathname}`);
      }

      if (variant === 'shopper') {
        const response = await flowResult(store.CheckoutStore.addItem(photo));

        if (!response?.error) {
          toast.success(ADD_PHOTO_TO_CART_SUCCESS, {
            onClick: () => history.push('/checkout/cart'),
          });
        }

        return;
      }

      setDeletePhotoModalIsOpen(true);
    } catch (error) {
      console.log(error);
    }
  };

  const renderActionIcon = () => {
    if (requesting) return <Loader />;

    if (variant === 'shopper') {
      return <ShoppingCartIcon color={Theme.light87} />;
    }

    return <TrashIcon color={Theme.light87} />;
  };

  return (
    <div key={id} className={getItemClassName({ width, height })}>
      <div className='image-wrapper'>
        <img
          src={thumbnail_url}
          className='photo-grid__item__image'
          onClick={() => setPhotoModalIsOpen(true)}
        />
        {showActionButton ? (
          <Button
            className={`photo-grid__item__button surface-11${requesting ? ' visible' : ''}`}
            onClick={handleActionClick}
          >
            {renderActionIcon()}
          </Button>
        ) : null}
      </div>
      <PhotoModal
        modalProps={{
          open: photoModalIsOpen,
          onCancel: () => {
            setPhotoModalIsOpen(false);
          },
          showBtCancel: false,
          showBtConfirm: false,
          showBtClose: true,
        }}
        photo={photo}
        variant={variant}
        onClickAction={showActionButton ? handleActionClick : null}
      />
      <Modal
        open={deletePhotoModalIsOpen}
        btConfirmText='Excluir'
        btCancelColor='light'
        onCancel={() => setDeletePhotoModalIsOpen(false)}
        onConfirm={deletePhoto}
      >
        <Text variant='subtitle-1' color={Theme.light87}>
          Excluir foto?
        </Text>
      </Modal>
    </div>
  );
};

const PhotoGrid = (props) => {
  const { event, photos, variant, showActionButton, onDeletePhoto } = props;

  return (
    <div className='photo-grid'>
      {photos.map((photo) => (
        <PhotoGridItem
          key={photo.id}
          event={event}
          photo={photo}
          variant={variant}
          showActionButton={showActionButton}
          onDeletePhoto={onDeletePhoto}
        />
      ))}
    </div>
  );
};

PhotoGridItem.propTypes = {
  photo: PropTypes.instanceOf(Object).isRequired,
  event: PropTypes.instanceOf(Object),
  variant: PropTypes.string.isRequired,
  showActionButton: PropTypes.bool.isRequired,
  onDeletePhoto: PropTypes.func.isRequired,
};

PhotoGrid.propTypes = {
  photos: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      src: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
    }).isRequired,
  ),
  event: PropTypes.instanceOf(Object),
  variant: PropTypes.oneOf(['photographer', 'shopper']),
  showActionButton: PropTypes.bool,
  onDeletePhoto: PropTypes.func,
};

PhotoGrid.defaultProps = {
  photos: [],
  event: {},
  variant: 'photographer',
  showActionButton: true,
  onDeletePhoto: () => {},
};

export default observer(PhotoGrid);
