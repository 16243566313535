/* eslint-disable no-unused-vars */
import { Container, Menu, MenuItem } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  Button,
  Card,
  EmptyState,
  Hero,
  ListLoader,
  Modal,
  Screen,
  Tabs,
  Text,
} from '../../components';
import { CloseIcon, DropdownIcon, EmptyStateCalendarIcon } from '../../components/svg';
import { EVENT_REMOVED_FROM_MY_EVENTS, SYSTEM_INSTABILITY } from '../../constants/Messages';
import * as Theme from '../../constants/Theme';
import {
  /* useQuery, */
  useStore,
} from '../../hooks';
import './styles.scss';

const statusMenuOptions = ['todos os jobs', 'jobs inativos', 'jobs ativos'];

const orderByMenuOptions = ['mais recentes', 'mais antigos'];

const tabs = ['Meus jobs', 'Todos os jobs'];

const PhotographerMyJobsScreen = () => {
  const store = useStore();
  const history = useHistory();
  // const query = useQuery();

  const [mounted, setMounted] = useState(false);
  const [requesting, setRequesting] = useState(true);
  const [searching, setSearching] = useState(false);
  const [activeTab] = useState(0);
  const [leavingEvent, setLeavingEvent] = useState(false);
  const [name, setName] = useState('');
  const [location, setLocation] = useState('');
  const [date, setDate] = useState(null);
  const [page, setPage] = useState(1);
  const [statusMenu, setStatusMenu] = useState(null);
  const [orderByMenu, setOrderByMenu] = useState(null);
  const [status, setStatus] = useState(0);
  const [orderBy, setOrderBy] = useState(0);
  const [eventCreationNotAuthorizedModalIsOpen, setEventCreationNotAuthorizedModalIsOpen] =
    useState(false);

  const user = store.UserStore.profile;
  const events = store.EventStore.list;
  const meta = store.EventStore.meta;

  const handleStatusClick = (event) => {
    setStatusMenu(event.currentTarget);
  };

  const handleCloseStatusMenu = () => {
    setStatusMenu(null);
  };

  const handleOrderByMenuClick = (event) => {
    setOrderByMenu(event.currentTarget);
  };

  const handleCloseOrderByMenu = () => {
    setOrderByMenu(null);
  };

  const getEvents = async () => {
    try {
      setRequesting(true);

      const response = await flowResult(
        store.EventStore.getMyList({
          user: activeTab,
          status,
          name,
          city_id: location,
          date,
          order_by: orderBy,
          page,
        }),
      );

      if (response.error) toast.error(SYSTEM_INSTABILITY);
    } catch (error) {
      console.log(error);
    } finally {
      setRequesting(false);
    }
  };

  const search = async () => {
    await getEvents();
    setSearching(true);
  };

  const renderEventsLength = () => {
    if (requesting) return '';

    let text = 'Nenhum job identificado';

    if (meta?.total === 1) {
      text = '1 job identificado';
    } else if (meta?.total > 1) {
      text = `${meta?.total} jobs identificados`;
    }

    return text;
  };

  const leaveEvent = async (id) => {
    try {
      setLeavingEvent(true);

      const response = await flowResult(store.EventStore.leave({ id }));

      if (response.error) {
        toast.error(SYSTEM_INSTABILITY);
        return;
      }

      toast.success(EVENT_REMOVED_FROM_MY_EVENTS);

      await getEvents();
    } catch (error) {
      console.log(error);
    } finally {
      setLeavingEvent(false);
    }
  };

  const renderEventList = () => {
    if (requesting) return <ListLoader />;

    if (mounted && !events?.length) {
      if (searching) {
        return (
          <EmptyState
            title='Desculpe, não achamos o que você estava procurando'
            description='Que tal fazer uma nova busca?'
          />
        );
      }

      switch (activeTab) {
        case 1: {
          return (
            <>
              <EmptyState
                icon={<EmptyStateCalendarIcon />}
                title='A Rokkos ainda não possui nenhum evento.'
                description='Busque ou crie um evento e comece a compartilhar suas fotos.'
                button={
                  <Button
                    variant='contained'
                    onClick={() => {
                      // user.bank_data?.validated
                      //   ? history.push("/event/create")
                      //   : setEventCreationNotAuthorizedModalIsOpen(true);

                      history.push('/event/create');
                    }}
                  >
                    Criar Evento
                  </Button>
                }
              />
              <Modal
                open={eventCreationNotAuthorizedModalIsOpen}
                btConfirmText='Atualizar perfil'
                btCancelText='Voltar'
                btCancelColor='light'
                onCancel={() => setEventCreationNotAuthorizedModalIsOpen(false)}
                onConfirm={() => history.push('/profile')}
              >
                <Text variant='h6' className='modal-title'>
                  Parece que você ainda não preencheu seu perfil
                </Text>
                <Text variant='body-1' color={Theme.light60}>
                  Atualize seus dados pessoais e comece a compartilhar suas fotos na Rokkos!
                </Text>
              </Modal>
            </>
          );
        }

        default: {
          return (
            <EmptyState
              icon={<EmptyStateCalendarIcon />}
              title='Você ainda não possui nenhum evento.'
              description='Busque ou crie um evento e comece a compartilhar suas fotos na Rokkos.'
            />
          );
        }
      }
    }

    return events.map((event) => (
      <Card
        key={event.id}
        event={event}
        variant='event'
        onClick={() => {
          history.push(`/event/${event.id}`);
        }}
        // button={
        //   !requesting &&
        //   activeTab === 1 &&
        //   event.collaborative &&
        //   !event?.user?.manager ? (
        //     <Button
        //       variant="contained"
        //       // onClick={() => history.push(`/event/${id}`)}
        //     >
        //       Participar do evento
        //     </Button>
        //   ) : null
        // }
        button={
          !requesting && activeTab === 0 && !event?.public_event && !event?.user?.manager ? (
            <Button
              onClick={() => leaveEvent(event.id)}
              startIcon={<CloseIcon color={Theme.dark} />}
              variant='contained'
              loading={leavingEvent}
            >
              Sair do evento
            </Button>
          ) : null
        }
      />
    ));
  };

  const handleTabChange = (tab) => {
    setPage(1);
    if (mounted && tab == 1) history.push('/jobs/all');
  };

  const init = async () => {
    // if (query.get("redirect")) {
    //   history.push(query.get("redirect"));
    //   return;
    // }

    await getEvents();
    setMounted(true);
  };

  useEffect(() => {
    if (mounted) getEvents();
  }, [status, orderBy, page]);

  useEffect(() => {
    if (!mounted) return;

    if (window.innerWidth > 1024) {
      window.scrollTo(0, 336);
      return;
    }

    window.scrollTo(0, 432);
  }, [page]);

  useEffect(() => {
    if (!mounted) init();
  }, []);

  return (
    <Screen className='photographer-home' container={false}>
      <Hero
        placeholder='Evento'
        buttonLabel='Buscar Evento'
        requesting={requesting}
        onChangeText={setName}
        onChangeLocation={setLocation}
        onChangeDate={setDate}
        onSubmitSearch={search}
        variant={searching ? 'photographer-small' : 'photographer'}
      />

      <Container maxWidth={Theme.containerMaxWidth}>
        <div className='photographer-home__tabs'>
          <Tabs
            onChange={handleTabChange}
            value={activeTab}
            tabs={tabs.map((label, index) => ({
              label,
              disabled: activeTab !== index && requesting,
            }))}
          />
        </div>

        <div className='photographer-home__actions'>
          <div className='photographer-home__filter'>
            <div className='photographer-home__filter__length'>
              <Text variant='h6' color={Theme.light38}>
                {renderEventsLength()}
              </Text>
            </div>
            {activeTab === 0 ? (
              <div className='photographer-home__filter__item'>
                <div className='photographer-home__filter__item__label'>
                  <Text variant='h6' color={Theme.primary}>
                    Mostrar:
                  </Text>
                </div>
                <div
                  className='photographer-home__filter__item__value'
                  onClick={handleStatusClick}
                >
                  <Text variant='body-2' color={Theme.light87}>
                    {statusMenuOptions[status]}
                  </Text>
                  <DropdownIcon />
                </div>
                <Menu
                  anchorEl={statusMenu}
                  open={Boolean(statusMenu)}
                  onClose={handleCloseStatusMenu}
                  getContentAnchorEl={null}
                >
                  {statusMenuOptions.map((option, index) => (
                    <MenuItem
                      key={option}
                      disabled={requesting}
                      onClick={() => {
                        handleCloseStatusMenu();
                        setStatus(index);
                      }}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </Menu>
              </div>
            ) : null}
            <div className='photographer-home__filter__item'>
              <div className='photographer-home__filter__item__label'>
                <Text variant='h6' color={Theme.primary}>
                  Ordenar por:
                </Text>
              </div>
              <div
                className='photographer-home__filter__item__value'
                onClick={handleOrderByMenuClick}
              >
                <Text variant='body-2' color={Theme.light87}>
                  {orderByMenuOptions[orderBy]}
                </Text>
                <DropdownIcon />
              </div>
              <Menu
                anchorEl={orderByMenu}
                open={Boolean(orderByMenu)}
                onClose={handleCloseOrderByMenu}
                getContentAnchorEl={null}
              >
                {orderByMenuOptions.map((option, index) => (
                  <MenuItem
                    key={option}
                    disabled={requesting}
                    onClick={() => {
                      handleCloseOrderByMenu();
                      setOrderBy(index);
                    }}
                  >
                    {option}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </div>

          {activeTab === 0 ? (
            <div className='photographer-home__create-event'>
              <Button
                variant='contained'
                onClick={() => {
                  // user.bank_data?.validated
                  //   ? history.push("/event/create")
                  //   : setEventCreationNotAuthorizedModalIsOpen(true);

                  history.push('/event/create');
                }}
              >
                Criar evento
              </Button>
              <Modal
                open={eventCreationNotAuthorizedModalIsOpen}
                btConfirmText='Atualizar perfil'
                btCancelText='Voltar'
                btCancelColor='light'
                onCancel={() => setEventCreationNotAuthorizedModalIsOpen(false)}
                onConfirm={() => history.push('/profile')}
              >
                <Text variant='h6' className='modal-title'>
                  Parece que você ainda não preencheu seu perfil
                </Text>
                <Text variant='body-1' color={Theme.light60}>
                  Atualize seus dados pessoais e comece a compartilhar suas fotos na Rokkos!
                </Text>
              </Modal>
            </div>
          ) : null}
        </div>

        <div className='photographer-home__event-list'>
          {renderEventList()}
          {mounted && events?.length && meta?.last_page > 1 ? (
            <Pagination
              page={page}
              count={meta.last_page}
              color='primary'
              onChange={(_, page) => setPage(page)}
              disabled={requesting}
            />
          ) : null}
        </div>
      </Container>
    </Screen>
  );
};

export default observer(PhotographerMyJobsScreen);
