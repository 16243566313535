/* eslint-disable react/prop-types */
import { configure } from 'mobx';
import { createContext } from 'react';

import RootStore from './containers/RootStore';

configure({
  enforceActions: 'never',
});

const rootStore = new RootStore();

export const RootStoreContext = createContext(rootStore);

export const RootStoreProvider = ({ children }) => (
  <RootStoreContext.Provider value={rootStore}>{children}</RootStoreContext.Provider>
);
