import PropTypes from 'prop-types';

import * as Theme from '../../../constants/Theme';

const EmptyStatePhotoSearchIcon = (props) => {
  const { color } = props;

  return (
    <svg
      width='304'
      height='386'
      viewBox='0 0 304 386'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M220.688 0C198.251 0 180 18.2512 180 40.6875C180 63.1237 198.251 81.375 220.688 81.375C231.247 81.375 240.849 77.2985 248.088 70.68C248.173 70.8311 248.25 70.99 248.382 71.1217L256.717 79.4569C255.831 80.9283 255.466 82.655 255.679 84.3593C255.893 86.0636 256.673 87.6466 257.895 88.8538L290.775 121.733C292.235 123.194 294.181 124 296.25 124C298.319 124 300.264 123.194 301.729 121.729C303.194 120.264 304 118.319 304 116.25C304 114.181 303.194 112.236 301.729 110.771L268.85 77.8914C266.393 75.4346 262.421 75.0665 259.472 76.7327L251.122 68.3821C250.99 68.2504 250.835 68.1729 250.68 68.0876C257.554 60.618 261.371 50.8387 261.375 40.6875C261.375 18.2512 243.124 0 220.688 0ZM266.11 80.631L298.99 113.51C299.722 114.243 300.125 115.215 300.125 116.25C300.125 117.285 299.722 118.257 298.99 118.99C298.251 119.693 297.27 120.086 296.25 120.086C295.23 120.086 294.249 119.693 293.51 118.99L260.631 86.1103C260.27 85.7512 259.983 85.3243 259.788 84.8542C259.592 84.384 259.492 83.8798 259.492 83.3706C259.492 82.8614 259.592 82.3572 259.788 81.8871C259.983 81.4169 260.27 80.99 260.631 80.631C260.99 80.2699 261.417 79.9834 261.887 79.7878C262.357 79.5923 262.861 79.4916 263.371 79.4916C263.88 79.4916 264.384 79.5923 264.854 79.7878C265.324 79.9834 265.751 80.2699 266.11 80.631ZM220.688 77.5C200.39 77.5 183.875 60.9847 183.875 40.6875C183.875 20.3903 200.39 3.875 220.688 3.875C240.985 3.875 257.5 20.3903 257.5 40.6875C257.5 60.9847 240.985 77.5 220.688 77.5Z'
        fill={color}
      />
      <path
        d='M220.688 11.625C204.66 11.625 191.625 24.6605 191.625 40.6875C191.625 56.7145 204.66 69.75 220.688 69.75C236.715 69.75 249.75 56.7145 249.75 40.6875C249.75 24.6605 236.715 11.625 220.688 11.625ZM220.688 65.875C206.799 65.875 195.5 54.5755 195.5 40.6875C195.5 26.7995 206.799 15.5 220.688 15.5C234.576 15.5 245.875 26.7995 245.875 40.6875C245.875 54.5755 234.576 65.875 220.688 65.875Z'
        fill={color}
      />
      <path
        d='M10.7812 386H219.219C225.163 386 230 381.163 230 375.219V166.781C230 160.837 225.163 156 219.219 156H10.7812C4.83719 156 0 160.837 0 166.781V375.219C0 381.163 4.83719 386 10.7812 386ZM7.1875 166.781C7.1875 165.828 7.56613 164.914 8.24008 164.24C8.91404 163.566 9.82813 163.188 10.7812 163.188H219.219C220.172 163.188 221.086 163.566 221.76 164.24C222.434 164.914 222.812 165.828 222.812 166.781V375.219C222.812 376.172 222.434 377.086 221.76 377.76C221.086 378.434 220.172 378.812 219.219 378.812H10.7812C9.82813 378.812 8.91404 378.434 8.24008 377.76C7.56613 377.086 7.1875 376.172 7.1875 375.219V166.781Z'
        fill={color}
      />
      <path
        d='M147.344 245.844C155.272 245.844 161.719 239.397 161.719 231.469C161.719 223.541 155.272 217.094 147.344 217.094C139.416 217.094 132.969 223.541 132.969 231.469C132.969 239.397 139.416 245.844 147.344 245.844ZM147.344 224.281C148.767 224.28 150.159 224.701 151.344 225.491C152.528 226.281 153.451 227.404 153.997 228.719C154.542 230.034 154.685 231.481 154.408 232.878C154.13 234.274 153.445 235.557 152.438 236.563C151.432 237.57 150.149 238.255 148.753 238.533C147.356 238.81 145.909 238.667 144.594 238.122C143.279 237.576 142.156 236.653 141.366 235.469C140.576 234.284 140.155 232.892 140.156 231.469C140.156 227.508 143.376 224.281 147.344 224.281Z'
        fill={color}
      />
      <path
        d='M32.3438 335.688H197.656C198.609 335.688 199.523 335.309 200.197 334.635C200.871 333.961 201.25 333.047 201.25 332.094V188.344C201.25 187.391 200.871 186.477 200.197 185.803C199.523 185.129 198.609 184.75 197.656 184.75H32.3438C31.3906 184.75 30.4765 185.129 29.8026 185.803C29.1286 186.477 28.75 187.391 28.75 188.344V332.094C28.75 333.047 29.1286 333.961 29.8026 334.635C30.4765 335.309 31.3906 335.688 32.3438 335.688ZM35.9375 328.5V287.977C36.0956 287.862 36.2753 287.797 36.4191 287.653L73.1903 250.882C74.1315 249.984 75.3825 249.483 76.6834 249.483C77.9844 249.483 79.2353 249.984 80.1766 250.882L134.234 304.939C134.896 305.607 135.794 305.987 136.734 305.998C137.674 306.009 138.581 305.649 139.258 304.997L167.045 278.604C167.949 277.69 169.176 277.167 170.462 277.147C171.748 277.127 172.99 277.611 173.923 278.497L194.062 300.433L194.07 300.44V328.5H35.9375ZM194.062 191.938V289.817L179.105 273.53C177.986 272.399 176.652 271.502 175.182 270.891C173.713 270.281 172.136 269.968 170.545 269.972H170.538C168.958 269.965 167.393 270.27 165.931 270.87C164.469 271.469 163.141 272.351 162.021 273.465L136.836 297.385L85.2581 245.801C80.6653 241.215 72.6872 241.222 68.1087 245.801L35.9375 277.979V191.938H194.062Z'
        fill={color}
      />
    </svg>
  );
};

EmptyStatePhotoSearchIcon.propTypes = {
  color: PropTypes.string,
};

EmptyStatePhotoSearchIcon.defaultProps = {
  color: Theme.light60,
};

export default EmptyStatePhotoSearchIcon;
