import { makeAutoObservable } from 'mobx';

import PaginationInitialMeta from '../../constants/PaginationInitialMeta';
import baseAPI from '../../services/baseAPI';
import { formatDate } from '../../utils';
import Event from '../models/Event';

export default class EventStore {
  rootStore;
  meta = { ...PaginationInitialMeta };
  list = [];
  detail = null;

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this, { rootStore: false });
  }

  getList = async (payload = {}) => {
    try {
      const { user, name, city_id, date, status, order_by, page = 1, per_page = 16 } = payload;

      this.list = [];
      this.meta = { ...PaginationInitialMeta };
      this.detail = null;
      this.rootStore.AlbumStore.list = [];
      this.rootStore.PhotoStore.list = [];
      this.rootStore.AlbumStore.meta = { ...PaginationInitialMeta };
      this.rootStore.PhotoStore.meta = { ...PaginationInitialMeta };

      const params = {};

      if (name?.length) params.name = name;
      if (city_id) params.city_id = city_id;
      if (date instanceof Date) params.date = formatDate(date, '-', 'en-US');
      if (!user && user !== null) params.user = 1;
      if (status > 0) params.enabled = status - 1;

      if (page > 1) params.page = page;
      if (per_page !== 16) params.per_page = per_page;

      params.order_by = order_by ? 'asc' : 'desc';

      const response = await baseAPI.get('/events/', {
        params,
      });

      const { status: responseStatus, data } = response;

      if (responseStatus === 204) {
        this.list = [];
        this.meta = { ...PaginationInitialMeta };
        this.detail = null;
        this.rootStore.AlbumStore.list = [];
        this.rootStore.PhotoStore.list = [];
        this.rootStore.AlbumStore.meta = { ...PaginationInitialMeta };
        this.rootStore.PhotoStore.meta = { ...PaginationInitialMeta };

        return true;
      }

      if (responseStatus !== 200 || !data?.data || !data?.meta) {
        this.list = [];
        this.meta = { ...PaginationInitialMeta };
        this.detail = null;
        this.rootStore.AlbumStore.list = [];
        this.rootStore.PhotoStore.list = [];
        this.rootStore.AlbumStore.meta = { ...PaginationInitialMeta };
        this.rootStore.PhotoStore.meta = { ...PaginationInitialMeta };

        return {
          error: {
            status: responseStatus,
          },
        };
      }

      this.list = [];
      this.meta = data.meta;
      this.list = data.data.map((item) => new Event(item));

      return true;
    } catch (error) {
      console.warn(error);

      this.list = [];
      this.meta = { ...PaginationInitialMeta };
      this.detail = null;
      this.rootStore.AlbumStore.list = [];
      this.rootStore.PhotoStore.list = [];
      this.rootStore.AlbumStore.meta = { ...PaginationInitialMeta };
      this.rootStore.PhotoStore.meta = { ...PaginationInitialMeta };

      return {
        error: {
          status: error?.response?.status || 400,
        },
      };
    }
  };

  getMyList = async (payload = {}) => {
    try {
      const { name, city_id, date, order_by, page = 1, per_page = 16 } = payload;

      this.list = [];
      this.meta = { ...PaginationInitialMeta };
      this.detail = null;
      this.rootStore.AlbumStore.list = [];
      this.rootStore.PhotoStore.list = [];
      this.rootStore.AlbumStore.meta = { ...PaginationInitialMeta };
      this.rootStore.PhotoStore.meta = { ...PaginationInitialMeta };

      const params = {};

      if (name?.length) params.name = name;
      if (city_id) params.city_id = city_id;
      if (date instanceof Date) params.date = formatDate(date, '-', 'en-US');

      if (page > 1) params.page = page;
      if (per_page !== 16) params.per_page = per_page;

      params.order_by = order_by ? 'asc' : 'desc';

      const response = await baseAPI.get('/events/mine', {
        params,
      });

      const { status: responseStatus, data } = response;

      if (responseStatus === 204) {
        this.list = [];
        this.meta = { ...PaginationInitialMeta };
        this.detail = null;
        this.rootStore.AlbumStore.list = [];
        this.rootStore.PhotoStore.list = [];
        this.rootStore.AlbumStore.meta = { ...PaginationInitialMeta };
        this.rootStore.PhotoStore.meta = { ...PaginationInitialMeta };

        return true;
      }

      if (responseStatus !== 200 || !data?.data || !data?.meta) {
        this.list = [];
        this.meta = { ...PaginationInitialMeta };
        this.detail = null;
        this.rootStore.AlbumStore.list = [];
        this.rootStore.PhotoStore.list = [];
        this.rootStore.AlbumStore.meta = { ...PaginationInitialMeta };
        this.rootStore.PhotoStore.meta = { ...PaginationInitialMeta };

        return {
          error: {
            status: responseStatus,
          },
        };
      }

      this.list = [];
      this.meta = data.meta;
      this.list = data.data.map((item) => new Event(item));

      return true;
    } catch (error) {
      console.warn(error);

      this.list = [];
      this.meta = { ...PaginationInitialMeta };
      this.detail = null;
      this.rootStore.AlbumStore.list = [];
      this.rootStore.PhotoStore.list = [];
      this.rootStore.AlbumStore.meta = { ...PaginationInitialMeta };
      this.rootStore.PhotoStore.meta = { ...PaginationInitialMeta };

      return {
        error: {
          status: error?.response?.status || 400,
        },
      };
    }
  };

  getAllList = async (payload = {}) => {
    try {
      const { name, city_id, date, order_by, page = 1, per_page = 16 } = payload;

      this.list = [];
      this.meta = { ...PaginationInitialMeta };
      this.detail = null;
      this.rootStore.AlbumStore.list = [];
      this.rootStore.PhotoStore.list = [];
      this.rootStore.AlbumStore.meta = { ...PaginationInitialMeta };
      this.rootStore.PhotoStore.meta = { ...PaginationInitialMeta };

      const params = {};

      if (name?.length) params.name = name;
      if (city_id) params.city_id = city_id;
      if (date instanceof Date) params.date = formatDate(date, '-', 'en-US');

      if (page > 1) params.page = page;
      if (per_page !== 16) params.per_page = per_page;

      params.order_by = order_by ? 'asc' : 'desc';

      const response = await baseAPI.get('/events/all_public', {
        params,
      });

      const { status: responseStatus, data } = response;

      if (responseStatus === 204) {
        this.list = [];
        this.meta = { ...PaginationInitialMeta };
        this.detail = null;
        this.rootStore.AlbumStore.list = [];
        this.rootStore.PhotoStore.list = [];
        this.rootStore.AlbumStore.meta = { ...PaginationInitialMeta };
        this.rootStore.PhotoStore.meta = { ...PaginationInitialMeta };

        return true;
      }

      if (responseStatus !== 200 || !data?.data || !data?.meta) {
        this.list = [];
        this.meta = { ...PaginationInitialMeta };
        this.detail = null;
        this.rootStore.AlbumStore.list = [];
        this.rootStore.PhotoStore.list = [];
        this.rootStore.AlbumStore.meta = { ...PaginationInitialMeta };
        this.rootStore.PhotoStore.meta = { ...PaginationInitialMeta };

        return {
          error: {
            status: responseStatus,
          },
        };
      }

      this.list = [];
      this.meta = data.meta;
      this.list = data.data.map((item) => new Event(item));

      return true;
    } catch (error) {
      console.warn(error);

      this.list = [];
      this.meta = { ...PaginationInitialMeta };
      this.detail = null;
      this.rootStore.AlbumStore.list = [];
      this.rootStore.PhotoStore.list = [];
      this.rootStore.AlbumStore.meta = { ...PaginationInitialMeta };
      this.rootStore.PhotoStore.meta = { ...PaginationInitialMeta };

      return {
        error: {
          status: error?.response?.status || 400,
        },
      };
    }
  };

  getDetail = async (payload = {}) => {
    try {
      const { id } = payload;

      this.detail = null;
      this.rootStore.AlbumStore.list = [];
      this.rootStore.PhotoStore.list = [];
      this.rootStore.AlbumStore.meta = { ...PaginationInitialMeta };
      this.rootStore.PhotoStore.meta = { ...PaginationInitialMeta };

      const response = await baseAPI.get(`/events/${id}`);

      const { status, data } = response;

      if (status !== 200 || !data) {
        this.detail = null;
        this.rootStore.AlbumStore.list = [];
        this.rootStore.PhotoStore.list = [];
        this.rootStore.AlbumStore.meta = { ...PaginationInitialMeta };
        this.rootStore.PhotoStore.meta = { ...PaginationInitialMeta };

        return {
          error: {
            status,
          },
        };
      }

      this.detail = new Event(data);

      return true;
    } catch (error) {
      console.warn(error);

      this.detail = null;
      this.rootStore.AlbumStore.list = [];
      this.rootStore.PhotoStore.list = [];
      this.rootStore.AlbumStore.meta = { ...PaginationInitialMeta };
      this.rootStore.PhotoStore.meta = { ...PaginationInitialMeta };

      return {
        error: {
          status: error?.response?.status || 400,
        },
      };
    }
  };

  getDetailByAccessCode = async (payload = {}) => {
    try {
      const { access_code } = payload;

      this.detail = null;
      this.rootStore.AlbumStore.list = [];
      this.rootStore.PhotoStore.list = [];
      this.rootStore.AlbumStore.meta = { ...PaginationInitialMeta };
      this.rootStore.PhotoStore.meta = { ...PaginationInitialMeta };

      const response = await baseAPI.post('events/my-events', {
        access_code,
      });

      const { status, data } = response;

      if (status !== 200 || !data) {
        this.detail = null;
        this.rootStore.AlbumStore.list = [];
        this.rootStore.PhotoStore.list = [];
        this.rootStore.AlbumStore.meta = { ...PaginationInitialMeta };
        this.rootStore.PhotoStore.meta = { ...PaginationInitialMeta };

        return {
          error: {
            status,
          },
        };
      }

      this.detail = new Event(data);

      return true;
    } catch (error) {
      console.warn(error);

      this.detail = null;
      this.rootStore.AlbumStore.list = [];
      this.rootStore.PhotoStore.list = [];
      this.rootStore.AlbumStore.meta = { ...PaginationInitialMeta };
      this.rootStore.PhotoStore.meta = { ...PaginationInitialMeta };

      return {
        error: {
          status: error?.response?.status || 400,
        },
      };
    }
  };

  getPublicDetailByAccessCode = async (payload = {}) => {
    try {
      const { access_code } = payload;

      this.detail = null;
      this.rootStore.AlbumStore.list = [];
      this.rootStore.PhotoStore.list = [];
      this.rootStore.AlbumStore.meta = { ...PaginationInitialMeta };
      this.rootStore.PhotoStore.meta = { ...PaginationInitialMeta };

      const response = await baseAPI.get('events/my-event/photos', {
        params: {
          access_code,
        },
      });

      const { status, data } = response;

      if (status !== 200 || !data) {
        this.detail = null;
        this.rootStore.AlbumStore.list = [];
        this.rootStore.PhotoStore.list = [];
        this.rootStore.AlbumStore.meta = { ...PaginationInitialMeta };
        this.rootStore.PhotoStore.meta = { ...PaginationInitialMeta };

        return {
          error: {
            status,
          },
        };
      }

      this.detail = new Event(data);

      return true;
    } catch (error) {
      console.warn(error);

      this.detail = null;
      this.rootStore.AlbumStore.list = [];
      this.rootStore.PhotoStore.list = [];
      this.rootStore.AlbumStore.meta = { ...PaginationInitialMeta };
      this.rootStore.PhotoStore.meta = { ...PaginationInitialMeta };

      return {
        error: {
          status: error?.response?.status || 400,
        },
      };
    }
  };

  leave = async (payload = {}) => {
    try {
      const { id } = payload;

      const response = await baseAPI.post(`/events/${id}/remove-event`);

      const { status } = response;

      if (status !== 200) {
        return {
          error: {
            status,
          },
        };
      }

      return true;
    } catch (error) {
      console.warn(error);

      return {
        error: {
          status: error?.response?.status || 400,
        },
      };
    }
  };

  create = async (payload = {}) => {
    try {
      const {
        name,
        date,
        city_id,
        photo_price,
        collaborative,
        public_event,
        restricted_photos,
        category,
        cover,
        enable_package_1,
        package_1_quantity,
        package_1_price,
        enable_package_2,
        package_2_quantity,
        package_2_price,
        has_individual_price,
      } = payload;

      this.detail = null;
      this.rootStore.AlbumStore.list = [];
      this.rootStore.PhotoStore.list = [];
      this.rootStore.AlbumStore.meta = { ...PaginationInitialMeta };
      this.rootStore.PhotoStore.meta = { ...PaginationInitialMeta };

      const formData = new FormData();

      formData.append('name', name);
      formData.append('date', formatDate(date, '-', 'en-US'));
      formData.append('city_id', Number(city_id));
      formData.append('photo_price', Number(photo_price));
      formData.append('collaborative', Number(collaborative));
      formData.append('public', Number(public_event));
      formData.append('restricted_photos', Number(restricted_photos));
      formData.append('category_id', Number(category));
      formData.append('has_individual_price', has_individual_price ? 1 : 0);

      if (cover) formData.append('cover', cover);

      formData.append('packages[0][price]', Number(package_1_price));
      formData.append('packages[0][quantity]', Number(package_1_quantity));
      formData.append('packages[0][enabled]', enable_package_1 ? 1 : 0);

      formData.append('packages[1][price]', Number(package_2_price));
      formData.append('packages[1][quantity]', Number(package_2_quantity));
      formData.append('packages[1][enabled]', enable_package_2 ? 1 : 0);

      const response = await baseAPI.post('/events', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const { status, data } = response;

      if (status !== 201 || !data?.id) {
        this.detail = null;
        this.rootStore.AlbumStore.list = [];
        this.rootStore.PhotoStore.list = [];
        this.rootStore.AlbumStore.meta = { ...PaginationInitialMeta };
        this.rootStore.PhotoStore.meta = { ...PaginationInitialMeta };

        return {
          error: {
            status,
          },
        };
      }

      this.detail = new Event(data);

      return data;
    } catch (error) {
      console.warn(error);

      this.detail = null;
      this.rootStore.AlbumStore.list = [];
      this.rootStore.PhotoStore.list = [];
      this.rootStore.AlbumStore.meta = { ...PaginationInitialMeta };
      this.rootStore.PhotoStore.meta = { ...PaginationInitialMeta };

      return {
        error: {
          status: error?.response?.status || 400,
        },
      };
    }
  };

  edit = async (payload = {}) => {
    try {
      const {
        id,
        name,
        date,
        city_id,
        photo_price,
        collaborative,
        public_event,
        restricted_photos,
        category,
        cover,
        enable_package_1,
        package_1_quantity,
        package_1_price,
        enable_package_2,
        package_2_quantity,
        package_2_price,
        has_individual_price,
      } = payload;

      this.detail = null;
      this.rootStore.AlbumStore.list = [];
      this.rootStore.PhotoStore.list = [];
      this.rootStore.AlbumStore.meta = { ...PaginationInitialMeta };
      this.rootStore.PhotoStore.meta = { ...PaginationInitialMeta };

      const formData = new FormData();

      formData.append('name', name);
      formData.append('date', formatDate(date, '-', 'en-US'));
      formData.append('city_id', Number(city_id));
      formData.append('photo_price', Number(photo_price));
      formData.append('collaborative', Number(collaborative));
      formData.append('public', Number(public_event));
      formData.append('restricted_photos', Number(restricted_photos));
      formData.append('category_id', Number(category));
      formData.append('has_individual_price', has_individual_price ? 1 : 0);

      if (cover instanceof File) formData.append('cover', cover);

      formData.append('packages[0][price]', Number(package_1_price));
      formData.append('packages[0][quantity]', Number(package_1_quantity));
      formData.append('packages[0][enabled]', enable_package_1 ? 1 : 0);

      formData.append('packages[1][price]', Number(package_2_price));
      formData.append('packages[1][quantity]', Number(package_2_quantity));
      formData.append('packages[1][enabled]', enable_package_2 ? 1 : 0);

      formData.append('_method', 'put');

      const response = await baseAPI.post(`/events/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const { status, data } = response;

      if (status !== 200 || !data?.id) {
        this.detail = null;
        this.rootStore.AlbumStore.list = [];
        this.rootStore.PhotoStore.list = [];
        this.rootStore.AlbumStore.meta = { ...PaginationInitialMeta };
        this.rootStore.PhotoStore.meta = { ...PaginationInitialMeta };

        return {
          error: {
            status,
          },
        };
      }

      this.detail = new Event(data);

      return data;
    } catch (error) {
      console.warn(error);

      this.detail = null;
      this.rootStore.AlbumStore.list = [];
      this.rootStore.PhotoStore.list = [];
      this.rootStore.AlbumStore.meta = { ...PaginationInitialMeta };
      this.rootStore.PhotoStore.meta = { ...PaginationInitialMeta };

      return {
        error: {
          status: error?.response?.status || 400,
        },
      };
    }
  };

  setStatus = async (payload = {}) => {
    try {
      const { id, status } = payload;

      const response = await baseAPI.patch(`/events/${id}/status`, {
        enabled: status,
      });

      const { status: responseStatus, data } = response;

      if (responseStatus !== 200 || !data) {
        return {
          error: {
            status: responseStatus,
          },
        };
      }

      this.detail = new Event(data);

      return true;
    } catch (error) {
      console.warn(error);

      return {
        error: {
          status: error?.response?.status || 400,
        },
      };
    }
  };

  remove = async (payload = {}) => {
    try {
      const { id } = payload;

      const response = await baseAPI.delete(`/events/${id}`);

      const { status } = response;

      if (status !== 200) {
        return {
          error: {
            status,
          },
        };
      }

      return true;
    } catch (error) {
      console.warn(error);

      return {
        error: {
          status: error?.response?.status || 400,
        },
      };
    }
  };
}
