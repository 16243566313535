import { makeObservable, observable } from 'mobx';

export default class Photo {
  id = null;
  width = null;
  height = null;
  preview_url = null;
  thumbnail_url = null;
  original_filename = null;
  photo_frontend_optimized = null;
  photo_status = 0;
  dpi = null;
  price = null;
  event = null;
  owner = null;
  restricted = null;

  constructor(newPhoto = {}) {
    makeObservable(this, {
      id: observable,
      width: observable,
      height: observable,
      preview_url: observable,
      thumbnail_url: observable,
      original_filename: observable,
      photo_frontend_optimized: observable,
      photo_status: observable,
      dpi: observable,
      price: observable,
      event: observable,
      owner: observable,
      restricted: observable,
    });

    if (newPhoto.id == null) {
      throw new Error('Invalid Photo constructor');
    }

    const {
      id,
      width,
      height,
      preview_url,
      thumbnail_url,
      original_filename,
      photo_frontend_optimized,
      photo_status,
      dpi,
      price,
      event,
      owner,
      restricted,
    } = newPhoto;

    this.id = id;
    this.width = width || null;
    this.height = height || null;
    this.preview_url = preview_url || '';
    this.thumbnail_url = thumbnail_url || '';
    this.original_filename = original_filename || '';
    this.photo_frontend_optimized = photo_frontend_optimized || false;
    this.photo_status = photo_status || 0;
    this.dpi = dpi || null;
    this.price = price || null;
    this.event = event || null;
    this.owner = owner || null;
    this.restricted = restricted || null;
  }
}
