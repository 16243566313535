import PropTypes from 'prop-types';

const VerifiedIcon = (props) => {
  const { color } = props;

  return (
    <svg
      width='34'
      height='32'
      viewBox='0 0 34 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M33.5 16.0001L29.84 11.8301L30.35 6.31006L24.935 5.08006L22.1 0.310059L17 2.50006L11.9 0.310059L9.065 5.08006L3.65 6.29506L4.16 11.8151L0.5 16.0001L4.16 20.1701L3.65 25.7051L9.065 26.9351L11.9 31.7051L17 29.5001L22.1 31.6901L24.935 26.9201L30.35 25.6901L29.84 20.1701L33.5 16.0001ZM14 23.5001L8 17.5001L10.115 15.3851L14 19.2551L23.885 9.37006L26 11.5001L14 23.5001Z'
        fill={color}
      />
    </svg>
  );
};

VerifiedIcon.propTypes = {
  color: PropTypes.string,
};

VerifiedIcon.defaultProps = {
  color: '#2962FF',
};

export default VerifiedIcon;
