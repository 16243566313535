import { makeAutoObservable } from 'mobx';

import PaginationInitialMeta from '../../constants/PaginationInitialMeta';
import baseAPI from '../../services/baseAPI';
import { formatDate } from '../../utils';
import Photo from '../models/Photo';

export default class PhotoStore {
  rootStore;
  meta = { ...PaginationInitialMeta };
  list = [];
  detail = null;

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this, { rootStore: false });
  }

  getList = async (payload = {}) => {
    try {
      const { event_id, album_id, page = 1, per_page = 16 } = payload;

      this.list = [];
      this.meta = { ...PaginationInitialMeta };

      const params = {};

      if (page > 1) params.page = page;
      if (per_page !== 16) params.per_page = per_page;

      const response =
        this.rootStore.UserStore.profile?.type === 1
          ? await baseAPI.get(`/events/${event_id}/albums/${album_id}/photos`, {
              params,
            })
          : await baseAPI.post(
              `/events/photos?event=${event_id}`,
              {},
              {
                params,
              },
            );

      const { status, data } = response;

      if (status === 204) {
        this.list = [];
        this.meta = { ...PaginationInitialMeta };

        return { empty: true };
      }

      if (status !== 200 || !data?.data || !data?.meta) {
        this.list = [];
        this.meta = { ...PaginationInitialMeta };

        return {
          error: {
            status,
          },
        };
      }

      this.meta = data.meta;
      this.list = data.data.map((item) => new Photo(item));

      return true;
    } catch (error) {
      console.warn(error);

      this.list = [];
      this.meta = { ...PaginationInitialMeta };

      return {
        error: {
          status: error?.response?.status || 400,
        },
      };
    }
  };

  getUserPhotoList = async (payload = {}) => {
    try {
      const { user, name, city_id, date, order_by, page = 1, per_page = 16 } = payload;

      this.list = [];
      this.meta = { ...PaginationInitialMeta };

      const params = {};

      if (name?.length) params.name = name;
      if (city_id) params.city_id = city_id;
      if (date instanceof Date) params.date = formatDate(date, '-', 'en-US');
      if (!user) params.user = 1;

      if (page > 1) params.page = page;
      if (per_page !== 16) params.per_page = per_page;

      params.order_by = order_by ? 'asc' : 'desc';

      const response = await baseAPI.post(
        `/events/photos`,
        {},
        {
          params,
        },
      );

      const { status, data } = response;

      if (status === 204) {
        this.list = [];
        this.meta = { ...PaginationInitialMeta };
        return true;
      }

      if (status !== 200 || !data?.data?.length || !data?.meta) {
        this.list = [];
        this.meta = { ...PaginationInitialMeta };

        return {
          error: {
            status,
          },
        };
      }

      this.meta = data.meta;
      this.list = data.data.map((item) => new Photo(item));

      return true;
    } catch (error) {
      console.warn(error);

      this.list = [];
      this.meta = { ...PaginationInitialMeta };

      return {
        error: {
          status: error?.response?.status || 400,
        },
      };
    }
  };

  getDetail = async (payload = {}) => {
    try {
      const { event_id, album_id } = payload;

      this.detail = null;

      const response = await baseAPI.get(`/events/${event_id}/albums/${album_id}`);

      const { status, data } = response;

      if (status !== 200 || !data) {
        this.detail = null;

        return {
          error: {
            status,
          },
        };
      }

      this.detail = new Photo(data);

      return true;
    } catch (error) {
      console.warn(error);

      this.detail = null;

      return {
        error: {
          status: error?.response?.status || 400,
        },
      };
    }
  };

  remove = async (payload = {}) => {
    try {
      const { photos_id } = payload;

      const response = await baseAPI.delete(`/events/photos/destroy`, {
        data: {
          photo_id: photos_id,
        },
      });

      const { status } = response;

      if (status !== 202) {
        return {
          error: {
            status,
          },
        };
      }

      return true;
    } catch (error) {
      console.warn(error);

      return {
        error: {
          status: error?.response?.status || 400,
        },
      };
    }
  };

  download = async (payload = {}) => {
    try {
      const { id } = payload;

      const response = await baseAPI.get(`/events/photos/${id}/original`);

      const { status, data } = response;

      if (status !== 200 || !data?.length) {
        return {
          error: {
            status,
          },
        };
      }

      const downloadLink = document.createElement('a');

      downloadLink.href = data;

      downloadLink.download = `Rokkos_${id}_${new Date().getTime()}.jpg`;

      downloadLink.click();

      return true;
    } catch (error) {
      console.warn(error);

      return {
        error: {
          status: error?.response?.status || 400,
        },
      };
    }
  };
}
