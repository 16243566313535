import { useHistory } from 'react-router-dom';

import { Button, Screen, Text } from '../../components';
import { EmptyStateCalendarSearchIcon } from '../../components/svg';
import * as Theme from '../../constants/Theme';

const EventNotFoundScreen = () => {
  const history = useHistory();

  return (
    <Screen className='not-found'>
      <div className='not-found__image'>
        <EmptyStateCalendarSearchIcon />
      </div>
      <Text variant='h5' className='not-found__title' responsive>
        Não encontramos o evento que você estava procurando.
      </Text>
      <Text variant='h6' className='not-found__description' color={Theme.light60} responsive>
        Confira o código de acesso e tente novamente.
      </Text>
      <Button variant='contained' onClick={() => history.push('/')}>
        Ir para página inicial
      </Button>
    </Screen>
  );
};

export default EventNotFoundScreen;
