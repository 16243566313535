import { Container } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  AlbumHeader,
  Button,
  EmptyState,
  ListLoader,
  Loader,
  PhotoGrid,
  PhotoList,
  Screen,
  Text,
} from '../../components';
import { EmptyStatePhotoSearchIcon, GridIcon, ListIcon } from '../../components/svg';
import { SYSTEM_INSTABILITY } from '../../constants/Messages';
import * as Theme from '../../constants/Theme';
import { useStore } from '../../hooks';
import './styles.scss';

const AlbumDetailScreen = () => {
  const store = useStore();
  const params = useParams();
  const history = useHistory();

  const [mounted, setMounted] = useState(false);
  const [requesting, setRequesting] = useState(true);
  const [requestingPhotos, setRequestingPhotos] = useState(true);
  const [page, setPage] = useState(1);
  const [listType, setListType] = useState('grid');

  const user = store.UserStore.profile;
  const album = store.AlbumStore.detail;
  const photos = store.PhotoStore.list;
  const meta = store.PhotoStore.meta;

  const getAlbumDetail = async () => {
    try {
      setRequesting(true);

      const response = await flowResult(
        store.AlbumStore.getDetail({
          event_id: params.id,
          album_id: params.albumId,
        }),
      );

      if (response.error) toast.error(SYSTEM_INSTABILITY);
    } catch (error) {
      console.log(error);
    } finally {
      setRequesting(false);
    }
  };

  const getPhotos = async () => {
    try {
      setRequestingPhotos(true);

      const response = await flowResult(
        store.PhotoStore.getList({
          event_id: params.id,
          album_id: params.albumId,
          page,
        }),
      );

      if (response.error) {
        toast.error(SYSTEM_INSTABILITY);
        history.push(`/event/${params.id}`);
      }

      if (response.empty) {
        history.push(`/event/${params.id}`);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setRequestingPhotos(false);
      setRequesting(false);
    }
  };

  const renderPhotoGrid = () => {
    if (requestingPhotos) {
      return <ListLoader variant='grid' length={16} interval={300} />;
    }

    if (mounted && !requestingPhotos && !photos?.length) {
      return null;
    }

    return (
      <PhotoGrid
        photos={photos}
        showActionButton={user?.type === 1 && user?.id === album?.owner?.id}
        onDeletePhoto={getPhotos}
      />
    );
  };

  const renderPhotoList = () => {
    if (requestingPhotos) {
      return <Loader color={Theme.primary} />;
    }

    if (mounted && !requestingPhotos && !photos?.length) {
      return (
        <EmptyState
          icon={<EmptyStatePhotoSearchIcon />}
          title='Nenhuma foto sua foi encontrada neste album.'
          description='Conheça outros eventos da Rokkos!'
          button={
            <Button variant='contained' onClick={() => history.push('/events/all')}>
              Conferir eventos
            </Button>
          }
        />
      );
    }

    return (
      <PhotoList
        photos={photos}
        variant={user?.type === 1 ? 'photographer' : 'shopper'}
        showActionButton={user?.type === 1 && user?.id === album?.owner?.id}
        onDeletePhoto={onDeletePhoto}
      />
    );
  };

  const renderAlbumContent = () => {
    if (listType === 'list') return renderPhotoList();

    return renderPhotoGrid();
  };

  const onDeletePhoto = () => {
    setPage(1);
    getPhotos();
  };

  const init = async () => {
    setPage(1);
    await getAlbumDetail();
    await getPhotos();

    setMounted(true);
  };

  useEffect(() => {
    if (!mounted) return;

    getPhotos();

    window.scrollTo(0, 136);
  }, [page, mounted]);

  useEffect(() => {
    init();
  }, []);

  return (
    <Screen className='album-detail' container={false}>
      {requesting || !album ? (
        <Loader color={Theme.primary} paddingVertical={32} />
      ) : (
        <>
          <AlbumHeader album={album} />
          <Container maxWidth={Theme.containerMaxWidth}>
            {photos?.length && user?.id === album?.owner?.id && user?.type === 1 ? (
              <div className='album-detail__list-type'>
                <div className='album-detail__list-type__label'>
                  <Text variant='h6' color={Theme.primary}>
                    Visualizar:
                  </Text>
                </div>
                <Button
                  className='album-detail__list-type__button'
                  onClick={() => setListType('grid')}
                  title='Grid'
                >
                  <GridIcon color={listType === 'grid' ? Theme.light87 : Theme.light60} />
                </Button>
                <Button
                  className='album-detail__list-type__button'
                  onClick={() => setListType('list')}
                  title='Lista'
                >
                  <ListIcon color={listType === 'list' ? Theme.light87 : Theme.light60} />
                </Button>
              </div>
            ) : null}
            {renderAlbumContent()}
            {mounted && photos?.length && meta?.last_page > 1 ? (
              <Pagination
                page={page}
                count={meta.last_page}
                color='primary'
                onChange={(_, page) => setPage(page)}
                disabled={requestingPhotos}
              />
            ) : null}
          </Container>
        </>
      )}
    </Screen>
  );
};

export default observer(AlbumDetailScreen);
