import PropTypes from 'prop-types';

import * as Theme from '../../../constants/Theme';

const MinimizeIcon = (props) => {
  const { color, size } = props;

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 13 13'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='minimize-icon'
    >
      <line x1='4.5' y1='4.37114e-08' x2='4.5' y2='5' stroke={color} />
      <line x1='5' y1='4.5' x2='4.37114e-08' y2='4.5' stroke={color} />
      <line x1='-5.96244e-09' y1='8.5' x2='5' y2='8.5' stroke={color} />
      <line x1='4.5' y1='8' x2='4.5' y2='13' stroke={color} />
      <line x1='8.5' y1='13' x2='8.5' y2='8' stroke={color} />
      <line x1='8' y1='8.5' x2='13' y2='8.5' stroke={color} />
      <line x1='13' y1='4.5' x2='8' y2='4.5' stroke={color} />
      <line x1='8.5' y1='5' x2='8.5' y2='8.14603e-08' stroke={color} />
    </svg>
  );
};

MinimizeIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

MinimizeIcon.defaultProps = {
  color: Theme.light60,
  size: 24,
};

export default MinimizeIcon;
