import { useFormik } from 'formik';
import { flowResult } from 'mobx';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';

import { Button, Input, Logo, Screen, Text } from '../../components';
import { passwordChangeFormDefinitions } from '../../constants/FormDefinitions';
import { PASSWORD_CHANGE_SUCCESS, SYSTEM_INSTABILITY } from '../../constants/Messages';
import * as Theme from '../../constants/Theme';
import { useStore } from '../../hooks';
import './styles.scss';

const ChangePasswordScreen = () => {
  const store = useStore();
  const history = useHistory();
  const params = useParams();

  const { initialValues, validationSchema } = passwordChangeFormDefinitions;

  const onSubmit = async (formValues) => {
    try {
      const { token, email } = params;

      if (!token || !email) {
        const response = await flowResult(store.UserStore.redefinePassword(formValues));

        if (response?.error) {
          switch (response?.error?.status) {
            default: {
              toast.error(SYSTEM_INSTABILITY);
              return;
            }
          }
        }
      } else {
        const response = await flowResult(
          store.UserStore.changePassword({
            ...formValues,
            token,
            email,
          }),
        );

        if (response?.error) {
          switch (response?.error?.status) {
            default: {
              toast.error(SYSTEM_INSTABILITY);
              return;
            }
          }
        }
      }

      toast.success(PASSWORD_CHANGE_SUCCESS);
      history.push('/');
    } catch (error) {
      console.log(error);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit,
  });

  return (
    <Screen className='change-password'>
      <main
        style={{
          pointerEvents: formik.isSubmitting ? 'none' : 'initial',
        }}
      >
        <div className='change-password__logo'>
          <Logo big />
        </div>
        <div className='change-password__title'>
          <Text variant='h5' responsive color={Theme.primary}>
            Alterar Senha
          </Text>
        </div>
        <form onSubmit={formik.handleSubmit} noValidate>
          <div className='change-password__inputs'>
            <div className='change-password__inputs__input-wrapper'>
              <Input
                variant='filled'
                placeholder='Nova senha'
                fullWidth
                id='password'
                value={formik.values.password}
                error={formik.touched.password && !!formik.errors.password}
                helperText={formik.touched.password && formik.errors.password}
                onChange={formik.handleChange}
                secureText
              />
            </div>
            <div className='change-password__inputs__input-wrapper'>
              <Input
                variant='filled'
                placeholder='Confirmar nova senha'
                fullWidth
                id='password_confirmation'
                value={formik.values.password_confirmation}
                error={
                  formik.touched.password_confirmation && !!formik.errors.password_confirmation
                }
                helperText={
                  formik.touched.password_confirmation && formik.errors.password_confirmation
                }
                onChange={formik.handleChange}
                secureText
              />
            </div>
          </div>
          <div className='change-password__actions'>
            <Button variant='contained' fullWidth loading={formik.isSubmitting} type='submit'>
              Alterar senha
            </Button>
          </div>
        </form>
      </main>
    </Screen>
  );
};

export default ChangePasswordScreen;
