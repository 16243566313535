import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Button, Screen, Stepper, Text } from '../../components';
import { Rocket } from '../../components/svg';
import * as Theme from '../../constants/Theme';
import { useStore } from '../../hooks';
import './styles.scss';

const CheckoutConfirmationScreen = () => {
  const history = useHistory();
  const store = useStore();

  const [rocketIsVisible, setRocketIsVisible] = useState(false);

  const orders = store.CheckoutStore.checkoutConfirmation.photographerOrders.reduce(
    (acc, order) => acc + order.photos.length,
    0,
  );

  const getRocketStyle = () => {
    if (rocketIsVisible) return { opacity: 1, transform: 'translate3d(0,0,0)' };

    return { opacity: 0, transform: 'translate3d(-20px,20px,0)' };
  };

  useEffect(() => {
    if (!store.CheckoutStore.checkoutConfirmation) {
      history.push('/checkout/cart');
    }

    setTimeout(() => {
      setRocketIsVisible(true);
    }, 250);
  }, []);

  return (
    <Screen className='checkout-confirmation'>
      <main>
        <div className='checkout__header'>
          <Text variant='h5' responsive color={Theme.primary}>
            Comprar
          </Text>
        </div>
        <div className='checkout__stepper'>
          <Stepper
            activeStep={1}
            steps={[
              {
                label: 'Compra',
                completed: true,
              },
              {
                label: 'Confirmação',
              },
            ]}
          />
        </div>
        <div className='checkout__surface surface-7'>
          <div className='checkout__surface__title'>
            <Text variant='h5'>Confirmação de pedido</Text>
          </div>

          <div className='checkout-confirmation__text'>
            <Text variant='h6' color={Theme.light60}>
              Eba! Seu pagamento foi realizado com sucesso!
            </Text>
          </div>

          <div className='checkout-confirmation__rocket' style={getRocketStyle()}>
            <Rocket />
          </div>

          {store.CheckoutStore.checkoutConfirmation ? (
            <div className='checkout-confirmation__text'>
              <Text variant='h6' color={Theme.light60}>
                {`A${orders > 1 ? 's' : ''} `}
                {orders > 1 ? (
                  <span className='checkout-confirmation__count'>{orders}</span>
                ) : null}{' '}
                {`foto${orders > 1 ? 's' : ''} `} que você comprou já{' '}
                {`est${orders > 1 ? 'ão' : 'á'} `} {`disponíve${orders > 1 ? 'is' : 'l'} `} para
                download através do botão abaixo:
              </Text>
            </div>
          ) : null}

          <div className='checkout-confirmation__action'>
            <Button variant='contained' onClick={() => history.push('/collection')}>
              Baixar minhas fotos
            </Button>
          </div>
        </div>
      </main>
    </Screen>
  );
};

export default observer(CheckoutConfirmationScreen);
