import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Button, Screen, Text } from '../../components';
import { NotFound } from '../../components/svg';
import * as Theme from '../../constants/Theme';
import { useQuery } from '../../hooks';
import './styles.scss';

const NotFoundScreen = () => {
  const history = useHistory();
  const query = useQuery();
  const location = useLocation();

  useEffect(() => {
    const redirectUri = query.get('redirect');
    const pathName = location?.pathname;

    if (redirectUri?.length) history.push(redirectUri);
    else if (['/login', '/login/', '/events', '/event/'].includes(pathName)) {
      history.push('/');
    }
  }, []);

  return (
    <Screen className='not-found'>
      <div className='not-found__image'>
        <NotFound />
      </div>
      <Text variant='h5' className='not-found__title' responsive>
        Opa! Não encontramos a página que você estava procurando.
      </Text>
      <Text variant='h6' className='not-found__description' color={Theme.light60} responsive>
        Mas ainda podemos te ajudar! Clique abaixo e volte a navegar na Rokkos.
      </Text>
      <Button variant='contained' onClick={() => history.push('/')}>
        Ir para página inicial
      </Button>
    </Screen>
  );
};

export default NotFoundScreen;
