import { Pagination } from '@material-ui/lab';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Button, CartListItem, EmptyState, Loader, Screen, Text } from '../../components';
import { EmptyStatePhotoSearchIcon } from '../../components/svg';
import { SYSTEM_INSTABILITY } from '../../constants/Messages';
import * as Theme from '../../constants/Theme';
import { useStore } from '../../hooks';
import './styles.scss';

const CollectionScreen = () => {
  const history = useHistory();
  const store = useStore();
  const [mounted, setMounted] = useState(false);
  const [requesting, setRequesting] = useState(true);
  const [page, setPage] = useState(1);

  const orders = store.UserStore.collectionList;

  const getCollection = async () => {
    try {
      setRequesting(true);

      const response = await flowResult(store.UserStore.getCollection({ page }));

      if (response.error) toast.error(SYSTEM_INSTABILITY);
    } catch (error) {
      console.log(error);
    } finally {
      setRequesting(false);
    }
  };

  const renderCollection = () => {
    if (requesting) return <Loader color={Theme.primary} />;

    if (mounted && !requesting && !orders?.length) {
      return (
        <EmptyState
          icon={<EmptyStatePhotoSearchIcon />}
          title='Você ainda não possui compras'
          description='Conheça o acervo de fotos da Rokkos! Se você adquiriu fotos por PIX e já realizou o pagamento, aguarde alguns minutos até a compra ser confirmada pelo nosso sistema'
          button={
            <Button variant='contained' onClick={() => history.push('/')}>
              Conferir fotos
            </Button>
          }
        />
      );
    }

    return (
      <div className='checkout__surface surface-7'>
        <div className='collection__list-header'>
          <div className='collection__list-header__photo'>
            <Text variant='body-1' color={Theme.primary}>
              Miniatura
            </Text>
          </div>
          <div className='collection__list-header__id'>
            <Text variant='body-1' color={Theme.primary}>
              Data
            </Text>
          </div>
          <div className='collection__list-header__owner'>
            <Text variant='body-1' color={Theme.primary}>
              Fotógrafo
            </Text>
          </div>
          <div className='collection__list-header__resolution'>
            <Text variant='body-1' color={Theme.primary}>
              Resolução
            </Text>
          </div>
          <div className='collection__list-header__price'>
            <Text variant='body-1' color={Theme.primary}>
              Download
            </Text>
          </div>
        </div>

        <div className='collection__list'>
          {orders.map((order) =>
            order.photos
              .filter((photo) => photo)
              .map((photo) => (
                <CartListItem
                  key={photo.id}
                  photo={{
                    ...photo,
                    owner: order.photographer,
                    date: order.created_at,
                  }}
                  variant='download'
                  showBtRemove={false}
                />
              )),
          )}
        </div>
      </div>
    );
  };

  const init = async () => {
    await getCollection();
    setMounted(true);
  };

  useEffect(() => {
    if (!mounted) return;

    getCollection();

    if (window.innerWidth > 1024) {
      window.scrollTo(0, 140);
      return;
    }

    window.scrollTo(0, 90);
  }, [page]);

  useEffect(() => {
    init();
  }, []);

  return (
    <Screen className='collection'>
      <main>
        <div className='checkout__header'>
          <Text variant='h5' responsive color={Theme.primary}>
            Minhas compras
          </Text>
        </div>

        {renderCollection()}

        {mounted && orders?.length && store.UserStore.collectionMeta?.last_page > 1 ? (
          <Pagination
            page={page}
            count={store.UserStore.collectionMeta.last_page}
            color='primary'
            onChange={(_, page) => setPage(page)}
            disabled={requesting}
          />
        ) : null}
      </main>
    </Screen>
  );
};

export default observer(CollectionScreen);
