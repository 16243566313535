/* eslint-disable no-undef */
import Axios from 'axios';

const baseAPI = Axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api`,
  timeout: 40000,
  validateStatus: (status) => status !== 401,
});

export default baseAPI;
