/* eslint-disable no-undef */
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import packageJson from '../package.json';
import { Routes, ThemeProvider } from './components';
import initServices from './services';
import { RootStoreProvider } from './stores';

if (process.env.REACT_APP_PROD) initServices();

const App = () => {
  if (packageJson?.version) console.log(`v${packageJson.version}`);

  return (
    <div className='app'>
      <RootStoreProvider>
        <ThemeProvider>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <BrowserRouter>
              <Routes />
            </BrowserRouter>
            <ToastContainer position='bottom-right' />
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </RootStoreProvider>
    </div>
  );
};

export default App;
