import { makeAutoObservable } from 'mobx';

import { useLocalStorage } from '../../hooks';
import baseAPI from '../../services/baseAPI';
import User from '../models/User';

export default class AuthStore {
  rootStore;
  status = 'unauthenticated';

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this, { rootStore: false });
  }

  unauthenticate = () => {
    this.status = 'unauthenticated';

    this.rootStore.UserStore.profile = null;

    this.rootStore.CheckoutStore.cart = null;
    this.rootStore.CheckoutStore.checkoutConfirmation = null;

    this.rootStore.UploadStore.resetQueue();

    localStorage.removeItem('rokkos@token');

    baseAPI.defaults.headers.common.Authorization = null;
  };

  authenticate = async (payload = {}) => {
    try {
      this.status = 'fetching';

      const { type, email, password, storedToken } = payload;

      const [, setStoredToken] = useLocalStorage('token');

      this.rootStore.EventStore.list = [];
      this.rootStore.AlbumStore.list = [];
      this.rootStore.PhotoStore.list = [];

      if (storedToken?.length) {
        baseAPI.defaults.headers.common.Authorization = `Bearer ${storedToken}`;

        await this.rootStore.UserStore.getUser();

        if (this.rootStore.UserStore.profile?.type === 1) {
          await this.rootStore.UserStore.getPublicUser();
        } else {
          this.rootStore.CheckoutStore.getCart();
        }

        if (!this.rootStore.UserStore.profile?.id) {
          this.unauthenticate();
          return false;
        }

        await this.rootStore.ConstantStore.getEventCategories();
        await this.rootStore.ConstantStore.getBanks();

        this.status = 'authenticated';

        return true;
      }

      const response = await baseAPI.post('/auth', {
        type,
        email,
        password,
      });

      const { status, data } = response;

      if (status !== 200 || !data?.token?.length || !data?.user) {
        this.unauthenticate();

        return {
          error: {
            status,
          },
        };
      }

      const { token, user } = data;

      setStoredToken(token);

      baseAPI.defaults.headers.common.Authorization = `Bearer ${token}`;

      this.rootStore.UserStore.profile = new User(user);

      if (this.rootStore.UserStore.profile?.type === 1) {
        await this.rootStore.UserStore.getPublicUser();
      } else {
        this.rootStore.CheckoutStore.getCart();
      }

      await this.rootStore.ConstantStore.getEventCategories();
      await this.rootStore.ConstantStore.getBanks();

      this.status = 'authenticated';

      return true;
    } catch (error) {
      console.warn(error);

      this.unauthenticate();

      return {
        error: {
          status: error?.response?.status || 400,
        },
      };
    }
  };

  oAuth = async (payload = {}) => {
    try {
      const { accessToken } = payload;

      const [, setStoredToken] = useLocalStorage('token');

      // this.rootStore.CheckoutStore.cart = null;
      // this.rootStore.CheckoutStore.checkoutConfirmation = null;

      const response = await baseAPI.post(`/oauth?token=${accessToken}`);

      const { status, data } = response;

      if (status !== 200 || !data?.token?.length || !data?.user) {
        this.unauthenticate();

        return {
          error: {
            status,
          },
        };
      }

      const { token, user } = data;

      setStoredToken(token);

      baseAPI.defaults.headers.common.Authorization = `Bearer ${token}`;

      this.rootStore.UserStore.profile = new User(user);

      if (this.rootStore.UserStore.profile?.type === 1) {
        await this.rootStore.UserStore.getPublicUser();
      } else {
        this.rootStore.CheckoutStore.getCart();
      }

      await this.rootStore.ConstantStore.getEventCategories();
      await this.rootStore.ConstantStore.getBanks();

      this.status = 'authenticated';

      return true;
    } catch (error) {
      console.warn(error);

      this.unauthenticate();

      return {
        error: {
          status: error?.response?.status || 400,
        },
      };
    }
  };

  init = () => {
    baseAPI.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401) this.unauthenticate();
        return error;
      },
    );

    const [storedToken] = useLocalStorage('token');

    if (storedToken?.length) this.authenticate({ storedToken });
  };
}
