import { useFormik } from 'formik';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Button, CheckoutLoader, Input, Loader, Screen, Stepper, Text } from '../../components';
import { checkoutFormDefinitions } from '../../constants/FormDefinitions';
import { SYSTEM_INSTABILITY } from '../../constants/Messages';
import * as Theme from '../../constants/Theme';
import { useStore } from '../../hooks';

import { getAddressByZipCode } from '../../utils';
import './styles.scss';

const CheckoutScreen = () => {
  const history = useHistory();
  const store = useStore();
  const [requestingAddress, setRequestingAddress] = useState(false);
  const [mounted, setMounted] = useState(false);

  const { initialValues, validationSchema } = checkoutFormDefinitions;
  const user = store.UserStore.profile;

  const getAddress = async (zipCode) => {
    try {
      setRequestingAddress(true);

      const address = await getAddressByZipCode(zipCode);

      if (address) {
        formik.setValues((values) => ({ ...values, ...address }));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setRequestingAddress(false);
    }
  };

  const onSubmit = async (formValues) => {
    try {
      let response;

      response = await flowResult(store.CheckoutStore.checkout(formValues));

      if (response?.error) {
        switch (response?.error?.status) {
          default: {
            toast.error(response.error?.message || SYSTEM_INSTABILITY, { autoClose: false });
            return;
          }
        }
      }

      console.log('response', response);

      if (response.free_checkout) {
        history.push('/checkout/free-confirmation');
      } else {
        history.push('/checkout/pagarme-confirmation');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      ...initialValues,
      ...user.address,
      cpf: user.cpf_cnpj,
      phone: `${user.phone_prefix}${user.phone}`,
      zip: String(user.address.zip).replace('-', ''),
      payment_method: 'pix',
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit,
  });

  useEffect(() => {
    if (formik.values?.zip?.length >= 8 && mounted) {
      getAddress(formik.values.zip);
    }
  }, [formik.values.zip]);

  useEffect(() => {
    if (store.CheckoutStore.cart?.cart_events?.length < 1) history.push('/checkout/cart');
    setMounted(true);
  }, []);

  return (
    <Screen className='checkout'>
      <main
        style={{
          pointerEvents: formik.isSubmitting ? 'none' : 'initial',
        }}
      >
        <div className='checkout__header'>
          <Text variant='h5' responsive color={Theme.primary}>
            Comprar
          </Text>
        </div>
        <div className='checkout__stepper'>
          <Stepper
            activeStep={0}
            steps={[
              {
                label: 'Compra',
              },
              {
                label: 'Pagamento',
              },
            ]}
          />
        </div>

        <form
          className='checkout__form'
          noValidate
          onSubmit={formik.handleSubmit}
          style={{
            pointerEvents: formik.isSubmitting ? 'none' : 'initial',
          }}
        >
          <div className='checkout__surface surface-7 checkout__form__board address'>
            <div className='checkout__surface__title'>
              <Text variant='h5'>Dados de cobrança</Text>
            </div>

            <div className='checkout__fields'>
              <Text variant='h6' className='cobranca_instructions'>
                Os dados a seguir são necessários para geração do pagamento:
              </Text>

              <div className='checkout__field'>
                <Input
                  variant='filled'
                  placeholder='CPF'
                  fullWidth
                  id='cpf'
                  value={String(formik.values.cpf)}
                  error={formik.touched.cpf && !!formik.errors.cpf}
                  helperText={formik.touched.cpf && formik.errors.cpf}
                  onChange={formik.handleChange}
                  mask='cpf'
                />
              </div>

              <div className='checkout__field'>
                <Input
                  variant='filled'
                  placeholder='Telefone'
                  fullWidth
                  id='phone'
                  value={formik.values.phone}
                  error={formik.touched.phone && !!formik.errors.phone}
                  helperText={formik.touched.phone && formik.errors.phone}
                  disabled={requestingAddress}
                  onChange={formik.handleChange}
                  endAdornment={requestingAddress ? <Loader /> : null}
                  mask='phone'
                />
              </div>

              <div className='checkout__field'>
                <Input
                  variant='filled'
                  placeholder='CEP'
                  fullWidth
                  id='zip'
                  value={formik.values.zip}
                  error={formik.touched.zip && !!formik.errors.zip}
                  helperText={formik.touched.zip && formik.errors.zip}
                  disabled={requestingAddress}
                  onChange={formik.handleChange}
                  endAdornment={requestingAddress ? <Loader /> : null}
                  mask='cep'
                />
              </div>

              <div className='checkout__fields__row'>
                <div className='checkout__field street'>
                  <Input
                    variant='filled'
                    placeholder='Rua'
                    fullWidth
                    id='street'
                    value={formik.values.street}
                    error={formik.touched.street && !!formik.errors.street}
                    helperText={formik.touched.street && formik.errors.street}
                    onChange={formik.handleChange}
                  />
                </div>
                <div className='checkout__field number'>
                  <Input
                    variant='filled'
                    placeholder='Número'
                    fullWidth
                    id='number'
                    value={String(formik.values.number)}
                    error={formik.touched.number && !!formik.errors.number}
                    helperText={formik.touched.number && formik.errors.number}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>

              <div className='checkout__fields__row'>
                <div className='checkout__field complement'>
                  <Input
                    variant='filled'
                    placeholder='Complemento'
                    fullWidth
                    id='complement'
                    value={formik.values.complement}
                    error={formik.touched.complement && !!formik.errors.complement}
                    helperText={formik.touched.complement && formik.errors.complement}
                    onChange={formik.handleChange}
                  />
                </div>
                <div className='checkout__field district'>
                  <Input
                    variant='filled'
                    placeholder='Bairro'
                    fullWidth
                    id='district'
                    value={formik.values.district}
                    error={formik.touched.district && !!formik.errors.district}
                    helperText={formik.touched.district && formik.errors.district}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>

              <div className='checkout__fields__row'>
                <div className='checkout__field city'>
                  <Input
                    variant='filled'
                    placeholder='Cidade'
                    fullWidth
                    id='city'
                    value={formik.values.city}
                    error={formik.touched.city && !!formik.errors.city}
                    helperText={formik.touched.city && formik.errors.city}
                    onChange={formik.handleChange}
                  />
                </div>
                <div className='checkout__field state'>
                  <Input
                    variant='filled'
                    placeholder='Estado'
                    fullWidth
                    id='state'
                    value={formik.values.state}
                    error={formik.touched.state && !!formik.errors.state}
                    helperText={formik.touched.state && formik.errors.state}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
            </div>

          </div>

          <div className='checkout__actions'>
            <Button variant='contained' type='submit' loading={formik.isSubmitting}>
              Prosseguir com compra
            </Button>
          </div>
        </form>
      </main>
      <CheckoutLoader open={formik.isSubmitting} />
    </Screen>
  );
};

export default observer(CheckoutScreen);
