import PropTypes from 'prop-types';

import { Text } from '../../components';
import * as Theme from '../../constants/Theme';

const ProfileFormItem = ({ label, value }) => {
  return (
    <div className='profile__form__field'>
      <div className='profile__form__field__label'>
        <Text variant='body-1' color={Theme.light60}>
          {label}
        </Text>
      </div>
      <div className='profile__form__field__value'>
        <Text variant='h6'>{value}</Text>
      </div>
    </div>
  );
};

ProfileFormItem.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
};

ProfileFormItem.defaultProps = {
  label: '',
  value: '',
};

export default ProfileFormItem;
