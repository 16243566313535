import PropTypes from 'prop-types';
import { Fade } from 'react-reveal';
import { useHistory } from 'react-router-dom';

import { Button, PublicProfileEventCard, Text } from '..';
import { PlusIcon } from '../svg';
import './styles.scss';

const PublicProfileEvents = (props) => {
  const history = useHistory();
  const { user } = props;

  if (!user?.last_events?.length) return null;

  return (
    <section className='public-profile__events'>
      <Fade bottom distance='10px'>
        <Text className='public-profile__events__title' variant='h5' responsive>
          EVENTOS
        </Text>
      </Fade>

      <div className='public-profile__events__list'>
        {user.last_events.map((event, index) => (
          <Fade bottom distance='10px' delay={(index + 1) * 100} key={event.id}>
            <PublicProfileEventCard
              key={event.id}
              event={{ ...event, user }}
              onClick={() => history.push(`/event/${event.id}`)}
            />
          </Fade>
        ))}
      </div>
      <div className='button-wrapper'>
        <Fade bottom distance='10px' delay={(user?.last_events?.length + 1) * 100}>
          <Button
            variant='contained'
            startIcon={<PlusIcon color='#000' />}
            onClick={() => history.push('/')}
          >
            Mais Eventos
          </Button>
        </Fade>
      </div>
    </section>
  );
};

PublicProfileEvents.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    avatar_url: PropTypes.string,
    last_events: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        cover_url: PropTypes.string,
        name: PropTypes.string,
        date: PropTypes.string,
        photo_count: PropTypes.number,
      }),
    ),
  }),
};

export default PublicProfileEvents;
