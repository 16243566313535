import PropTypes from 'prop-types';

import * as Theme from '../../../constants/Theme';

const UnlockIcon = (props) => {
  const { color, size } = props;

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12 17C13.1 17 14 16.1 14 15C14 13.9 13.1 13 12 13C10.9 13 10 13.9 10 15C10 16.1 10.9 17 12 17ZM18 8H17V6C17 3.24 14.76 1 12 1C9.24 1 7 3.24 7 6H8.9C8.9 4.29 10.29 2.9 12 2.9C13.71 2.9 15.1 4.29 15.1 6V8H6C4.9 8 4 8.9 4 10V20C4 21.1 4.9 22 6 22H18C19.1 22 20 21.1 20 20V10C20 8.9 19.1 8 18 8ZM18 20H6V10H18V20Z'
        fill={color}
      />
    </svg>
  );
};

UnlockIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

UnlockIcon.defaultProps = {
  color: Theme.light60,
  size: 24,
};

export default UnlockIcon;
