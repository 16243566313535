import PropTypes from 'prop-types';

import { Avatar, Text } from '..';
import coverPlaceholder from '../../assets/images/event-cover-placeholder.png';
import * as Theme from '../../constants/Theme';
import { CalendarIcon, CameraIcon, LockIcon, PinIcon, UnlockIcon } from '../svg';
import './styles.scss';

const Card = (props) => {
  const { variant, event, album, button, onClick } = props;

  const hasPhotos = !!event?.photo_count;

  const getCardClassName = () => {
    let newClassName = 'card';
    if (variant) newClassName += ` card--${variant}`;
    if (hasPhotos) newClassName += ` card--${variant}--has-photos`;

    return newClassName;
  };

  if (!event && !album) return null;

  return (
    <div className={getCardClassName()}>
      {variant === 'album' ? (
        <>
          <div className='card__image' onClick={onClick}>
            <img src={album?.cover_url?.length ? album.cover_url : coverPlaceholder} />
          </div>
          <div className='card__content' onClick={onClick}>
            <Avatar image={album.owner.avatar_url} size='medium' />
            <div className='card__text'>
              <div className='card__name'>
                <Text variant='h6' color={Theme.light60}>
                  {album.owner.name}
                </Text>
              </div>
              <div className='card__photo_count'>
                <Text variant='body-2'>{`${album.photo_count} ${
                  album?.photo_count > 1 ? ' fotos' : ' foto'
                }`}</Text>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className='card__image' onClick={onClick}>
            <img src={event?.cover_url?.length ? event.cover_url : coverPlaceholder} />
          </div>
          <div className='card__content' onClick={onClick}>
            <div className='card__text'>
              <div className='card__name'>
                <Text variant='h6'>{event.name}</Text>
              </div>
              <div className='card__date'>
                <CalendarIcon size={18} />
                <Text variant='subtitle-1'>{event.date}</Text>
              </div>
              <div className='card__location'>
                <PinIcon size={18} />
                <Text variant='subtitle-1'>{`${event.city.name} - ${event.city.state}`}</Text>
              </div>
              <div className='card__privacy'>
                {event.public_event ? <UnlockIcon size={18} /> : <LockIcon size={18} />}
                <Text variant='subtitle-1' color={Theme.light60}>
                  {`${event.public_event ? 'Público' : 'Privado'}`}
                </Text>
              </div>
              <div className='card__owner'>
                {event.owner && (
                  <>
                    <CameraIcon size={18} />
                    <Text variant='subtitle-1' color={Theme.light60}>
                      {event.owner.name}
                    </Text>
                  </>
                )}
              </div>
              {hasPhotos ? (
                <div className='card__photo_count'>
                  <Text variant='button' color={Theme.light38}>{`${event.photo_count} ${
                    event?.photo_count > 1 ? ' fotos' : ' foto'
                  }`}</Text>
                </div>
              ) : null}
            </div>
            {button ? <div className='card__button'>{button}</div> : null}
          </div>
        </>
      )}
    </div>
  );
};

Card.propTypes = {
  variant: PropTypes.oneOf(['album', 'event']),
  album: PropTypes.shape({
    cover_url: PropTypes.string.isRequired,
    owner: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      avatar_url: PropTypes.string,
    }),
    photo_count: PropTypes.number.isRequired,
  }),
  event: PropTypes.shape({
    cover_url: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    city: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      state: PropTypes.string,
    }).isRequired,
    collaborative: PropTypes.number.isRequired,
    public_event: PropTypes.number.isRequired,
    owner: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      avatar_url: PropTypes.string,
    }),
    photo_count: PropTypes.number,
  }),
  button: PropTypes.node,
  onClick: PropTypes.func,
};

Card.defaultProps = {
  variant: 'album',
  event: null,
  album: null,
  button: null,
  onClick: () => '',
};
export default Card;
