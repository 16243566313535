import { parse } from 'date-fns';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';

import { EventForm, Loader, Screen, Text } from '../../components';
import { eventFormDefinitions } from '../../constants/FormDefinitions';
import {
  EVENT_DELETED,
  EVENT_DISABLED,
  EVENT_EDITED,
  EVENT_ENABLED,
  SYSTEM_INSTABILITY,
} from '../../constants/Messages';
import * as Theme from '../../constants/Theme';
import { useStore } from '../../hooks';
import './styles.scss';

const EditEventScreen = () => {
  const store = useStore();
  const params = useParams();
  const history = useHistory();

  const event = store.EventStore.detail;

  const [requesting, setRequesting] = useState(true);
  const [changingEventStatus, setChangingEventStatus] = useState(false);

  const { initialValues, validationSchema } = eventFormDefinitions;

  const onSubmit = async (formValues) => {
    try {
      const response = await flowResult(
        store.EventStore.edit({ id: event?.id, ...formValues }),
      );

      if (response?.error) {
        switch (response?.error?.status) {
          default:
            toast.error(SYSTEM_INSTABILITY);
            history.push(`/event/${params.id}`);

            break;
        }
        return;
      }

      toast.success(EVENT_EDITED);
      history.push(`/event/${params.id}`);
    } catch (error) {
      console.log(error);
      history.push(`/event/${params.id}`);
    }
  };

  const setEventStatus = async (status) => {
    try {
      setChangingEventStatus(true);

      const response = await flowResult(store.EventStore.setStatus({ id: event?.id, status }));

      if (response?.error) {
        switch (response?.error?.status) {
          default:
            toast.error(SYSTEM_INSTABILITY);
            return;
        }
      }

      status ? toast.success(EVENT_ENABLED) : toast.success(EVENT_DISABLED);
    } catch (error) {
      console.log(error);
    } finally {
      setChangingEventStatus(false);
    }
  };

  const deleteEvent = async () => {
    try {
      setChangingEventStatus(true);

      const response = await flowResult(store.EventStore.remove({ id: event?.id }));

      if (response?.error) {
        switch (response?.error?.status) {
          default:
            toast.error(SYSTEM_INSTABILITY);
            break;
        }
        return;
      }

      toast.success(EVENT_DELETED);

      history.push('/');
    } catch (error) {
      console.log(error);
    } finally {
      setChangingEventStatus(false);
    }
  };

  const init = async () => {
    try {
      setRequesting(true);

      const response = await flowResult(store.EventStore.getDetail({ id: params.id }));

      if (response.error) {
        toast.error(SYSTEM_INSTABILITY);
        return;
      }
    } catch (error) {
      console.log(error);
    } finally {
      setRequesting(false);
    }
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <Screen className='create-event'>
      <div className='create-event__header'>
        <Text variant='h5' responsive className='create-event__title'>
          Editar Evento
        </Text>
      </div>

      {requesting ? (
        <Loader color={Theme.primary} />
      ) : (
        <main>
          <EventForm
            editing
            eventIsEnabled={event?.enabled}
            initialValues={{
              ...initialValues,
              ...event,
              photo_price: event?.photo_price || '',
              enable_package_1: event?.packages[0]?.enabled ? true : false,
              package_1_price: event?.packages[0]?.price?.length
                ? event?.packages[0].price
                : '',
              package_1_quantity: event?.packages[0]?.quantity
                ? event?.packages[0]?.quantity
                : '',
              enable_package_2: event?.packages[1]?.enabled ? true : false,
              package_2_price: event?.packages[1]?.price?.length
                ? event?.packages[1].price
                : '',
              package_2_quantity: event?.packages[1]?.quantity
                ? event?.packages[1]?.quantity
                : '',
              public_event: event?.public_event ? '1' : '0',
              restricted_photos: event?.restricted_photos ? '1' : '0',
              collaborative: event?.collaborative ? '1' : '0',
              date: parse(event?.date, 'dd/MM/yyyy', new Date()),
              category: String(event?.category.id),
              city_id: String(event?.city.id),
            }}
            validationSchema={validationSchema}
            changingEventStatus={changingEventStatus}
            onSubmit={onSubmit}
            onEnableEvent={() => setEventStatus(true)}
            onDisableEvent={() => setEventStatus(false)}
            onDeleteEvent={deleteEvent}
          />
        </main>
      )}
    </Screen>
  );
};

export default observer(EditEventScreen);
